import { Styled, Text } from '@leaf/components'

const NotFoundPage = () => {
  return (
    <Styled.Center>
      <Text.H1>404</Text.H1>

      <Text.Body1>This is not the page you are looking for.</Text.Body1>
    </Styled.Center>
  )
}

export { NotFoundPage }
