import { useShallow } from 'zustand/react/shallow'

import { AsyncTable, renderCompanyAvatar } from '@leaf/table'

import { graphqlClient } from '@/api'
import {
  type GetAnalyticsLanesQuery,
  useGetAnalyticsLanesQuery,
} from '@/features/analytics-lane/analytics-lane-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 300,
  },
  {
    field: 'company.id',
    headerName: 'Shipper ID',
  },
  {
    field: 'company.name',
    headerName: 'Shipper',
    minWidth: 200,
    renderCell: renderCompanyAvatar({
      id: 'company.id',
      name: 'company.name',
    }),
  },
  {
    field: 'originLocation.name',
    headerName: 'Origin',
    minWidth: 250,
  },
  {
    field: 'destinationLocation.name',
    headerName: 'Destination',
    minWidth: 250,
  },
  {
    field: 'batchDate',
    headerName: 'Batch Date',
    type: 'date',
  },
  {
    field: 'leafMiles',
    headerName: 'Leaf Miles',
    type: 'number',
  },
  {
    field: 'companyMiles',
    headerName: 'Shipper Miles',
    type: 'number',
  },
  {
    field: 'recordType',
    headerName: 'Record Type',
  },
]

const initialState = {
  columns: {
    columnVisibilityModel: {
      'company.id': false,
    },
  },
}

// TODO: LEAF-5120 Implement export for server-side tables
const AnalyticsLaneOverview = () => {
  const [changeTable, savedState] = useStore(
    useShallow((state) => [state.changeTable, state.analyticsLaneOverview]),
  )

  useTitles([{ value: 'Analytics Lanes' }])

  return (
    <AsyncTable
      // @ts-expect-error
      columns={columns}
      initialState={initialState}
      name='analyticsLaneOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      query={{
        client: graphqlClient,
        fn: useGetAnalyticsLanesQuery,
        select: (response: GetAnalyticsLanesQuery) => ({
          count: response?.analyticsLanesAggregate.aggregate?.count,
          rows: response?.analyticsLanes,
        }),
      }}
    />
  )
}

export { AnalyticsLaneOverview }
