import { useShallow } from 'zustand/react/shallow'

import { Table } from '@leaf/table'

import { graphqlClient } from '@/api'
import { useGetFuelPricesOverviewQuery } from '@/features/fuel-price/fuel-price-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const exportOptions = { fileName: 'fuel_prices' }

const FuelPriceOverview = () => {
  const [savedState, changeTable] = useStore(
    useShallow((state) => [state.fuelPriceOverview, state.changeTable]),
  )

  useTitles([{ value: 'Fuel Prices' }])

  const { data, isLoading } = useGetFuelPricesOverviewQuery(
    graphqlClient,
    {},
    { select: (response) => response.fuelPrices },
  )

  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'price', headerName: 'Price', type: 'number' },
    { field: 'fuelPriceIndex.name', headerName: 'Fuel Price Index' },
    { field: 'validFrom', headerName: 'Valid From', type: 'date' },
    { field: 'createdAt', headerName: 'Created At', type: 'date' },
    { field: 'createdByUser.email', headerName: 'Created By' },
  ]

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      exportOptions={exportOptions}
      loading={isLoading}
      name='fuelPriceOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={data}
    />
  )
}

export { FuelPriceOverview }
