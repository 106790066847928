// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetFuelSurchargeScheduleQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetFuelSurchargeScheduleQuery = { __typename?: 'query_root', fuelSurchargeSchedulesByPk?: { __typename?: 'fuelSurchargeSchedules', id: any, name: string, validFrom?: any, peg: any, bucketSize: any, increment: any, isFirstStepZero: boolean, createdAt: any, updatedAt: any, company?: { __typename?: 'companies', id: any, name: string, companyType?: string }, equipmentType?: { __typename?: 'equipmentTypes', id: any, name: string }, fuelPriceIndex: { __typename?: 'fuelPriceIndexes', id: any, name: string }, createdByUser?: { __typename?: 'users', id: any, email: string }, updatedByUser?: { __typename?: 'users', id: any, email: string } } };

export type UpdateFuelSurchargeScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  fuelSurchargeSchedule: Types.FuelSurchargeSchedulesSetInput;
}>;


export type UpdateFuelSurchargeScheduleMutation = { __typename?: 'mutation_root', updateFuelSurchargeSchedulesByPk?: { __typename?: 'fuelSurchargeSchedules', id: any } };

export type DeleteFuelSurchargeScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeleteFuelSurchargeScheduleMutation = { __typename?: 'mutation_root', deleteFuelSurchargeSchedulesByPk?: { __typename?: 'fuelSurchargeSchedules', id: any } };



export const GetFuelSurchargeScheduleDocument = `
    query GetFuelSurchargeSchedule($id: uuid!) {
  fuelSurchargeSchedulesByPk(id: $id) {
    id
    name
    validFrom
    company {
      id
      name
      companyType
    }
    equipmentType {
      id
      name
    }
    fuelPriceIndex {
      id
      name
    }
    peg
    bucketSize
    increment
    isFirstStepZero
    createdAt
    createdByUser {
      id
      email
    }
    updatedAt
    updatedByUser {
      id
      email
    }
  }
}
    `;

export const useGetFuelSurchargeScheduleQuery = <
      TData = GetFuelSurchargeScheduleQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetFuelSurchargeScheduleQueryVariables,
      options?: UseQueryOptions<GetFuelSurchargeScheduleQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFuelSurchargeScheduleQuery, TError, TData>(
      ['GetFuelSurchargeSchedule', variables],
      fetcher<GetFuelSurchargeScheduleQuery, GetFuelSurchargeScheduleQueryVariables>(client, GetFuelSurchargeScheduleDocument, variables, headers),
      options
    )};

useGetFuelSurchargeScheduleQuery.document = GetFuelSurchargeScheduleDocument;

useGetFuelSurchargeScheduleQuery.getKey = (variables: GetFuelSurchargeScheduleQueryVariables) => ['GetFuelSurchargeSchedule', variables];


useGetFuelSurchargeScheduleQuery.fetcher = (client: GraphQLClient, variables: GetFuelSurchargeScheduleQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFuelSurchargeScheduleQuery, GetFuelSurchargeScheduleQueryVariables>(client, GetFuelSurchargeScheduleDocument, variables, headers);

export const UpdateFuelSurchargeScheduleDocument = `
    mutation updateFuelSurchargeSchedule($id: uuid!, $fuelSurchargeSchedule: fuelSurchargeSchedulesSetInput!) {
  updateFuelSurchargeSchedulesByPk(
    pkColumns: {id: $id}
    _set: $fuelSurchargeSchedule
  ) {
    id
  }
}
    `;

export const useUpdateFuelSurchargeScheduleMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateFuelSurchargeScheduleMutation, TError, UpdateFuelSurchargeScheduleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateFuelSurchargeScheduleMutation, TError, UpdateFuelSurchargeScheduleMutationVariables, TContext>(
      ['updateFuelSurchargeSchedule'],
      (variables?: UpdateFuelSurchargeScheduleMutationVariables) => fetcher<UpdateFuelSurchargeScheduleMutation, UpdateFuelSurchargeScheduleMutationVariables>(client, UpdateFuelSurchargeScheduleDocument, variables, headers)(),
      options
    )};

useUpdateFuelSurchargeScheduleMutation.getKey = () => ['updateFuelSurchargeSchedule'];


useUpdateFuelSurchargeScheduleMutation.fetcher = (client: GraphQLClient, variables: UpdateFuelSurchargeScheduleMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateFuelSurchargeScheduleMutation, UpdateFuelSurchargeScheduleMutationVariables>(client, UpdateFuelSurchargeScheduleDocument, variables, headers);

export const DeleteFuelSurchargeScheduleDocument = `
    mutation deleteFuelSurchargeSchedule($id: uuid!) {
  deleteFuelSurchargeSchedulesByPk(id: $id) {
    id
  }
}
    `;

export const useDeleteFuelSurchargeScheduleMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteFuelSurchargeScheduleMutation, TError, DeleteFuelSurchargeScheduleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteFuelSurchargeScheduleMutation, TError, DeleteFuelSurchargeScheduleMutationVariables, TContext>(
      ['deleteFuelSurchargeSchedule'],
      (variables?: DeleteFuelSurchargeScheduleMutationVariables) => fetcher<DeleteFuelSurchargeScheduleMutation, DeleteFuelSurchargeScheduleMutationVariables>(client, DeleteFuelSurchargeScheduleDocument, variables, headers)(),
      options
    )};

useDeleteFuelSurchargeScheduleMutation.getKey = () => ['deleteFuelSurchargeSchedule'];


useDeleteFuelSurchargeScheduleMutation.fetcher = (client: GraphQLClient, variables: DeleteFuelSurchargeScheduleMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteFuelSurchargeScheduleMutation, DeleteFuelSurchargeScheduleMutationVariables>(client, DeleteFuelSurchargeScheduleDocument, variables, headers);
