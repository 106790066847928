// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetAnalyticsLanesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  orderBy?: Types.InputMaybe<Array<Types.AnalyticsLanesOrderBy> | Types.AnalyticsLanesOrderBy>;
  where?: Types.InputMaybe<Types.AnalyticsLanesBoolExp>;
}>;


export type GetAnalyticsLanesQuery = { __typename?: 'query_root', analyticsLanes: Array<{ __typename?: 'analyticsLanes', id: any, batchDate: any, leafMiles?: any, companyMiles?: any, recordType: any, company: { __typename?: 'companies', id: any, name: string }, originLocation?: { __typename?: 'analyticsLocations', name: string }, destinationLocation?: { __typename?: 'analyticsLocations', name: string } }>, analyticsLanesAggregate: { __typename?: 'analyticsLanesAggregate', aggregate?: { __typename?: 'analyticsLanesAggregateFields', count: number } } };



export const GetAnalyticsLanesDocument = `
    query GetAnalyticsLanes($limit: Int!, $offset: Int!, $orderBy: [analyticsLanesOrderBy!], $where: analyticsLanesBoolExp) @cached(ttl: 300) {
  analyticsLanes(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
    id
    company {
      id
      name
    }
    batchDate
    leafMiles
    companyMiles
    recordType
    originLocation {
      name
    }
    destinationLocation {
      name
    }
  }
  analyticsLanesAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export const useGetAnalyticsLanesQuery = <
      TData = GetAnalyticsLanesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAnalyticsLanesQueryVariables,
      options?: UseQueryOptions<GetAnalyticsLanesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAnalyticsLanesQuery, TError, TData>(
      ['GetAnalyticsLanes', variables],
      fetcher<GetAnalyticsLanesQuery, GetAnalyticsLanesQueryVariables>(client, GetAnalyticsLanesDocument, variables, headers),
      options
    )};

useGetAnalyticsLanesQuery.document = GetAnalyticsLanesDocument;

useGetAnalyticsLanesQuery.getKey = (variables: GetAnalyticsLanesQueryVariables) => ['GetAnalyticsLanes', variables];


useGetAnalyticsLanesQuery.fetcher = (client: GraphQLClient, variables: GetAnalyticsLanesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAnalyticsLanesQuery, GetAnalyticsLanesQueryVariables>(client, GetAnalyticsLanesDocument, variables, headers);
