import { useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'

import { DataRow, DownloadButton, Spinner, Styled, Text, UploadButton } from '@leaf/components'
import type * as Types from '@leaf/types'
import { download, numbers, time } from '@leaf/utilities'

import {
  getTemplate,
  importContracts,
} from '@/features/contract/contract-overview-import-dialog.api'
import { useStore } from '@/store'

type ContractOverviewImportDialogProps = {
  onClose: Types.Noop
  onSuccess: Types.Noop
  open: boolean
}
const ContractOverviewImportDialog = ({
  onClose,
  onSuccess,
  open,
}: ContractOverviewImportDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [uploadFile, setUploadFile] = useState<File>()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const handleOnClose = () => {
    onClose()
    setUploadFile(undefined)
  }

  const handleUpload = (file: File) => {
    setUploadFile(file)
  }

  const handleDownload = async () => {
    const { content, name } = await getTemplate()
    download.trigger(name || 'contract_template.xlsx', content)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const { error } = await importContracts(uploadFile!)
    setIsLoading(false)

    if (error) {
      // TODO: LEAF-4950 - Create error message snackbar hook
      addSnackbar({
        message: error.key || 'An error occurred while importing contracts.',
        severity: 'error',
      })
    } else {
      onSuccess()
      addSnackbar({
        message: 'Import successful.',
      })
      handleOnClose()
    }
  }

  return (
    <Dialog onClose={handleOnClose} open={open}>
      {isLoading ? <Spinner overlay /> : null}

      <DialogTitle>Import Contracts</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text.Body1>Please provide an Excel file.</Text.Body1>

            <Text.Body1 paragraph>
              The required values and examples can be found in the template.
            </Text.Body1>
          </Grid>

          <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }} xs={12}>
            <DownloadButton handleDownload={handleDownload} label='Template' />

            <UploadButton handleUpload={handleUpload} />
          </Grid>

          {uploadFile && (
            <Grid item xs={12}>
              <Styled.Divider />

              <DataRow label='Filename' value={uploadFile.name} />

              <DataRow
                label='Last modification'
                value={time.filetimeToDatetime(uploadFile.lastModified)}
              />

              <DataRow label='Size' value={numbers.convertBytes(uploadFile.size)} />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleOnClose} variant='outlined'>
          Cancel
        </Button>

        <Button color='primary' disabled={!uploadFile} onClick={handleSubmit} variant='contained'>
          Import
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ContractOverviewImportDialog }
