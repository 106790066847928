import { useQuery } from '@tanstack/react-query'

import { Spinner } from '@leaf/components'

import { restClient } from '@/api'
import { useTitles } from '@/hooks'

type HolisticsToken = {
  embedCode: string
  token: string
}

const Dashboard = () => {
  const { data: response, isLoading } = useQuery({
    queryFn: () => restClient.get('tendering/token/holistics'),
    queryKey: ['holistics'],
  })

  useTitles([{ value: 'Dashboard' }])

  if (isLoading) {
    return <Spinner page />
  }

  const { embedCode, token } = response!.data as HolisticsToken
  return (
    <iframe
      allowFullScreen
      src={`https://us.holistics.io/embed/${embedCode}?_token=${token}`}
      style={{
        border: '0',
        height: 'calc(100% - 56px)',
        width: '100%',
      }}
      title='Dashboard'
    />
  )
}

export { Dashboard }
