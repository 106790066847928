import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Alert, Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import {
  AutocompleteElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import * as z from 'zod'

import type { GraphQLErrorType } from '@leaf/api'
import { Spinner } from '@leaf/components'
import { CompanyTypes, CompanyTypesSelect, StatesSelect } from '@leaf/constants'
import { clean, validation as v } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import { useGetActiveAdminsQuery } from '@/features/company/company-details.api.generated'
import { useInsertCompanyMutation } from '@/features/company/company-overview.api.generated'

const schema = z.object({
  accountTeamMembers: v.__selects,
  companyType: v._string,
  name: v._string,
  phone: v.__string,
  registeredName: v.__string,
  registeredState: v.__select,
  tenderEmailAddress: v.__email,
  tenderPhoneNumber: v.__string,
  transactionFee: v.__percentage,
})
type Schema = z.infer<typeof schema>

const CompanyCreateDialog = ({ onClose, onSuccess, open }: Types.FormDialog) => {
  const {
    error,
    isError,
    isLoading,
    mutate: insert,
  } = useInsertCompanyMutation(graphqlClient, {
    meta: { globalError: false },
    onSuccess,
  })
  const errors = (error as GraphQLErrorType)?.response?.errors

  const { data: admins } = useGetActiveAdminsQuery(
    graphqlClient,
    {},
    {
      select: ({ users }) =>
        users.map((user) => ({
          id: user.id,
          label: user.email,
        })),
    },
  )

  const context = useForm<Schema>({
    defaultValues: {
      companyType: CompanyTypes.SHIPPER,
    },
    mode: 'all',
    resolver: zr(schema),
  })

  const handleSuccess = () => {
    const values = context.getValues()

    const payload = {
      ...values,
      accountTeamMembers: values.accountTeamMembers && {
        data: values.accountTeamMembers?.map((accountTeamMember) => {
          return { userId: accountTeamMember.id }
        }),
      },
      registeredState: values.registeredState?.id?.toString(),
      transactionFee: values.transactionFee || null,
    }
    insert({ company: clean(payload) })
  }

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      {isLoading && <Spinner overlay />}

      <Alert severity='info'>More settings can be found on the detail page after creation.</Alert>

      {isError && errors.map((e) => <Alert severity='error'>{e.message}</Alert>)}

      <FormContainer formContext={context} onSuccess={handleSuccess}>
        <DialogContent>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextFieldElement
                autoComplete='off'
                fullWidth
                label='Name'
                name='name'
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <SelectElement
                fullWidth
                label='Type'
                name='companyType'
                options={CompanyTypesSelect}
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                InputProps={{
                  inputProps: { max: 1, min: 0, step: 0.05 },
                }}
                fullWidth
                label='Transaction fee (%)'
                name='transactionFee'
                type='number'
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                autoComplete='off'
                fullWidth
                label='Phone'
                name='phone'
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                label='Registered name'
                name='registeredName'
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <AutocompleteElement
                label='Registered state'
                name='registeredState'
                options={StatesSelect}
                textFieldProps={{ fullWidth: true, variant: 'standard' }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                label='Tender Phone'
                name='tenderPhoneNumber'
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                label='Tender Email'
                name='tenderEmailAddress'
                variant='standard'
              />
            </Grid>

            <Grid item xs={12}>
              <AutocompleteElement
                label='Account Team Members'
                multiple
                name='accountTeamMembers'
                options={admins || []}
                textFieldProps={{ fullWidth: true, variant: 'standard' }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' onClick={onClose} variant='contained'>
            Cancel
          </Button>

          <Button color='primary' type='submit' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  )
}

export { CompanyCreateDialog }
