import { queryClient, restClient } from '@/api'

const invite = (userId: string) =>
  queryClient.fetchQuery({
    queryFn: () => restClient.put(`users/${userId}/invite`),
  })

const toggleStatus = (userId: string) =>
  queryClient.fetchQuery({
    queryFn: () => restClient.patch(`users/${userId}/status`),
  })

export { invite, toggleStatus }
