import { Outlet, useNavigation } from 'react-router-dom'

import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { Snackbar, Spinner } from '@leaf/components'
import { Apps, CompanyTypes, Roles } from '@leaf/constants'

import { auth } from '@/api'
import { Changelog } from '@/features'
import { Navigation } from '@/navigation/navigation'
import { LayoutHeader } from '@/navigation/pages/layout-header'
import { useStore } from '@/store'

const getRedirectUrl = (metadata: { company_type: string; default_app: string; role: string }) => {
  if (metadata.company_type === CompanyTypes.CARRIER) {
    return 'https://carrier.leaflogistics.com'
  }
  if (metadata.company_type === CompanyTypes.SHIPPER) {
    if (metadata.default_app === Apps.TENDER) {
      return 'https://tender.leaflogistics.com'
    }
    return 'https://shipper.leaflogistics.com'
  }
  throw new Error('Could not determine redirect URL.')
}

const LayoutPage = () => {
  const { client, isRedirectToLogin, user } = auth

  const shouldRedirect = !isRedirectToLogin && user?.app_metadata.role !== Roles.LE_ADMIN
  useQuery({
    enabled: shouldRedirect,
    queryFn: async () => {
      await client.logout({
        logoutParams: {
          returnTo: getRedirectUrl(user?.app_metadata),
        },
      })
      return {}
    },
    queryKey: ['redirect-user'],
  })

  const [updateUser, showChangelog, toggleChangelog, snackbars, removeSnackbar] = useStore(
    useShallow((state) => [
      state.updateUser,
      state.showChangelog,
      state.toggleChangelog,
      state.snackbars,
      state.removeSnackbar,
    ]),
  )
  updateUser({
    companyId: 60,
    id: Number(user?.app_metadata.uid),
    isAdmin: true,
  })

  const navigation = useNavigation()

  if (isRedirectToLogin || shouldRedirect) {
    return <Spinner page />
  }

  const navigationLoading = navigation.state === 'loading'

  return (
    <Grid sx={{ display: 'flex', height: '100vh' }}>
      <Navigation toggleChangelog={toggleChangelog} />

      <Changelog onClose={toggleChangelog} open={showChangelog} />

      <Grid sx={{ flexGrow: 1, overflow: 'auto' }}>
        <LayoutHeader />

        {navigationLoading ? <Spinner page /> : <Outlet />}
      </Grid>

      <Snackbar removeSnackbar={removeSnackbar} snackbars={snackbars} />
    </Grid>
  )
}

export { LayoutPage }
