import { Fragment, type ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Divider } from '@mui/material'

import { Text } from '@leaf/components'

import { useStore } from '@/store'

const Breadcrumbs = () => {
  const titles = useStore((state) => state.titles)

  const navigate = useNavigate()

  const breadcrumbs: ReactNode[] = []

  titles.forEach((title, i) => {
    if (title.to) {
      breadcrumbs.push(
        <Fragment key={`${i}-title-wrapper`}>
          <Text.Caption
            key={`${i}-title`}
            onClick={() => navigate(title.to!)}
            sx={{ color: 'gray', cursor: 'pointer' }}
          >
            {title.value}
          </Text.Caption>
        </Fragment>,
      )
    } else {
      breadcrumbs.push(<Text.Caption key={`${i}-title`}>{title.value}</Text.Caption>)
    }
    if (i < titles.length - 1) {
      breadcrumbs.push(
        <Divider
          flexItem
          key={`${i}-divider`}
          orientation='vertical'
          sx={{ margin: '0 1em 0 1em' }}
        />,
      )
    }
  })

  return <Box sx={{ display: 'flex' }}>{breadcrumbs}</Box>
}

export { Breadcrumbs }
