import { ChangelogDialog } from '@leaf/components'
import type * as Types from '@leaf/types'

import log_202307 from '@/features/changelog/log-202307.md?raw'
import log_202308 from '@/features/changelog/log-202308.md?raw'
import log_202309 from '@/features/changelog/log-202309.md?raw'
import log_202310 from '@/features/changelog/log-202310.md?raw'
import log_202311 from '@/features/changelog/log-202311.md?raw'
import log_202312 from '@/features/changelog/log-202312.md?raw'
import log_202401 from '@/features/changelog/log-202401.md?raw'
import log_202402 from '@/features/changelog/log-202402.md?raw'
import log_202403 from '@/features/changelog/log-202403.md?raw'
import log_202405 from '@/features/changelog/log-202405.md?raw'
import log_202406 from '@/features/changelog/log-202406.md?raw'
import log_202407 from '@/features/changelog/log-202407.md?raw'
import log_202408 from '@/features/changelog/log-202408.md?raw'
import log_202409 from '@/features/changelog/log-202409.md?raw'

const changelogs = [
  log_202409,
  log_202408,
  log_202407,
  log_202406,
  log_202405,
  log_202403,
  log_202402,
  log_202401,
  log_202312,
  log_202311,
  log_202310,
  log_202309,
  log_202308,
  log_202307,
]

const Changelog = ({ onClose, open }: Types.Dialog) => {
  return <ChangelogDialog changelogs={changelogs} onClose={onClose} open={open} />
}

export { Changelog }
