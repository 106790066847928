// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetRawLaneQueryVariables = Types.Exact<{
  powerLaneId: Types.Scalars['uuid']['input'];
}>;


export type GetRawLaneQuery = { __typename?: 'query_root', analyticsRawLanes: Array<{ __typename?: 'analyticsRawLanes', id: any, annualizedLoads?: any, batchDate: any, companyMiles?: any, companyTransitTime?: any, dAddress?: string, dCity?: string, dClusterName: string, dCountry?: string, dSite?: string, dZip?: string, dState?: string, recordType: any, protection?: any, pcmMiles?: any, pcmHours?: any, oZip?: string, oState?: string, oSite?: string, oCountry?: string, oClusterName: string, oCity?: string, oAddress?: string, mode?: any, meta?: any, meanSpend?: any, loads?: any, lastSpendDate?: any, hazardous?: boolean, firstSpendDate?: any, equipmentType?: any, equipmentClass: any, datasetLabel?: string }> };

export type GetLaneQueryVariables = Types.Exact<{
  laneId: Types.Scalars['uuid']['input'];
}>;


export type GetLaneQuery = { __typename?: 'query_root', analyticsLanesByPk?: { __typename?: 'analyticsLanes', id: any, batchDate: any, companyMiles?: any, leafMiles?: any, leafTransitTime?: any, recordType: any, destinationLocation?: { __typename?: 'analyticsLocations', name: string }, originLocation?: { __typename?: 'analyticsLocations', name: string } } };

export type GetLanePlanOpportunitiesQueryVariables = Types.Exact<{
  lanePlanId: Types.Scalars['uuid']['input'];
}>;


export type GetLanePlanOpportunitiesQuery = { __typename?: 'query_root', lanePlanOpportunities: Array<{ __typename?: 'lanePlanOpportunities', opportunityType?: string, priority?: number }> };



export const GetRawLaneDocument = `
    query GetRawLane($powerLaneId: uuid!) @cached(ttl: 300) {
  analyticsRawLanes(where: {powerLaneId: {_eq: $powerLaneId}}) {
    id
    annualizedLoads
    batchDate
    companyMiles
    companyTransitTime
    dAddress
    dCity
    dClusterName
    dCountry
    dSite
    dZip
    dState
    recordType
    protection
    pcmMiles
    pcmHours
    oZip
    oState
    oSite
    oCountry
    oClusterName
    oCity
    oAddress
    mode
    meta
    meanSpend
    loads
    lastSpendDate
    hazardous
    firstSpendDate
    equipmentType
    equipmentClass
    datasetLabel
  }
}
    `;

export const useGetRawLaneQuery = <
      TData = GetRawLaneQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRawLaneQueryVariables,
      options?: UseQueryOptions<GetRawLaneQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRawLaneQuery, TError, TData>(
      ['GetRawLane', variables],
      fetcher<GetRawLaneQuery, GetRawLaneQueryVariables>(client, GetRawLaneDocument, variables, headers),
      options
    )};

useGetRawLaneQuery.document = GetRawLaneDocument;

useGetRawLaneQuery.getKey = (variables: GetRawLaneQueryVariables) => ['GetRawLane', variables];


useGetRawLaneQuery.fetcher = (client: GraphQLClient, variables: GetRawLaneQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRawLaneQuery, GetRawLaneQueryVariables>(client, GetRawLaneDocument, variables, headers);

export const GetLaneDocument = `
    query GetLane($laneId: uuid!) @cached(ttl: 300) {
  analyticsLanesByPk(id: $laneId) {
    id
    batchDate
    companyMiles
    leafMiles
    leafTransitTime
    recordType
    destinationLocation {
      name
    }
    originLocation {
      name
    }
  }
}
    `;

export const useGetLaneQuery = <
      TData = GetLaneQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetLaneQueryVariables,
      options?: UseQueryOptions<GetLaneQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLaneQuery, TError, TData>(
      ['GetLane', variables],
      fetcher<GetLaneQuery, GetLaneQueryVariables>(client, GetLaneDocument, variables, headers),
      options
    )};

useGetLaneQuery.document = GetLaneDocument;

useGetLaneQuery.getKey = (variables: GetLaneQueryVariables) => ['GetLane', variables];


useGetLaneQuery.fetcher = (client: GraphQLClient, variables: GetLaneQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLaneQuery, GetLaneQueryVariables>(client, GetLaneDocument, variables, headers);

export const GetLanePlanOpportunitiesDocument = `
    query GetLanePlanOpportunities($lanePlanId: uuid!) @cached(ttl: 300) {
  lanePlanOpportunities(where: {lanePlanId: {_eq: $lanePlanId}}) {
    opportunityType
    priority
  }
}
    `;

export const useGetLanePlanOpportunitiesQuery = <
      TData = GetLanePlanOpportunitiesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetLanePlanOpportunitiesQueryVariables,
      options?: UseQueryOptions<GetLanePlanOpportunitiesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLanePlanOpportunitiesQuery, TError, TData>(
      ['GetLanePlanOpportunities', variables],
      fetcher<GetLanePlanOpportunitiesQuery, GetLanePlanOpportunitiesQueryVariables>(client, GetLanePlanOpportunitiesDocument, variables, headers),
      options
    )};

useGetLanePlanOpportunitiesQuery.document = GetLanePlanOpportunitiesDocument;

useGetLanePlanOpportunitiesQuery.getKey = (variables: GetLanePlanOpportunitiesQueryVariables) => ['GetLanePlanOpportunities', variables];


useGetLanePlanOpportunitiesQuery.fetcher = (client: GraphQLClient, variables: GetLanePlanOpportunitiesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLanePlanOpportunitiesQuery, GetLanePlanOpportunitiesQueryVariables>(client, GetLanePlanOpportunitiesDocument, variables, headers);
