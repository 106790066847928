import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { executionLaneDetailsQuery } from '@/features/execution-lane/execution-lane-details.api'

const executionLaneDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = executionLaneDetailsQuery(params.id!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { executionLaneDetailsLoader }
