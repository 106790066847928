// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetAdaptConstellationsOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAdaptConstellationsOverviewQuery = { __typename?: 'query_root', constellations: Array<{ __typename?: 'constellations', id: any, name: string, constellationLanes: Array<{ __typename?: 'constellationLanes', equipmentClass: string, lane: { __typename?: 'analyticsLanes', companyId: any, company: { __typename?: 'companies', name: string }, originLocation?: { __typename?: 'analyticsLocations', name: string }, destinationLocation?: { __typename?: 'analyticsLocations', name: string } } }>, constellationNetworkMoves: Array<{ __typename?: 'constellationNetworkMoves', networkMoveId: any }> }> };



export const GetAdaptConstellationsOverviewDocument = `
    query GetAdaptConstellationsOverview {
  constellations(orderBy: {name: ASC}) {
    id
    name
    constellationLanes {
      equipmentClass
      lane {
        companyId
        company {
          name
        }
        originLocation {
          name
        }
        destinationLocation {
          name
        }
      }
    }
    constellationNetworkMoves {
      networkMoveId
    }
  }
}
    `;

export const useGetAdaptConstellationsOverviewQuery = <
      TData = GetAdaptConstellationsOverviewQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetAdaptConstellationsOverviewQueryVariables,
      options?: UseQueryOptions<GetAdaptConstellationsOverviewQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAdaptConstellationsOverviewQuery, TError, TData>(
      variables === undefined ? ['GetAdaptConstellationsOverview'] : ['GetAdaptConstellationsOverview', variables],
      fetcher<GetAdaptConstellationsOverviewQuery, GetAdaptConstellationsOverviewQueryVariables>(client, GetAdaptConstellationsOverviewDocument, variables, headers),
      options
    )};

useGetAdaptConstellationsOverviewQuery.document = GetAdaptConstellationsOverviewDocument;

useGetAdaptConstellationsOverviewQuery.getKey = (variables?: GetAdaptConstellationsOverviewQueryVariables) => variables === undefined ? ['GetAdaptConstellationsOverview'] : ['GetAdaptConstellationsOverview', variables];


useGetAdaptConstellationsOverviewQuery.fetcher = (client: GraphQLClient, variables?: GetAdaptConstellationsOverviewQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAdaptConstellationsOverviewQuery, GetAdaptConstellationsOverviewQueryVariables>(client, GetAdaptConstellationsOverviewDocument, variables, headers);
