import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { shipmentDetailsQuery } from '@/features/contract/contract-details-shipments-details.api'

const shipmentDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = shipmentDetailsQuery(params.shipmentId!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { shipmentDetailsLoader }
