import { fileClient } from '@leaf/utilities'

import { auth } from '@/api'

const getLanePlanExport = async (ids: string[]) => {
  const client = await fileClient(auth.token!)
  return client.download(`${import.meta.env.VITE_API}/planning/lane/export`, {
    ids,
  })
}

export { getLanePlanExport }
