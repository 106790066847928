import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { cloneDeep } from 'lodash-es'
import {
  AutocompleteElement,
  CheckboxElement,
  FormContainer,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'
import * as z from 'zod'

import { CardHeader, DataRow, Page, Progress } from '@leaf/components'
import { clean, select, validation as v } from '@leaf/form'
import { time } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { useGetShippersQuery } from '@/api/companies.api.generated'
import { useGetEquipmentTypesQuery } from '@/api/equipment-types.api.generated'
import { useGetFuelPriceIndexesQuery } from '@/api/fuel-price-indexes.api.generated'
import {
  type FuelSurchargeScheduleQueryReturnType,
  fuelSurchargeScheduleDetailsQuery,
} from '@/features/fuel-surcharge-schedule/fuel-surcharge-schedule-details.api'
import { useUpdateFuelSurchargeScheduleMutation } from '@/features/fuel-surcharge-schedule/fuel-surcharge-schedule-details.api.generated'
import { FuelSurchargeScheduleDeleteDialog } from '@/features/fuel-surcharge-schedule/fuel-surcharge-schedule-details-delete-dialog'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const schema = z.object({
  bucketSize: v._number,
  company: v._select,
  equipmentType: v.__select,
  fuelPriceIndex: v._select,
  increment: v._number,
  isFirstStepZero: z.boolean(),
  name: v._string,
  peg: v._number,
  validFrom: z.date(),
})
type Schema = z.infer<typeof schema>

const toPayload = (values: Schema) => ({
  bucketSize: values.bucketSize,
  companyId: values.company.id,
  equipmentTypeId: values.equipmentType?.id,
  fuelPriceIndexId: values.fuelPriceIndex.id,
  increment: values.increment,
  isFirstStepZero: values.isFirstStepZero,
  name: values.name,
  peg: values.peg,
  validFrom: values.validFrom,
})

const FuelSurchargeScheduleDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const { data } = useQuery(fuelSurchargeScheduleDetailsQuery(id!))
  const fsc = data as FuelSurchargeScheduleQueryReturnType

  const { data: shippers } = useGetShippersQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.companies) },
  )
  const { data: equipmentTypes } = useGetEquipmentTypesQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.equipmentTypes) },
  )
  const { data: fuelPriceIndexes } = useGetFuelPriceIndexesQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.fuelPriceIndexes) },
  )
  const { mutate: update } = useUpdateFuelSurchargeScheduleMutation(graphqlClient, {
    onSuccess: () => addSnackbar({ message: 'Fuel surcharge schedule updated successfully' }),
  })

  const context = useForm<Schema>({
    defaultValues: {
      ...cloneDeep(fsc),
      company: select.fromResponse(fsc.company),
      equipmentType: select.fromResponse(fsc.equipmentType),
      fuelPriceIndex: select.fromResponse(fsc.fuelPriceIndex),
      validFrom: new Date(fsc.validFrom),
    },
    mode: 'all',
    resolver: zr(schema),
  })

  const onSubmit = (values: Schema) => {
    const payload = toPayload(values)
    update({ fuelSurchargeSchedule: clean(payload), id: fsc.id })
  }

  const toggleDeleteDialog = () => setShowDeleteDialog(!showDeleteDialog)

  const onDeleteSuccess = () => {
    toggleDeleteDialog()
    navigate('/fuel-surcharge-schedules')
    addSnackbar({ message: `FSC '${data?.name}' deleted successfully` })
  }

  useTitles([
    { to: 'fuel-surcharge-schedules', value: 'Fuel Surcharge Schedules' },
    { value: fsc.id },
  ])
  useActions([
    <Button
      color='primary'
      form='fsc-details-form'
      key='saveFsc'
      sx={{ marginRight: '1em' }}
      type='submit'
      variant='outlined'
    >
      Save
    </Button>,

    <Button
      color='error'
      form='fsc-details-form'
      key='deleteFsc'
      onClick={toggleDeleteDialog}
      variant='contained'
    >
      Delete
    </Button>,
  ])

  return (
    <>
      <FormContainer
        FormProps={{ id: 'fsc-details-form' }}
        formContext={context}
        onSuccess={onSubmit}
      >
        <Progress />

        <Page>
          <Grid item xs={7}>
            <Card>
              <CardHeader title='Fuel Surcharge Schedule' />

              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFieldElement
                      autoComplete='off'
                      fullWidth
                      label='Name'
                      name='name'
                      required
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutocompleteElement
                      label='Company'
                      name='company'
                      options={shippers || []}
                      required
                      textFieldProps={{ fullWidth: true, variant: 'standard' }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DatePickerElement
                      inputProps={{
                        fullWidth: true,
                        variant: 'standard',
                      }}
                      label='Valid From'
                      name='validFrom'
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutocompleteElement
                      label='Fuel Price Index'
                      name='fuelPriceIndex'
                      options={fuelPriceIndexes || []}
                      required
                      textFieldProps={{ fullWidth: true, variant: 'standard' }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextFieldElement
                      InputProps={{
                        inputProps: { min: 0, step: 0.05 },
                      }}
                      fullWidth
                      label='PEG'
                      name='peg'
                      required
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextFieldElement
                      InputProps={{
                        inputProps: { min: 0, step: 0.05 },
                      }}
                      fullWidth
                      label='Increment'
                      name='increment'
                      required
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextFieldElement
                      InputProps={{
                        inputProps: { min: 0, step: 0.05 },
                      }}
                      fullWidth
                      label='Bucket Size'
                      name='bucketSize'
                      required
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutocompleteElement
                      label='Equipment Type'
                      name='equipmentType'
                      options={equipmentTypes || []}
                      textFieldProps={{ fullWidth: true, variant: 'standard' }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CheckboxElement label='First Step Zero' name='isFirstStepZero' />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={5}>
            <Card>
              <CardHeader title='Meta' />

              <CardContent>
                <DataRow label='Created At' value={time.toLocalDatetime(fsc.createdAt)} />

                <DataRow label='Created By' value={fsc.createdByUser?.email} />

                <DataRow label='Updated At' value={time.toLocalDatetime(fsc.updatedAt)} />

                <DataRow label='Updated By' value={fsc.updatedByUser?.email} />
              </CardContent>
            </Card>
          </Grid>
        </Page>
      </FormContainer>

      <FuelSurchargeScheduleDeleteDialog
        id={id!}
        onClose={toggleDeleteDialog}
        onSuccess={onDeleteSuccess}
        open={showDeleteDialog}
      />
    </>
  )
}

export { FuelSurchargeScheduleDetails }
