import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AutocompleteElement, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import * as z from 'zod'

import { CardHeader, DataRow, Page, Progress } from '@leaf/components'
import { clean, select, validation as v } from '@leaf/form'
import { time } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { useGetEquipmentClassesQuery } from '@/api/equipment-class.api.generated'
import {
  type EquipmentTypeDetailsQueryReturnType,
  equipmentTypeDetailsQuery,
} from '@/features/equipment-type/equipment-type-details.api'
import { useUpdateEquipmentTypeMutation } from '@/features/equipment-type/equipment-type-details.api.generated'
import { EquipmentTypeDeleteDialog } from '@/features/equipment-type/equipment-type-details-delete-dialog'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const schema = z.object({
  description: v._string,
  equipmentClass: v.__select,
  name: v._string,
})
type Schema = z.infer<typeof schema>

const toPayload = ({ description, equipmentClass, name }: Schema) => ({
  description,
  equipmentClass: equipmentClass?.id,
  name,
})

const EquipmentTypeDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const { data } = useQuery(equipmentTypeDetailsQuery(id!))
  const equipmentType = data as EquipmentTypeDetailsQueryReturnType

  const { data: equipmentClasses } = useGetEquipmentClassesQuery(
    graphqlClient,
    {},
    {
      select: (response) => select.fromEnum(null, response.equipmentClasses),
    },
  )

  const { mutate: update } = useUpdateEquipmentTypeMutation(graphqlClient, {
    onSuccess: () => addSnackbar({ message: 'Equipment type updated successfully' }),
  })

  const context = useForm<Schema>({
    defaultValues: {
      ...equipmentType,
      equipmentClass: data?.equipmentClass
        ? { id: data.equipmentClass, label: data.equipmentClass }
        : null,
    },
    mode: 'all',
    resolver: zr(schema),
  })

  const onSubmit = (values: Schema) => {
    const payload = toPayload(values)
    update({ equipmentType: clean(payload), id: equipmentType.id })
  }

  const toggleDeleteDialog = () => setShowDeleteDialog(!showDeleteDialog)

  const onDeleteSuccess = () => {
    toggleDeleteDialog()
    navigate('/equipment-types')
    addSnackbar({ message: `Equipment type '${data?.name}' deleted successfully` })
  }

  useTitles([{ to: 'equipment-types', value: 'Equipment Types' }, { value: equipmentType.id }])
  useActions([
    <Button
      color='primary'
      form='equipment-type-details-form'
      key='saveEquipmentType'
      sx={{ marginRight: '1em' }}
      type='submit'
      variant='outlined'
    >
      Save
    </Button>,

    <Button
      color='error'
      form='equipment-type-details-form'
      key='deleteEquipmentType'
      onClick={toggleDeleteDialog}
      variant='contained'
    >
      Delete
    </Button>,
  ])

  return (
    <>
      <FormContainer
        FormProps={{ id: 'equipment-type-details-form' }}
        formContext={context}
        onSuccess={onSubmit}
      >
        <Progress />

        <Page>
          <Grid item xs={7}>
            <Card>
              <CardHeader title='Equipment Type' />

              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFieldElement
                      autoComplete='off'
                      fullWidth
                      label='Name'
                      name='name'
                      required
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextFieldElement
                      autoComplete='off'
                      fullWidth
                      label='Description'
                      name='description'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutocompleteElement
                      label='Equipment Class'
                      name='equipmentClass'
                      options={equipmentClasses ?? []}
                      textFieldProps={{ fullWidth: true, variant: 'standard' }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={5}>
            <Card>
              <CardHeader title='Meta' />

              <CardContent>
                <DataRow label='Created At' value={time.toLocalDatetime(equipmentType.createdAt)} />

                <DataRow label='Created By' value={equipmentType.createdByUser?.email} />

                <DataRow label='Updated At' value={time.toLocalDatetime(equipmentType.updatedAt)} />

                <DataRow label='Updated By' value={equipmentType.updatedByUser?.email} />
              </CardContent>
            </Card>
          </Grid>
        </Page>
      </FormContainer>

      <EquipmentTypeDeleteDialog
        id={id!}
        onClose={toggleDeleteDialog}
        onSuccess={onDeleteSuccess}
        open={showDeleteDialog}
      />
    </>
  )
}

export { EquipmentTypeDetails }
