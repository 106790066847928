import { queryClient } from '@/api'
import {
  type DeleteLanePlanCommentMutationVariables,
  type GetLanePlanCommentsQuery,
  type InsertLanePlanCommentMutationVariables,
  useGetLanePlanCommentsQuery,
} from '@/features/lane-plan/lane-plan-details-comments.api.generated'

const optimisticInsertComment = (lanePlanId: string) => {
  return {
    onError: (
      _: unknown,
      __: unknown,
      context: { previousLanePlanComments: unknown } | undefined,
    ) => {
      const queryKey = useGetLanePlanCommentsQuery.getKey({ lanePlanId })
      queryClient.setQueryData(queryKey, context?.previousLanePlanComments)
    },
    onMutate: async (newComment: InsertLanePlanCommentMutationVariables) => {
      const queryKey = useGetLanePlanCommentsQuery.getKey({ lanePlanId })
      await queryClient.cancelQueries({ queryKey })
      const previousLanePlanComments = queryClient.getQueryData(queryKey)
      queryClient.setQueryData(queryKey, (old) => {
        const oldLanePlanComments = old as GetLanePlanCommentsQuery
        const newLanePlanComments = [newComment, ...oldLanePlanComments.lanePlanComments]
        return { lanePlanComments: newLanePlanComments }
      })
      return { previousLanePlanComments }
    },
    onSettled: () => {
      const queryKey = useGetLanePlanCommentsQuery.getKey({ lanePlanId })
      queryClient.invalidateQueries({ queryKey })
    },
  }
}

const optimisticDeleteComment = (lanePlanId: string) => {
  return {
    onError: (
      _: unknown,
      __: unknown,
      context: { previousLanePlanComments: unknown } | undefined,
    ) => {
      const queryKey = useGetLanePlanCommentsQuery.getKey({ lanePlanId })
      queryClient.setQueryData(queryKey, context?.previousLanePlanComments)
    },
    onMutate: async (args: DeleteLanePlanCommentMutationVariables) => {
      const queryKey = useGetLanePlanCommentsQuery.getKey({ lanePlanId })
      await queryClient.cancelQueries({ queryKey })
      const previousLanePlanComments = queryClient.getQueryData(queryKey)
      queryClient.setQueryData(queryKey, (old) => {
        const oldLanePlanComments = old as GetLanePlanCommentsQuery
        const newLanePlanComments = oldLanePlanComments.lanePlanComments.filter((comment) => {
          return comment.id !== args.id
        })
        return { lanePlanComments: newLanePlanComments }
      })
      return { previousLanePlanComments }
    },
    onSettled: () => {
      const queryKey = useGetLanePlanCommentsQuery.getKey({ lanePlanId })
      queryClient.invalidateQueries({ queryKey })
    },
  }
}

export { optimisticDeleteComment, optimisticInsertComment }
