import { fileClient } from '@leaf/utilities'

import { auth } from '@/api'

const getLanePlanImport = async () => {
  const client = await fileClient(auth.token!)
  return client.download(`${import.meta.env.VITE_API}/planning/lane/import`)
}

const postLanePlanImport = async (file: File) => {
  const client = await fileClient(auth.token!)
  return client.upload(`${import.meta.env.VITE_API}/planning/lane/import`, file)
}

export { getLanePlanImport, postLanePlanImport }
