// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetEquipmentTypeDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type GetEquipmentTypeDetailsQuery = { __typename?: 'query_root', equipmentTypesByPk?: { __typename?: 'equipmentTypes', id: any, name: string, description: string, equipmentClass?: string, createdAt: any, updatedAt?: any, createdByUser?: { __typename?: 'users', id: any, email: string }, updatedByUser?: { __typename?: 'users', id: any, email: string } } };

export type UpdateEquipmentTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
  equipmentType: Types.EquipmentTypesSetInput;
}>;


export type UpdateEquipmentTypeMutation = { __typename?: 'mutation_root', updateEquipmentTypesByPk?: { __typename?: 'equipmentTypes', id: any } };

export type DeleteEquipmentTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type DeleteEquipmentTypeMutation = { __typename?: 'mutation_root', deleteEquipmentTypesByPk?: { __typename?: 'equipmentTypes', id: any } };



export const GetEquipmentTypeDetailsDocument = `
    query GetEquipmentTypeDetails($id: bigint!) @cached(ttl: 300) {
  equipmentTypesByPk(id: $id) {
    id
    name
    description
    equipmentClass
    createdAt
    createdByUser {
      id
      email
    }
    updatedAt
    updatedByUser {
      id
      email
    }
  }
}
    `;

export const useGetEquipmentTypeDetailsQuery = <
      TData = GetEquipmentTypeDetailsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetEquipmentTypeDetailsQueryVariables,
      options?: UseQueryOptions<GetEquipmentTypeDetailsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetEquipmentTypeDetailsQuery, TError, TData>(
      ['GetEquipmentTypeDetails', variables],
      fetcher<GetEquipmentTypeDetailsQuery, GetEquipmentTypeDetailsQueryVariables>(client, GetEquipmentTypeDetailsDocument, variables, headers),
      options
    )};

useGetEquipmentTypeDetailsQuery.document = GetEquipmentTypeDetailsDocument;

useGetEquipmentTypeDetailsQuery.getKey = (variables: GetEquipmentTypeDetailsQueryVariables) => ['GetEquipmentTypeDetails', variables];


useGetEquipmentTypeDetailsQuery.fetcher = (client: GraphQLClient, variables: GetEquipmentTypeDetailsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetEquipmentTypeDetailsQuery, GetEquipmentTypeDetailsQueryVariables>(client, GetEquipmentTypeDetailsDocument, variables, headers);

export const UpdateEquipmentTypeDocument = `
    mutation updateEquipmentType($id: bigint!, $equipmentType: equipmentTypesSetInput!) {
  updateEquipmentTypesByPk(pkColumns: {id: $id}, _set: $equipmentType) {
    id
  }
}
    `;

export const useUpdateEquipmentTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateEquipmentTypeMutation, TError, UpdateEquipmentTypeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateEquipmentTypeMutation, TError, UpdateEquipmentTypeMutationVariables, TContext>(
      ['updateEquipmentType'],
      (variables?: UpdateEquipmentTypeMutationVariables) => fetcher<UpdateEquipmentTypeMutation, UpdateEquipmentTypeMutationVariables>(client, UpdateEquipmentTypeDocument, variables, headers)(),
      options
    )};

useUpdateEquipmentTypeMutation.getKey = () => ['updateEquipmentType'];


useUpdateEquipmentTypeMutation.fetcher = (client: GraphQLClient, variables: UpdateEquipmentTypeMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateEquipmentTypeMutation, UpdateEquipmentTypeMutationVariables>(client, UpdateEquipmentTypeDocument, variables, headers);

export const DeleteEquipmentTypeDocument = `
    mutation deleteEquipmentType($id: bigint!) {
  deleteEquipmentTypesByPk(id: $id) {
    id
  }
}
    `;

export const useDeleteEquipmentTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteEquipmentTypeMutation, TError, DeleteEquipmentTypeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteEquipmentTypeMutation, TError, DeleteEquipmentTypeMutationVariables, TContext>(
      ['deleteEquipmentType'],
      (variables?: DeleteEquipmentTypeMutationVariables) => fetcher<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>(client, DeleteEquipmentTypeDocument, variables, headers)(),
      options
    )};

useDeleteEquipmentTypeMutation.getKey = () => ['deleteEquipmentType'];


useDeleteEquipmentTypeMutation.fetcher = (client: GraphQLClient, variables: DeleteEquipmentTypeMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>(client, DeleteEquipmentTypeDocument, variables, headers);
