import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetExecutionLaneDetailsDocument,
  type GetExecutionLaneDetailsQuery,
} from '@/features/execution-lane/execution-lane-details.api.generated'

type ExecutionLaneDetailsQueryReturnType = Types.NoUndefined<
  GetExecutionLaneDetailsQuery['executionLanesByPk']
>

const executionLaneDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const executionLaneResponse = await graphqlClient.request(GetExecutionLaneDetailsDocument, {
      id,
    })
    const data = clean(executionLaneResponse) as GetExecutionLaneDetailsQuery
    const executionLane = (data.executionLanesByPk || {}) as ExecutionLaneDetailsQueryReturnType
    return executionLane
  },
  queryKey: [`execution-lane-details-${id}`],
})

export { executionLaneDetailsQuery }
export type { ExecutionLaneDetailsQueryReturnType }
