import { zodResolver as zr } from '@hookform/resolvers/zod'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import {
  AutocompleteElement,
  CheckboxElement,
  FormContainer,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'
import * as z from 'zod'

import type { GraphQLErrorType } from '@leaf/api'
import { Spinner } from '@leaf/components'
import { clean, select, validation as v } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import { useGetShippersQuery } from '@/api/companies.api.generated'
import { useGetEquipmentTypesQuery } from '@/api/equipment-types.api.generated'
import { useGetFuelPriceIndexesQuery } from '@/api/fuel-price-indexes.api.generated'
import { useInsertFuelSurchargeScheduleMutation } from '@/features/fuel-surcharge-schedule/fuel-surcharge-schedule-overview.api.generated'

const schema = z.object({
  bucketSize: v._number,
  company: v._select,
  equipmentType: v.__select,
  fuelPriceIndex: v._select,
  increment: v._number,
  isFirstStepZero: z.boolean(),
  name: v._string,
  peg: v._number,
  validFrom: z.date(),
})
type Schema = z.infer<typeof schema>

const FuelSurchargeScheduleCreateDialog = ({ onClose, onSuccess, open }: Types.FormDialog) => {
  const { data: shippers } = useGetShippersQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.companies) },
  )
  const { data: equipmentTypes } = useGetEquipmentTypesQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.equipmentTypes) },
  )
  const { data: fuelPriceIndexes } = useGetFuelPriceIndexesQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.fuelPriceIndexes) },
  )

  const {
    error,
    isError,
    isLoading,
    mutate: insert,
  } = useInsertFuelSurchargeScheduleMutation(graphqlClient, {
    meta: { globalError: false },
    onSuccess,
  })
  const errors = (error as GraphQLErrorType)?.response?.errors

  const context = useForm<Schema>({
    defaultValues: {
      bucketSize: 0,
      increment: 0,
      isFirstStepZero: false,
      peg: 0,
      validFrom: new Date(),
    },
    resolver: zr(schema),
  })

  const handleSuccess = () => {
    const values = context.getValues()

    const payload = {
      bucketSize: values.bucketSize,
      companyId: values.company.id,
      equipmentTypeId: values.equipmentType?.id,
      fuelPriceIndexId: values.fuelPriceIndex.id,
      increment: values.increment,
      isFirstStepZero: values.isFirstStepZero,
      name: values.name,
      peg: values.peg,
      validFrom: values.validFrom,
    }
    insert({ fsc: clean(payload) })
  }

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      {isLoading && <Spinner overlay />}

      {isError && errors.map((e) => <Alert severity='error'>{e.message}</Alert>)}

      <FormContainer formContext={context} onSuccess={handleSuccess}>
        <DialogTitle>Create Shipper Fuel Surcharge Schedule</DialogTitle>

        <DialogContent>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextFieldElement
                autoComplete='off'
                fullWidth
                label='Name'
                name='name'
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <AutocompleteElement
                label='Company'
                name='company'
                options={shippers || []}
                required
                textFieldProps={{ fullWidth: true, variant: 'standard' }}
              />
            </Grid>

            <Grid item xs={6}>
              <DatePickerElement
                inputProps={{
                  fullWidth: true,
                  variant: 'standard',
                }}
                label='Valid From'
                name='validFrom'
                required
              />
            </Grid>

            <Grid item xs={6}>
              <AutocompleteElement
                label='Fuel Price Index'
                name='fuelPriceIndex'
                options={fuelPriceIndexes || []}
                required
                textFieldProps={{ fullWidth: true, variant: 'standard' }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                InputProps={{
                  inputProps: { min: 0, step: 0.05 },
                }}
                fullWidth
                label='PEG'
                name='peg'
                required
                type='number'
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                InputProps={{
                  inputProps: { min: 0, step: 0.05 },
                }}
                fullWidth
                label='Increment'
                name='increment'
                required
                type='number'
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                InputProps={{
                  inputProps: { min: 0, step: 0.05 },
                }}
                fullWidth
                label='Bucket Size'
                name='bucketSize'
                required
                type='number'
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <AutocompleteElement
                label='Equipment Type'
                name='equipmentType'
                options={equipmentTypes || []}
                textFieldProps={{ fullWidth: true, variant: 'standard' }}
              />
            </Grid>

            <Grid item xs={12}>
              <CheckboxElement label='First Step Zero' name='isFirstStepZero' />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' onClick={onClose} variant='contained'>
            Cancel
          </Button>

          <Button color='primary' type='submit' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  )
}

export { FuelSurchargeScheduleCreateDialog }
