import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetAdaptNetworkMoveDocument,
  type GetAdaptNetworkMoveQuery,
} from '@/features/adapt-network-move/adapt-network-move-details.api.generated'

type AdaptNetworkMoveQueryReturnType = Types.NoUndefined<
  GetAdaptNetworkMoveQuery['analyticsNetworkMovesByPk']
>

const adaptNetworkMoveDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const response = await graphqlClient.request(GetAdaptNetworkMoveDocument, { id })
    const data = clean(response) as GetAdaptNetworkMoveQuery
    const move = (data.analyticsNetworkMovesByPk || {}) as AdaptNetworkMoveQueryReturnType
    return move
  },
  queryKey: [`adapt-network-move-details-${id}`],
})

export { adaptNetworkMoveDetailsQuery }
export type { AdaptNetworkMoveQueryReturnType }
