import { useState } from 'react'

import {
  Person as ActivateIcon,
  Add as CreateIcon,
  PersonOff as DeactivateIcon,
  Send as InviteIcon,
} from '@mui/icons-material'
import { Button } from '@mui/material'
import { type GridRowParams } from '@mui/x-data-grid-pro'
import { useShallow } from 'zustand/react/shallow'

import { Apps, Roles } from '@leaf/constants'
import { useCopyToClipboard } from '@leaf/hooks'
import { Columns, Table, renderCompanyAvatar } from '@leaf/table'

import { graphqlClient } from '@/api'
import { invite, toggleStatus } from '@/features/user/user.api'
import { UserCreateDialog } from '@/features/user/user-create-dialog'
import { useGetUsersQuery } from '@/features/user/user-overview.api.generated'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const UserOverview = () => {
  const [addSnackbar, changeTable, savedState] = useStore(
    useShallow((state) => [state.addSnackbar, state.changeTable, state.userOverview]),
  )
  const [showCreateDialog, setShowCreateDialog] = useState(false)

  const { data, isLoading, refetch } = useGetUsersQuery(
    graphqlClient,
    {},
    { select: ({ users }) => users },
  )

  const { toClipboard } = useCopyToClipboard()

  const exportOptions = { fileName: 'users' }

  const toggleCreateDialog = () => setShowCreateDialog(!showCreateDialog)

  // Using types here don't provide much value
  const handleSuccess = (response: any, payload: any) => {
    refetch()
    toggleCreateDialog()
    toClipboard(response.data.url)
    addSnackbar({
      autoHideDuration: null,
      message: [
        `${payload.email} created successfully.`,
        `Copied activation link to your clipboard`,
        `${response.data.url}`,
      ].join('\n'),
    })
  }
  const handleInvite = (userId: string) => () =>
    invite(userId).then(({ data: inviteData }) => {
      toClipboard(inviteData.url)
      addSnackbar({
        autoHideDuration: null,
        message: [
          `Invitation re-sent to ${inviteData.email}`,
          `Copied activation link to your clipboard`,
          `${inviteData.url}`,
        ].join('\n'),
      })
    })
  const handleDeactivate = (userId: string) => () =>
    toggleStatus(userId).then(({ data: deactivationData }) => {
      refetch()
      addSnackbar({ message: `User ${deactivationData.email} deactivated` })
    })
  const handleActivate = (userId: string) => () =>
    toggleStatus(userId).then(({ data: activationData }) => {
      refetch()
      addSnackbar({ message: `User ${activationData.email} activated` })
    })

  useTitles([{ value: 'Users' }])
  useActions([
    <Button key='createUser' onClick={toggleCreateDialog} startIcon={<CreateIcon />}>
      Create
    </Button>,
  ])

  const columns = [
    {
      ...Columns.Action({
        options: (params: GridRowParams) => {
          const { active, id } = params.row
          return [
            { icon: InviteIcon, onClick: handleInvite(id), value: 'Send invite' },
            {
              icon: active ? DeactivateIcon : ActivateIcon,
              onClick: active ? handleDeactivate(id) : handleActivate(id),
              value: active ? 'Deactivate' : 'Activate',
            },
          ]
        },
      }),
      minWidth: 120,
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 250,
      valueGetter: (_: unknown, row: any) => {
        const { firstName, lastName } = row
        return `${firstName} ${lastName}`
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone',
    },
    {
      field: 'company',
      headerName: 'Company',
      minWidth: 250,
      renderCell: renderCompanyAvatar({
        id: 'company.id',
        name: 'company.name',
      }),
      valueGetter: (value?: any) => {
        const companyName = value?.name
        return companyName
      },
    },
    { field: 'businessUnit.name', headerName: 'Business Unit' },
    {
      field: 'role',
      headerName: 'Role',
      type: 'singleSelect',
      valueOptions: [Roles.LE_ADMIN, Roles.USER],
    },
    {
      field: 'defaultApp',
      headerName: 'App',
      type: 'singleSelect',
      valueOptions: [Apps.CARRIER, Apps.SHIPPER, Apps.TENDER, Apps.ADMIN],
    },
    {
      field: 'active',
      headerName: 'Active',
      type: 'boolean',
    },
    {
      field: 'loginCount',
      headerName: 'Logins',
      type: 'number',
    },
    {
      field: 'lastLoginAt',
      headerName: 'Last Login',
      type: 'dateTime',
    },
    ...Columns.Meta(false, true),
  ]

  return (
    <>
      <Table
        // @ts-expect-error
        columns={columns}
        exportOptions={exportOptions}
        loading={isLoading}
        name='userOverview'
        persist={{
          fn: changeTable,
          save: savedState,
        }}
        rows={data}
      />

      {showCreateDialog && (
        <UserCreateDialog
          onClose={toggleCreateDialog}
          onSuccess={handleSuccess}
          open={showCreateDialog}
        />
      )}
    </>
  )
}

export { UserOverview }
