// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetExplorerMetaQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  batchDate: Types.Scalars['date']['input'];
  equipmentClass: Types.Scalars['String']['input'];
}>;


export type GetExplorerMetaQuery = { __typename?: 'query_root', analyticsAdaptLaneDetails: Array<{ __typename?: 'laneAdaptDetail', meta?: any }> };

export type GetActualsQueryVariables = Types.Exact<{
  shipperId: Types.Scalars['bigint']['input'];
  routeId: Types.Scalars['uuid']['input'];
}>;


export type GetActualsQuery = { __typename?: 'query_root', actuals: Array<{ __typename?: 'actual', date: any, count: any }> };



export const GetExplorerMetaDocument = `
    query GetExplorerMeta($id: uuid!, $batchDate: date!, $equipmentClass: String!) @cached(ttl: 300) {
  analyticsAdaptLaneDetails(
    args: {id: $id, batchDate: $batchDate, equipmentClass: $equipmentClass}
  ) {
    meta
  }
}
    `;

export const useGetExplorerMetaQuery = <
      TData = GetExplorerMetaQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetExplorerMetaQueryVariables,
      options?: UseQueryOptions<GetExplorerMetaQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetExplorerMetaQuery, TError, TData>(
      ['GetExplorerMeta', variables],
      fetcher<GetExplorerMetaQuery, GetExplorerMetaQueryVariables>(client, GetExplorerMetaDocument, variables, headers),
      options
    )};

useGetExplorerMetaQuery.document = GetExplorerMetaDocument;

useGetExplorerMetaQuery.getKey = (variables: GetExplorerMetaQueryVariables) => ['GetExplorerMeta', variables];


useGetExplorerMetaQuery.fetcher = (client: GraphQLClient, variables: GetExplorerMetaQueryVariables, headers?: RequestInit['headers']) => fetcher<GetExplorerMetaQuery, GetExplorerMetaQueryVariables>(client, GetExplorerMetaDocument, variables, headers);

export const GetActualsDocument = `
    query GetActuals($shipperId: bigint!, $routeId: uuid!) @cached(ttl: 300) {
  actuals(args: {shipperId: $shipperId, routeId: $routeId}) {
    date
    count
  }
}
    `;

export const useGetActualsQuery = <
      TData = GetActualsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetActualsQueryVariables,
      options?: UseQueryOptions<GetActualsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetActualsQuery, TError, TData>(
      ['GetActuals', variables],
      fetcher<GetActualsQuery, GetActualsQueryVariables>(client, GetActualsDocument, variables, headers),
      options
    )};

useGetActualsQuery.document = GetActualsDocument;

useGetActualsQuery.getKey = (variables: GetActualsQueryVariables) => ['GetActuals', variables];


useGetActualsQuery.fetcher = (client: GraphQLClient, variables: GetActualsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetActualsQuery, GetActualsQueryVariables>(client, GetActualsDocument, variables, headers);
