import { useState } from 'react'

import { Add as CreateIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useShallow } from 'zustand/react/shallow'

import { Columns, Table } from '@leaf/table'

import { graphqlClient } from '@/api'
import { useGetEquipmentTypesOverviewQuery } from '@/features/equipment-type/equipment-type-overview.api.generated'
import { EquipmentTypeCreateDialog } from '@/features/equipment-type/equipment-type-overview-create-dialog'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const exportOptions = { fileName: 'equipment_types' }

const EquipmentTypeOverview = () => {
  const [changeTable, savedState, addSnackbar] = useStore(
    useShallow((state) => [state.changeTable, state.equipmentTypeOverview, state.addSnackbar]),
  )

  const [showCreateDialog, setShowCreateDialog] = useState(false)

  const toggleCreateDialog = () => setShowCreateDialog(!showCreateDialog)

  useTitles([{ value: 'Equipment Types' }])
  useActions([
    <Button key='createEquipmentType' onClick={toggleCreateDialog} startIcon={<CreateIcon />}>
      Create
    </Button>,
  ])

  const { data, isLoading, refetch } = useGetEquipmentTypesOverviewQuery(
    graphqlClient,
    {},
    { select: ({ equipmentTypes }) => equipmentTypes },
  )

  const onSuccess = (_: unknown, payload: any) => {
    refetch()
    toggleCreateDialog()
    addSnackbar({ message: `Equipment Type '${payload.name}' created successfully` })
  }

  const columns = [
    Columns.Action(),
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Name' },
    { field: 'description', headerName: 'Description' },
    { field: 'equipmentClass', headerName: 'Equipment Class' },
    ...Columns.Meta(true, true, true, false),
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: true,
      },
    },
  }

  return (
    <>
      <Table
        // @ts-expect-error
        columns={columns}
        exportOptions={exportOptions}
        initialState={initialState}
        loading={isLoading}
        name='equipmentTypeOverview'
        persist={{
          fn: changeTable,
          save: savedState,
        }}
        rows={data}
      />

      <EquipmentTypeCreateDialog
        onClose={toggleCreateDialog}
        onSuccess={onSuccess}
        open={showCreateDialog}
      />
    </>
  )
}

export { EquipmentTypeOverview }
