import * as api from '@leaf/api'

import { useStore } from '@/store'

const auth = await api.auth(import.meta.env.VITE_AUTH0_CLIENT_ID)

const { graphqlClient, queryClient, restClient } = api.init(auth.token!, (message: string) =>
  useStore.getState().addSnackbar({
    autoHideDuration: null,
    message,
    severity: 'error',
  }),
)

export { auth, graphqlClient, queryClient, restClient }
