import { zodResolver as zr } from '@hookform/resolvers/zod'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { AutocompleteElement, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import * as z from 'zod'

import type { GraphQLErrorType } from '@leaf/api'
import { Spinner } from '@leaf/components'
import { clean, select, validation as v } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import { useGetEquipmentClassesQuery } from '@/api/equipment-class.api.generated'
import { useInsertEquipmentTypeMutation } from '@/features/equipment-type/equipment-type-overview.api.generated'

const schema = z.object({
  description: v._string,
  equipmentClass: v.__select,
  name: v._string,
})
type Schema = z.infer<typeof schema>

const EquipmentTypeCreateDialog = ({ onClose, onSuccess, open }: Types.FormDialog) => {
  const {
    error,
    isError,
    isLoading,
    mutate: insert,
  } = useInsertEquipmentTypeMutation(graphqlClient, {
    meta: { globalError: false },
    onSuccess,
  })
  const errors = (error as GraphQLErrorType)?.response?.errors

  const { data: equipmentClasses } = useGetEquipmentClassesQuery(
    graphqlClient,
    {},
    {
      select: (response) => select.fromEnum(null, response.equipmentClasses),
    },
  )

  const context = useForm<Schema>({
    resolver: zr(schema),
  })

  const handleSuccess = () => {
    const values = context.getValues()

    const payload = {
      description: values.description,
      equipmentClass: values.equipmentClass?.id,
      name: values.name,
    }
    insert({ equipmentType: clean(payload) })
  }

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      {isLoading && <Spinner overlay />}

      {isError && errors.map((e) => <Alert severity='error'>{e.message}</Alert>)}

      <FormContainer formContext={context} onSuccess={handleSuccess}>
        <DialogTitle>Create Equipment Type</DialogTitle>

        <DialogContent>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextFieldElement
                autoComplete='off'
                fullWidth
                label='Name'
                name='name'
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                autoComplete='off'
                fullWidth
                label='Description'
                name='description'
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <AutocompleteElement
                label='Equipment Class'
                name='equipmentClass'
                options={equipmentClasses ?? []}
                textFieldProps={{ fullWidth: true, variant: 'standard' }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' onClick={onClose} variant='contained'>
            Cancel
          </Button>

          <Button color='primary' type='submit' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  )
}

export { EquipmentTypeCreateDialog }
