import { useShallow } from 'zustand/react/shallow'

import { Table } from '@leaf/table'

import { TABLE_HEIGHT } from '@/constants'
import type { GetContractMatchesQuery } from '@/features/contract/contract-details-matches.api.generated'
import { useStore } from '@/store'

const columns = [
  {
    field: 'isEnabled',
    headerName: 'Active',
    type: 'boolean',
  },
  {
    field: 'sellContract.contractType',
    headerName: 'Contract Type',
  },
  {
    field: 'sellContract.seller.name',
    headerName: 'Account Name',
  },
  {
    field: 'origins',
    headerName: 'Origin Areas',
    type: 'list',
  },
  {
    field: 'destinations',
    headerName: 'Destination Areas',
    type: 'list',
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    type: 'date',
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    type: 'date',
  },
  {
    field: 'contractMatchVolumes.remainingWeeks',
    headerName: 'Remaining Weeks',
    type: 'number',
  },
  {
    field: 'contractMatchVolumes.weeklyAllocatedVolume',
    headerName: 'Potential Weekly Volume',
    minWidth: 200,
    type: 'number',
  },
]

const initialState = {
  columns: {
    columnVisibilityModel: {},
  },
}

const getRowId = ({ sellContractId }: { sellContractId: string }) => sellContractId

type ContractDetailsMatchesProps = {
  data?: {
    destinations?: string[]
    origins?: string[]
  } & GetContractMatchesQuery['routingGuides']
  loading: boolean
}
const ContractDetailsMatches = ({ data, loading }: ContractDetailsMatchesProps) => {
  const [changeTable, savedState] = useStore(
    useShallow((state) => [state.changeTable, state.contractDetailsMatches]),
  )

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      disableColumnMenu
      getRowId={getRowId}
      height={TABLE_HEIGHT}
      initialState={initialState}
      loading={loading}
      name='contractDetailsMatches'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={data}
      slots={{
        toolbar: null,
      }}
    />
  )
}

export { ContractDetailsMatches }
