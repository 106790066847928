import type * as Types from '@leaf/types'

const DEFAULT_DAILIES = {
  FRIDAY: 0,
  MONDAY: 0,
  SATURDAY: 0,
  SUNDAY: 0,
  THURSDAY: 0,
  TUESDAY: 0,
  WEDNESDAY: 0,
}

const parseVolumePattern = (volumePattern?: string) => {
  if (!volumePattern) {
    return DEFAULT_DAILIES
  }
  const dailies = volumePattern?.split(',')?.reduce((acc, daily) => {
    const [day, volume] = daily.split('=')
    acc[day] = parseInt(volume, 10)
    return acc
  }, DEFAULT_DAILIES as Types.KeyValue)

  return dailies
}

export { parseVolumePattern }
