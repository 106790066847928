// @ts-nocheck
/* eslint-disable */
import type * as Types from './hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetFuelSurchargeSchedulesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFuelSurchargeSchedulesQuery = { __typename?: 'query_root', fuelSurchargeSchedules: Array<{ __typename?: 'fuelSurchargeSchedules', id: any, name: string, companyId?: any, equipmentTypeId?: any }> };



export const GetFuelSurchargeSchedulesDocument = `
    query GetFuelSurchargeSchedules {
  fuelSurchargeSchedules(orderBy: {name: ASC}) {
    id
    name
    companyId
    equipmentTypeId
  }
}
    `;

export const useGetFuelSurchargeSchedulesQuery = <
      TData = GetFuelSurchargeSchedulesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetFuelSurchargeSchedulesQueryVariables,
      options?: UseQueryOptions<GetFuelSurchargeSchedulesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFuelSurchargeSchedulesQuery, TError, TData>(
      variables === undefined ? ['GetFuelSurchargeSchedules'] : ['GetFuelSurchargeSchedules', variables],
      fetcher<GetFuelSurchargeSchedulesQuery, GetFuelSurchargeSchedulesQueryVariables>(client, GetFuelSurchargeSchedulesDocument, variables, headers),
      options
    )};

useGetFuelSurchargeSchedulesQuery.document = GetFuelSurchargeSchedulesDocument;

useGetFuelSurchargeSchedulesQuery.getKey = (variables?: GetFuelSurchargeSchedulesQueryVariables) => variables === undefined ? ['GetFuelSurchargeSchedules'] : ['GetFuelSurchargeSchedules', variables];


useGetFuelSurchargeSchedulesQuery.fetcher = (client: GraphQLClient, variables?: GetFuelSurchargeSchedulesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFuelSurchargeSchedulesQuery, GetFuelSurchargeSchedulesQueryVariables>(client, GetFuelSurchargeSchedulesDocument, variables, headers);
