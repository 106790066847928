import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetShipmentDocument,
  type GetShipmentQuery,
} from '@/features/contract/contract-details-shipments-details.api.generated'

type ShipmentDetailsQueryReturnType = Types.NoUndefined<
  {
    accessorials: string[]
  } & GetShipmentQuery['shipmentTendersByPk']
>

const shipmentDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const response = await graphqlClient.request(GetShipmentDocument, { id })
    const data = clean(response) as GetShipmentQuery
    const shipment = (data.shipmentTendersByPk || {}) as ShipmentDetailsQueryReturnType
    return shipment as ShipmentDetailsQueryReturnType
  },
  queryKey: [`shipment-details-${id}`],
  staleTime: 1800000,
})

export { shipmentDetailsQuery }
export type { ShipmentDetailsQueryReturnType }
