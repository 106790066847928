import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { type JavalinErrorType, javalinErrorToString } from '@leaf/api'
import { Spinner } from '@leaf/components'
import type * as Types from '@leaf/types'

import { restClient } from '@/api'
import { useStore } from '@/store'

type ContractDetailsDeleteDialogProps = {
  ids: { contract: string; routes: string[] }
} & Types.Dialog
const ContractDetailsDeleteDialog = ({ ids, onClose, open }: ContractDetailsDeleteDialogProps) => {
  const navigate = useNavigate()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<null | string[]>(null)

  const handleDelete = async () => {
    setIsLoading(true)
    const contractId = ids.contract

    try {
      ids.routes.forEach(async (routeId) => {
        await restClient.delete(`contracting/contracts/${ids.contract}/routes/${routeId}`)
      })
      await restClient.delete(`contracting/contracts/${contractId}`)
      navigate('/contracts')
      addSnackbar({
        message: `Contract ${contractId} successfully deleted`,
        severity: 'success',
      })
    } catch (error) {
      const javalinErrors = javalinErrorToString(error as JavalinErrorType)
      setErrors(javalinErrors)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog onClose={onClose} open={open}>
      {isLoading ? <Spinner overlay /> : null}

      <DialogTitle>Do you really want to delete this contract?</DialogTitle>

      <DialogContent>
        We will try to delete the contract, however in some cases this might not be possible since
        it can be referenced by other entities. Please contact us directly if you need help.
      </DialogContent>

      {errors && <Alert severity='error'>Could not delete contract: {errors}</Alert>}

      <DialogActions>
        <Button color='secondary' onClick={onClose} variant='contained'>
          Cancel
        </Button>

        <Button color='error' onClick={handleDelete} variant='contained'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ContractDetailsDeleteDialog }
