import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetEquipmentTypeDetailsDocument,
  type GetEquipmentTypeDetailsQuery,
} from '@/features/equipment-type/equipment-type-details.api.generated'

type EquipmentTypeDetailsQueryReturnType = Types.NoUndefined<
  GetEquipmentTypeDetailsQuery['equipmentTypesByPk']
>

const equipmentTypeDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const EquipmentTypeDetailsResponse = await graphqlClient.request(
      GetEquipmentTypeDetailsDocument,
      { id },
    )
    const data = clean(EquipmentTypeDetailsResponse) as GetEquipmentTypeDetailsQuery
    const equipmentTypeDetails = (data.equipmentTypesByPk ||
      {}) as EquipmentTypeDetailsQueryReturnType
    return equipmentTypeDetails
  },
  queryKey: [`equipment-type-details-${id}`],
})

export { equipmentTypeDetailsQuery }
export type { EquipmentTypeDetailsQueryReturnType }
