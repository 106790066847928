import { useParams } from 'react-router-dom'

import { Card, CardContent, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { CardHeader, Page, Progress } from '@leaf/components'

import { graphqlClient } from '@/api'
import type { LaneExplorerType } from '@/features/lane-plan/lane-plan.types'
import {
  type LanePlanQueryReturnType,
  lanePlanDetailsQuery,
  useGetPricingQuery,
} from '@/features/lane-plan/lane-plan-details.api'
import { useGetExplorersQuery } from '@/features/lane-plan/lane-plan-details.api.generated'
import { LanePlanComments } from '@/features/lane-plan/lane-plan-details-comments'
import { LanePlanExplorers } from '@/features/lane-plan/lane-plan-details-explorers'
import { LanePlanLane } from '@/features/lane-plan/lane-plan-details-lane'
import { LanePlanPricing } from '@/features/lane-plan/lane-plan-details-pricing'
import { LanePlanSummary } from '@/features/lane-plan/lane-plan-details-summary'
import { LanePlanVolume } from '@/features/lane-plan/lane-plan-details-volume'
import { useTitles } from '@/hooks'

const LanePlanDetails = () => {
  const { id } = useParams()
  const { data } = useQuery(lanePlanDetailsQuery(id!))
  const plan = data as LanePlanQueryReturnType

  const { data: explorers } = useGetExplorersQuery(
    graphqlClient,
    { id: plan.adaptLaneId },
    {
      select: ({ analyticsAdaptLanes }) => analyticsAdaptLanes as LaneExplorerType[],
    },
  )

  const payload: { [key: string]: string } = {
    id: plan.adaptLaneId,
  }
  // The pricing API doesn't like undefined or null values
  if (plan.fuelSurchargeSchedule) {
    payload.shipper_fsc_id = plan.fuelSurchargeSchedule.id
  }
  const { data: pricing } = useGetPricingQuery(payload)

  useTitles([{ to: 'planning/plans', value: 'Plans' }, { value: id }])

  return (
    <>
      <Progress />

      <Page>
        <Grid item xs={12}>
          <LanePlanSummary plan={plan} />
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container direction='column' item xs={4}>
            <Grid item xs={6}>
              <LanePlanLane plan={plan} pricing={pricing} />
            </Grid>
          </Grid>

          <Grid container direction='column' item xs={4}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title='Pricing' />

                  <CardContent>
                    <LanePlanPricing data={pricing} plannedShipperRpm={plan.plannedShipperRpm} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title='Volume' />

                  <CardContent>
                    <LanePlanVolume
                      explorers={explorers}
                      plannedWeeklyVolume={plan.plannedWeeklyVolume}
                      routeId={plan.route.id}
                      shipperId={plan.shipper.id}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction='column' item xs={4}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title='Explorers' />

                  <CardContent>
                    <LanePlanExplorers adaptLaneId={plan.adaptLaneId} explorers={explorers} />
                  </CardContent>
                </Card>

                <Grid item sx={{ pt: '16px' }} xs={12}>
                  <LanePlanComments lanePlanId={plan.id} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Page>
    </>
  )
}
export { LanePlanDetails }
