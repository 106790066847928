import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetCompanyByIdDocument,
  type GetCompanyByIdQuery,
} from '@/features/company/company-details.api.generated'

type CompanyType = Types.NoUndefined<GetCompanyByIdQuery['companiesByPk']>
type AdaptType = Types.NoUndefined<GetCompanyByIdQuery['adaptCompanyDataByPk']>
type CompanyQueryReturnType = AdaptType & CompanyType

const companyDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const companyResponse = await graphqlClient.request(GetCompanyByIdDocument, { id })
    const data = clean(companyResponse) as GetCompanyByIdQuery
    const company = (data.companiesByPk || {}) as CompanyType
    const adapt = (data.adaptCompanyDataByPk || {}) as AdaptType
    return {
      ...company,
      ...adapt,
    } as CompanyQueryReturnType
  },
  queryKey: [`company-details-${id}`],
})

export { companyDetailsQuery }
export type { CompanyQueryReturnType }
