import { useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EquipmentClasses } from '@leaf/constants'
import { Columns, Table, renderCompanyAvatar } from '@leaf/table'
import { download } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { getLanePlanExport } from '@/features/lane-plan/lane-plan-overview.api'
import { useGetLanePlansQuery } from '@/features/lane-plan/lane-plan-overview.api.generated'
import { LanePlanImportDialog } from '@/features/lane-plan/lane-plan-overview-import'
import { useGetLanePlanStatusesQuery } from '@/features/lane-plan/lane-plan-statuses.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const LanePlanOverview = () => {
  const [isLanePlanImportDialogOpen, setIsLanePlanImportDialogOpen] = useState(false)
  const [addSnackbar, changeTable, savedState] = useStore(
    useShallow((state) => [state.addSnackbar, state.changeTable, state.lanePlanOverview]),
  )

  const { data, isLoading, refetch } = useGetLanePlansQuery(
    graphqlClient,
    {},
    {
      select: ({ lanePlans }) =>
        lanePlans.map((lanePlan) => {
          const origins: string[] = []
          const destinations: string[] = []
          lanePlan.route?.legs?.forEach((leg) => {
            const { destinationName, originName } = leg.lane
            if (originName) {
              origins.push(originName)
            }
            if (destinationName) {
              destinations.push(destinationName)
            }
          })
          return {
            ...lanePlan,
            destinations,
            origins,
          }
        }),
    },
  )
  const { data: statuses } = useGetLanePlanStatusesQuery(
    graphqlClient,
    {},
    { select: ({ lanePlanStatus }) => lanePlanStatus.map((status) => status.name) },
  )

  const toggleLanePlanImportDialog = () =>
    setIsLanePlanImportDialogOpen(!isLanePlanImportDialogOpen)

  const importOptions = {
    onClick: toggleLanePlanImportDialog,
  }
  const exportOptions = {
    onClick: async (ids: string[]) => {
      addSnackbar({
        autoHideDuration: null,
        message: 'Your export is being prepared. It will download automatically when ready.',
        severity: 'info',
      })
      try {
        const { content, name } = await getLanePlanExport(ids)
        download.trigger(name || 'lane_plan_export.xlsx', content)
        addSnackbar({ message: 'Your export was downloaded successfully.' })
      } catch (e) {
        addSnackbar({
          message: 'Something went wrong with the export.',
          severity: 'error',
        })
      }
    },
  }

  const columns = [
    Columns.Action(),
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'shipper.id',
      headerName: 'Shipper ID',
    },
    {
      field: 'shipper.name',
      headerName: 'Shipper',
      minWidth: 200,
      renderCell: renderCompanyAvatar({
        id: 'shipper.id',
        name: 'shipper.name',
      }),
    },
    {
      field: 'route.id',
      headerName: 'Route ID',
      minWidth: 300,
    },
    {
      field: 'origins',
      headerName: 'Origin',
      minWidth: 250,
      sortable: false,
    },
    {
      field: 'destinations',
      headerName: 'Destination',
      minWidth: 250,
      sortable: false,
    },
    {
      field: 'equipmentClass',
      headerName: 'Equipment Class',
      type: 'singleSelect',
      valueOptions: [EquipmentClasses.DRY, EquipmentClasses.REEFER],
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: statuses,
    },
    {
      field: 'deprioritizationReason',
      headerName: 'Deprioritization Reason',
    },
    {
      field: 'adaptLaneId',
      headerName: 'Adapt Lane ID',
    },
    {
      field: 'fuelSurchargeSchedule.id',
      headerName: 'FSC ID',
    },
    {
      field: 'fuelSurchargeSchedule.name',
      headerName: 'FSC',
      link: {
        field: 'fuelSurchargeSchedule.id',
        to: '/fuel-surcharge-schedules?id=',
      },
      type: 'link',
    },
    {
      field: 'pickupType',
      headerName: 'Pickup Type',
    },
    {
      field: 'deliveryType',
      headerName: 'Delivery Type',
    },
    {
      field: 'plannedWeeklyVolume',
      headerName: 'Weekly Volume',
      type: 'number',
    },
    {
      field: 'plannedShipperRpm',
      headerName: 'Shipper RPM',
      type: 'money',
    },
    {
      field: 'plannedStartDate',
      headerName: 'Start Date',
      type: 'date',
    },
    {
      field: 'targetPercDedicated',
      headerName: 'Dedicated',
      type: 'percent',
    },
    {
      field: 'targetPercYellow',
      headerName: 'Yellow',
      type: 'percent',
    },
    {
      field: 'targetPercBlue',
      headerName: 'Blue',
      type: 'percent',
    },
    {
      field: 'targetPercGreen',
      headerName: 'Green',
      type: 'percent',
    },
    {
      field: 'estCarrierRate',
      headerName: 'Carrier Rate',
      type: 'money',
    },
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        'adaptLaneId': false,
        'deliveryType.id': false,
        'equipmentClass.id': false,
        'fuelSurchargeSchedule.id': false,
        'fuelSurchargeSchedule.name': false,
        'id': true,
        'pickupType.id': false,
        'route.id': false,
        'shipper.id': false,
      },
    },
  }

  useTitles([{ value: 'Plans' }])

  return (
    <>
      <Table
        // @ts-expect-error
        columns={columns}
        exportOptions={exportOptions}
        importOptions={importOptions}
        initialState={initialState}
        loading={isLoading}
        name='lanePlanOverview'
        persist={{
          fn: changeTable,
          save: savedState,
        }}
        rows={data}
      />

      <LanePlanImportDialog
        onClose={toggleLanePlanImportDialog}
        onSuccess={refetch}
        open={isLanePlanImportDialogOpen}
      />
    </>
  )
}

export { LanePlanOverview }
