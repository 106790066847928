// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetFuelSurchargeSchedulesOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFuelSurchargeSchedulesOverviewQuery = { __typename?: 'query_root', fuelSurchargeSchedules: Array<{ __typename?: 'fuelSurchargeSchedules', id: any, name: string, validFrom?: any, peg: any, bucketSize: any, increment: any, isFirstStepZero: boolean, createdAt: any, updatedAt: any, company?: { __typename?: 'companies', id: any, name: string, companyType?: string }, equipmentType?: { __typename?: 'equipmentTypes', id: any, name: string }, fuelPriceIndex: { __typename?: 'fuelPriceIndexes', id: any, name: string }, createdByUser?: { __typename?: 'users', id: any, email: string }, updatedByUser?: { __typename?: 'users', id: any, email: string } }> };

export type InsertFuelSurchargeScheduleMutationVariables = Types.Exact<{
  fsc: Types.FuelSurchargeSchedulesInsertInput;
}>;


export type InsertFuelSurchargeScheduleMutation = { __typename?: 'mutation_root', insertFuelSurchargeSchedulesOne?: { __typename?: 'fuelSurchargeSchedules', id: any } };



export const GetFuelSurchargeSchedulesOverviewDocument = `
    query GetFuelSurchargeSchedulesOverview {
  fuelSurchargeSchedules(orderBy: {createdAt: DESC}) {
    id
    name
    validFrom
    company {
      id
      name
      companyType
    }
    equipmentType {
      id
      name
    }
    fuelPriceIndex {
      id
      name
    }
    peg
    bucketSize
    increment
    isFirstStepZero
    createdAt
    createdByUser {
      id
      email
    }
    updatedAt
    updatedByUser {
      id
      email
    }
  }
}
    `;

export const useGetFuelSurchargeSchedulesOverviewQuery = <
      TData = GetFuelSurchargeSchedulesOverviewQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetFuelSurchargeSchedulesOverviewQueryVariables,
      options?: UseQueryOptions<GetFuelSurchargeSchedulesOverviewQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFuelSurchargeSchedulesOverviewQuery, TError, TData>(
      variables === undefined ? ['GetFuelSurchargeSchedulesOverview'] : ['GetFuelSurchargeSchedulesOverview', variables],
      fetcher<GetFuelSurchargeSchedulesOverviewQuery, GetFuelSurchargeSchedulesOverviewQueryVariables>(client, GetFuelSurchargeSchedulesOverviewDocument, variables, headers),
      options
    )};

useGetFuelSurchargeSchedulesOverviewQuery.document = GetFuelSurchargeSchedulesOverviewDocument;

useGetFuelSurchargeSchedulesOverviewQuery.getKey = (variables?: GetFuelSurchargeSchedulesOverviewQueryVariables) => variables === undefined ? ['GetFuelSurchargeSchedulesOverview'] : ['GetFuelSurchargeSchedulesOverview', variables];


useGetFuelSurchargeSchedulesOverviewQuery.fetcher = (client: GraphQLClient, variables?: GetFuelSurchargeSchedulesOverviewQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFuelSurchargeSchedulesOverviewQuery, GetFuelSurchargeSchedulesOverviewQueryVariables>(client, GetFuelSurchargeSchedulesOverviewDocument, variables, headers);

export const InsertFuelSurchargeScheduleDocument = `
    mutation insertFuelSurchargeSchedule($fsc: fuelSurchargeSchedulesInsertInput!) {
  insertFuelSurchargeSchedulesOne(object: $fsc) {
    id
  }
}
    `;

export const useInsertFuelSurchargeScheduleMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InsertFuelSurchargeScheduleMutation, TError, InsertFuelSurchargeScheduleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<InsertFuelSurchargeScheduleMutation, TError, InsertFuelSurchargeScheduleMutationVariables, TContext>(
      ['insertFuelSurchargeSchedule'],
      (variables?: InsertFuelSurchargeScheduleMutationVariables) => fetcher<InsertFuelSurchargeScheduleMutation, InsertFuelSurchargeScheduleMutationVariables>(client, InsertFuelSurchargeScheduleDocument, variables, headers)(),
      options
    )};

useInsertFuelSurchargeScheduleMutation.getKey = () => ['insertFuelSurchargeSchedule'];


useInsertFuelSurchargeScheduleMutation.fetcher = (client: GraphQLClient, variables: InsertFuelSurchargeScheduleMutationVariables, headers?: RequestInit['headers']) => fetcher<InsertFuelSurchargeScheduleMutation, InsertFuelSurchargeScheduleMutationVariables>(client, InsertFuelSurchargeScheduleDocument, variables, headers);
