// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetContractsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetContractsQuery = { __typename?: 'query_root', contracts: Array<{ __typename?: 'contracts', id: any, contractStatus: string, contractType?: string, minVolume?: number, maxVolume?: number, volumeType: string, volumeFrequency?: string, startDate?: any, endDate?: any, linehaulRpm?: any, pricingMechanism?: string, pricingIndex?: string, pricingIndexPremium?: any, pricingIndexPremiumOverrideSun?: any, packageId?: any, minimumLinehaulCharge?: any, minChargeableMiles?: any, isFleet: boolean, minChargeableMilesFrequency?: string, fixedFeePerShipment?: any, includeWeekends?: boolean, carrierUpcharge?: any, assetPricing?: any, assetPricingFrequency?: string, volumePattern?: string, createdAt: any, isBackupAward: boolean, useBtf: boolean, minTenderLeadTimeHrs?: number, updatedAt: any, buyer?: { __typename?: 'companies', id: any, name: string }, seller?: { __typename?: 'companies', id: any, name: string }, equipmentType: { __typename?: 'equipmentTypes', id: any, name: string }, contractAccessorials: Array<{ __typename?: 'contractAccessorials', accessorial: { __typename?: 'accessorials', id: any, name: string } }>, contractRoutes: Array<{ __typename?: 'contractRoutes', routeId: any, isPrimary?: boolean, route: { __typename?: 'routingRoutes', tripType: string, legs: Array<{ __typename?: 'routingLegs', lane: { __typename?: 'routingLanes', originName?: string, destinationName?: string } }> } }>, shipperRoutePlans: Array<{ __typename?: 'ContractingContractShipperRoutePlan', shipperRoutePlanId: any }>, domiciles: Array<{ __typename?: 'ContractingDedicatedDomicile', id: any, name: string, street: string, city: string, state: string, zip: string, geo: any }>, fuelSurchargeSchedule?: { __typename?: 'fuelSurchargeSchedules', id: any, name: string }, createdByUser?: { __typename?: 'users', id: any, email: string }, updatedByUser?: { __typename?: 'users', id: any, email: string } }> };



export const GetContractsDocument = `
    query GetContracts @cached(ttl: 300) {
  contracts(orderBy: {createdAt: DESC}) {
    id
    contractStatus
    contractType
    minVolume
    maxVolume
    volumeType
    volumeFrequency
    buyer {
      id
      name
    }
    seller {
      id
      name
    }
    equipmentType {
      id
      name
    }
    contractAccessorials {
      accessorial {
        id
        name
      }
    }
    contractRoutes {
      routeId
      isPrimary
      route {
        tripType
        legs {
          lane {
            originName
            destinationName
          }
        }
      }
    }
    contractType
    shipperRoutePlans {
      shipperRoutePlanId
    }
    startDate
    endDate
    linehaulRpm
    pricingMechanism
    pricingIndex
    pricingIndexPremium
    pricingIndexPremiumOverrideSun
    packageId
    domiciles(orderBy: {createdAt: DESC}) {
      id
      name
      street
      city
      state
      zip
      geo
    }
    minimumLinehaulCharge
    minChargeableMiles
    isFleet
    minChargeableMilesFrequency
    fixedFeePerShipment
    includeWeekends
    fuelSurchargeSchedule {
      id
      name
    }
    carrierUpcharge
    assetPricing
    assetPricingFrequency
    volumePattern
    createdByUser {
      id
      email
    }
    createdAt
    updatedByUser {
      id
      email
    }
    createdByUser {
      id
      email
    }
    createdAt
    isBackupAward
    useBtf
    minTenderLeadTimeHrs
    updatedByUser {
      id
      email
    }
    updatedAt
  }
}
    `;

export const useGetContractsQuery = <
      TData = GetContractsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetContractsQueryVariables,
      options?: UseQueryOptions<GetContractsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetContractsQuery, TError, TData>(
      variables === undefined ? ['GetContracts'] : ['GetContracts', variables],
      fetcher<GetContractsQuery, GetContractsQueryVariables>(client, GetContractsDocument, variables, headers),
      options
    )};

useGetContractsQuery.document = GetContractsDocument;

useGetContractsQuery.getKey = (variables?: GetContractsQueryVariables) => variables === undefined ? ['GetContracts'] : ['GetContracts', variables];


useGetContractsQuery.fetcher = (client: GraphQLClient, variables?: GetContractsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetContractsQuery, GetContractsQueryVariables>(client, GetContractsDocument, variables, headers);
