import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Alert, Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import { isBefore } from 'date-fns'
import {
  AutocompleteElement,
  CheckboxElement,
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
  type TextFieldElementProps,
  TextareaAutosizeElement,
  useForm,
  useWatch,
} from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'
import * as z from 'zod'

import { javalinErrorToString } from '@leaf/api'
import { DataRowSection, Progress, Text } from '@leaf/components'
import {
  LocationAutocomplete,
  clean,
  createRequire,
  select,
  useLocations,
  validation as v,
} from '@leaf/form'
import { usePrevious, useUpdateEffect } from '@leaf/hooks'
import type * as Types from '@leaf/types'
import { time } from '@leaf/utilities'

import { graphqlClient, restClient } from '@/api'
import { useGetAccessorialsQuery } from '@/api/accessorials.api.generated'
import { useGetCompaniesQuery } from '@/api/companies.api.generated'
import { useGetEquipmentTypesQuery } from '@/api/equipment-types.api.generated'
import { useGetFuelSurchargeSchedulesQuery } from '@/api/fuel-surcharge-schedules.api.generated'
import { useStore } from '@/store'

const isInvalidDailyFrequency = (df?: null | number | string) => {
  if (df === null) {
    return true
  }
  if (df === undefined) {
    return true
  }
  if (df === '') {
    return true
  }
  if (Number(df) < 0) {
    return true
  }
  return false
}

/* eslint-disable perfectionist/sort-objects */
const schema = z
  .object({
    company: v._select,
    type: v._string,
    status: v._string,
    planId: v.__string,
    notes: v.__string,
    equipmentType: v._select,
    accessorials: v.__selects,

    // CARRIER
    fleet: v.__boolean,
    domicileAddress: z.record(z.any(), z.any()).nullish(),
    domicileName: v.__string,

    backupAward: v.__boolean,
    volumeType: v._string,
    volumeFrequency: v._string,
    startDate: v.__date,
    endDate: v.__date,

    useBtf: v.__boolean,

    // WEEKLY
    minVolume: v.__number,
    maxVolume: v.__number,

    // DAILY
    includeWeekends: v.__boolean,
    su: v.__number,
    sa: v.__number,
    mo: v.__number,
    tu: v.__number,
    th: v.__number,
    we: v.__number,
    fr: v.__number,

    pricingMechanism: v._string,

    // FIXED
    fixedFeePerShipment: v.__number,
    linehaulRpm: v.__number,
    minLinehaulCharge: v.__number,

    // INDEX
    pricingIndex: v.__string,
    allIn: v.__boolean,
    pricingIndexPremium: v.__number,
    pricingIndexPremiumOverrideSunday: v.__number,
    minTenderLeadTime: v.__number,

    // OPEN
    carrierUpcharge: v.__number,

    // CARRIER AND NOT INDEX
    assetPricing: v.__number,
    assetPricingFrequency: v.__string,
    minChargeableMiles: v.__number,
    minChargeableMilesFrequency: v.__string,

    // NOT INDEX AND NOT ALL_IN
    fuelSurchargeSchedule: v.__select,
  })
  .superRefine((values, ctx) => {
    const customRequire = createRequire(ctx)

    customRequire(values.fleet && !values.domicileName, 'domicileName')
    customRequire(values.fleet && !values.domicileAddress, 'domicileAddress')

    customRequire(values.volumeFrequency === 'WEEKLY' && !values.maxVolume, 'maxVolume')

    if (values.volumeFrequency === 'DAILY') {
      customRequire(isInvalidDailyFrequency(values.su), 'su')
      customRequire(isInvalidDailyFrequency(values.mo), 'mo')
      customRequire(isInvalidDailyFrequency(values.tu), 'tu')
      customRequire(isInvalidDailyFrequency(values.we), 'we')
      customRequire(isInvalidDailyFrequency(values.th), 'th')
      customRequire(isInvalidDailyFrequency(values.fr), 'fr')
      customRequire(isInvalidDailyFrequency(values.sa), 'sa')
    }

    customRequire(!values.allIn && !values.fuelSurchargeSchedule, 'fuelSurchargeSchedule')

    customRequire(
      values.minChargeableMiles && !values.minChargeableMilesFrequency,
      'minChargeableMilesFrequency',
    )

    if (values.startDate && values.endDate && isBefore(values.endDate, values.startDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'End date must be after start date',
        path: ['endDate'],
      })
    }
  })
type Schema = z.infer<typeof schema>

type CompaniesAggregationType = {
  carriers: { id: string; label: string }[]
  shippers: { id: string; label: string }[]
}

const toPayload = (values: Schema) => {
  const payload: Types.KeyValue = {
    contractStatus: values.status,
    equipmentTypeId: values.equipmentType.id,
    startDate: time.toDate(values.startDate, 'yyyy-MM-dd'),
    endDate: time.toDate(values.endDate, 'yyyy-MM-dd'),
    shipperRoutePlanId: values.planId,
    notes: values.notes,
    accessorialIds: values.accessorials?.map((a) => a.id),
    isBackupAward: !!values.backupAward,
    volumeType: values.volumeType,
    volumeFrequency: values.volumeFrequency,
    pricingMechanism: values.pricingMechanism,
  }

  if (values.type === 'SHIPPER') {
    payload.buyerId = values.company.id
  }

  if (values.type === 'CARRIER') {
    payload.sellerId = values.company.id
    payload.useBtf = !!values.useBtf
  }

  if (values.volumeFrequency === 'DAILY') {
    const dailyPattern = {
      SUNDAY: values.su,
      MONDAY: values.mo,
      TUESDAY: values.tu,
      WEDNESDAY: values.we,
      THURSDAY: values.th,
      FRIDAY: values.fr,
      SATURDAY: values.sa,
    }
    const volumePattern = Object.entries(dailyPattern).reduce((acc, [key, value]) => {
      if (value) {
        acc.push(`${key}=${value}`)
        return acc
      }
      return acc
    }, [] as string[])
    payload.volumePattern = volumePattern.join(',')
  }

  if (values.volumeFrequency === 'WEEKLY') {
    payload.minVolume = values.minVolume
    payload.maxVolume = values.maxVolume
    payload.includeWeekends = !!values.includeWeekends
  }

  if (values.pricingMechanism === 'FIXED') {
    payload.fixedFeePerShipment = values.fixedFeePerShipment
    payload.linehaulRpm = values.linehaulRpm
    payload.minimumLinehaulCharge = values.minLinehaulCharge
  }

  if (values.pricingMechanism === 'INDEX') {
    payload.pricingIndex = values.pricingIndex
    payload.pricingIndexPremium = values.pricingIndexPremium
    payload.pricingIndexPremiumOverrideSun = values.pricingIndexPremiumOverrideSunday
    payload.minTenderLeadTimeHrs = values.minTenderLeadTime
  }

  if (values.pricingMechanism === 'OPEN_BOOK') {
    payload.carrierUpcharge = values.carrierUpcharge
  }

  if (!(values.pricingMechanism === 'INDEX' && values.allIn)) {
    payload.fscId = values.fuelSurchargeSchedule!.id
  }

  if (values.type === 'CARRIER') {
    payload.isFleet = !!values.fleet

    if (values.fleet) {
      payload.domicile = {
        name: values.domicileName,
        city: values.domicileAddress!.city,
        state: values.domicileAddress!.state,
        zip: values.domicileAddress!.zipCode,
        street: values.domicileAddress!.address,
        geo: JSON.stringify({
          type: 'Point',
          coordinates: [values.domicileAddress!.latitude, values.domicileAddress!.longitude],
        }),
      }
    }

    if (values.pricingMechanism !== 'INDEX') {
      payload.assetPricing = values.assetPricing
      payload.assetPricingFrequency = values.assetPricingFrequency
      payload.minChargeableMiles = values.minChargeableMiles
      payload.minChargeableMilesFrequency = values.minChargeableMilesFrequency
    }
  }
  return clean(payload)
}

const ContractOverviewCreateDialog = ({ onClose, open }: Types.FormDialog) => {
  const navigate = useNavigate()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const [errors, setErrors] = useState<string[]>([])

  const { data: companiesData } = useGetCompaniesQuery(
    graphqlClient,
    {},
    { select: (response) => response.companies },
  )

  const { data: equipmentTypes } = useGetEquipmentTypesQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.equipmentTypes) },
  )

  const { data: accessorials } = useGetAccessorialsQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.accessorials) },
  )

  const { data: fuelSurchargeSchedulesData, isLoading: areFscsLoading } =
    useGetFuelSurchargeSchedulesQuery(
      graphqlClient,
      {},
      { select: (response) => response.fuelSurchargeSchedules },
    )

  const {
    loading: domicileAddressesLoading,
    locations: domicileAddresses,
    onLocationChange: onDomicileAddressChange,
  } = useLocations(restClient, () =>
    addSnackbar({ message: 'Failed to find location', severity: 'error' }),
  )

  const companies = companiesData?.reduce(
    (acc, company) => {
      const c = { id: company.id, label: company.name }
      if (company.companyType === 'SHIPPER') {
        acc.shippers.push(c)
      } else if (company.companyType === 'CARRIER') {
        acc.carriers.push(c)
      }
      return acc
    },
    { carriers: [], shippers: [] } as CompaniesAggregationType,
  ) ?? { carriers: [], shippers: [] }

  const context = useForm<Schema>({
    defaultValues: {
      su: 0,
      mo: 0,
      tu: 0,
      we: 0,
      th: 0,
      fr: 0,
      sa: 0,
      pricingIndex: 'DAT',
      pricingMechanism: 'FIXED',
      status: 'DRAFT',
      type: 'SHIPPER',
      volumeFrequency: 'WEEKLY',
      volumeType: 'FIXED',
    },
    mode: 'all',
    resolver: zr(schema),
  })

  const handleSuccess = (values: Schema) => {
    const payload = toPayload(values)

    restClient
      .post('contracting/contracts', payload)
      .then((response: any) => {
        navigate(response.data.id)
        addSnackbar({
          message: [`Contract created successfully.`, `You can now add routes.`].join('\n'),
        })
      })
      .catch((error) => {
        const messages = javalinErrorToString(error)
        setErrors(messages)
      })
  }

  const [
    type,
    company,
    equipmentType,
    fleet,
    volumeFrequency,
    pricingMechanism,
    allIn,
    minChargeableMiles,
    startDate,
  ] = useWatch({
    control: context.control,
    name: [
      'type',
      'company',
      'equipmentType',
      'fleet',
      'volumeFrequency',
      'pricingMechanism',
      'allIn',
      'minChargeableMiles',
      'startDate',
    ],
  })

  const onTypeChange = () => {
    // @ts-expect-error
    context.setValue('company', null)
  }

  const fuelSurchargeSchedules = useMemo(() => {
    const relevant = select.fromResponses(
      fuelSurchargeSchedulesData?.filter((fsc) => {
        if (fsc.companyId === company?.id) {
          return true
        }
        if (!fsc.companyId && equipmentType && equipmentType.id === fsc.equipmentTypeId) {
          return true
        }
        return false
      }) || [],
    )
    return relevant
  }, [areFscsLoading, company, equipmentType])

  const prevType = usePrevious(type) as typeof type
  const prevCompany = usePrevious(company) as typeof company
  const prevEquipmentType = usePrevious(equipmentType) as typeof equipmentType

  useUpdateEffect(() => {
    if (
      prevType !== type ||
      prevCompany?.id !== company?.id ||
      prevEquipmentType?.id !== equipmentType?.id
    ) {
      context.setValue('fuelSurchargeSchedule', null)
    }
  }, [company, equipmentType, type])

  return (
    <Dialog fullWidth maxWidth='lg' onClose={onClose} open={open}>
      <Alert severity='info'>After saving, you will be able to add routes to this contract.</Alert>

      <Progress loading={domicileAddressesLoading} />

      {errors.map((error) => (
        <Alert key={error} severity='error'>
          {error}
        </Alert>
      ))}

      <FormContainer
        FormProps={{ id: 'contract-create-form' }}
        formContext={context}
        onSuccess={handleSuccess}
      >
        <DialogContent>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <DataRowSection label={<Text.H6>General</Text.H6>} />
            </Grid>

            <Grid item xs={2}>
              <RadioButtonGroup
                name='type'
                onChange={onTypeChange}
                options={[
                  { id: 'SHIPPER', label: 'Shipper' },
                  { id: 'CARRIER', label: 'Carrier' },
                ]}
                row
              />
            </Grid>

            <Grid item xs={3}>
              <AutocompleteElement
                label='Company'
                name='company'
                options={type === 'SHIPPER' ? companies.shippers : companies.carriers}
                required
                textFieldProps={{
                  fullWidth: true,
                  variant: 'standard',
                }}
              />
            </Grid>

            <Grid item xs={1}>
              <SelectElement
                fullWidth
                label='Status'
                name='status'
                options={[
                  { id: 'DRAFT', label: 'DRAFT' },
                  { id: 'CONTRACTED', label: 'CONTRACTED' },
                  { id: 'SUGGESTED', label: 'SUGGESTED' },
                ]}
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={3}>
              <TextFieldElement fullWidth label='Plan ID' name='planId' variant='standard' />
            </Grid>

            <Grid item xs={3}>
              <TextareaAutosizeElement fullWidth label='Notes' name='notes' variant='standard' />
            </Grid>

            <Grid item xs={2}>
              <CheckboxElement label='Backup Award' name='backupAward' />
            </Grid>

            <Grid item xs={4}>
              <AutocompleteElement
                label='Equipment Type'
                name='equipmentType'
                options={equipmentTypes || []}
                required
                textFieldProps={{
                  fullWidth: true,
                  variant: 'standard',
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <AutocompleteElement
                label='Accessorials'
                multiple
                name='accessorials'
                options={accessorials || []}
                textFieldProps={{
                  fullWidth: true,
                  variant: 'standard',
                }}
              />
            </Grid>

            {type === 'CARRIER' && (
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={2}>
                  <CheckboxElement label='Fleet' name='fleet' />
                </Grid>

                {fleet && (
                  <>
                    <Grid item xs={4}>
                      <TextFieldElement
                        fullWidth
                        label='Domicile Name'
                        name='domicileName'
                        required
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <LocationAutocomplete
                        label='Domicile Address'
                        name='domicileAddress'
                        options={domicileAddresses}
                        textFieldProps={
                          { onChange: onDomicileAddressChange } as TextFieldElementProps
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <DataRowSection label={<Text.H6>Volume</Text.H6>} />
            </Grid>

            {volumeFrequency === 'WEEKLY' ? (
              <Grid item xs={2}>
                <CheckboxElement label='Weekends' name='includeWeekends' />
              </Grid>
            ) : (
              <Grid item xs={2} />
            )}

            <Grid item xs={2}>
              <SelectElement
                fullWidth
                label='Volume Type'
                name='volumeType'
                options={[
                  { id: 'FIXED', label: 'FIXED' },
                  { id: 'VARIABLE', label: 'VARIABLE' },
                ]}
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={2}>
              <SelectElement
                fullWidth
                label='Volume Frequency'
                name='volumeFrequency'
                options={[
                  { id: 'DAILY', label: 'DAILY' },
                  { id: 'WEEKLY', label: 'WEEKLY' },
                ]}
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={3}>
              <DatePickerElement
                inputProps={{
                  fullWidth: true,
                  variant: 'standard',
                }}
                label='Start Date'
                name='startDate'
              />
            </Grid>

            <Grid item xs={3}>
              <DatePickerElement
                inputProps={{
                  fullWidth: true,
                  variant: 'standard',
                }}
                label='End Date'
                minDate={startDate ? new Date(startDate) : undefined}
                name='endDate'
              />
            </Grid>

            <Grid container item spacing={2} xs={12}>
              {volumeFrequency === 'WEEKLY' && (
                <>
                  <Grid item xs={2} />

                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='Min Volume'
                      name='minVolume'
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='Max Volume'
                      name='maxVolume'
                      required
                      type='number'
                      variant='standard'
                    />
                  </Grid>
                </>
              )}

              {volumeFrequency === 'DAILY' && (
                <>
                  <Grid item xs={2} />

                  <Grid item xs={1}>
                    <TextFieldElement label='Su' name='su' required type='number' />
                  </Grid>

                  <Grid item xs={1}>
                    <TextFieldElement label='Mo' name='mo' required type='number' />
                  </Grid>

                  <Grid item xs={1}>
                    <TextFieldElement label='Tu' name='tu' required type='number' />
                  </Grid>

                  <Grid item xs={1}>
                    <TextFieldElement label='We' name='we' required type='number' />
                  </Grid>

                  <Grid item xs={1}>
                    <TextFieldElement label='Th' name='th' required type='number' />
                  </Grid>

                  <Grid item xs={1}>
                    <TextFieldElement label='Fr' name='fr' required type='number' />
                  </Grid>

                  <Grid item xs={1}>
                    <TextFieldElement label='Sa' name='sa' required type='number' />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container item spacing={2} xs={12}>
              {type === 'CARRIER' ? (
                <Grid item xs={2}>
                  <CheckboxElement label='Use BTF when available' name='useBtf' />
                </Grid>
              ) : (
                <Grid item xs={2} />
              )}

              <Grid item xs={12}>
                <DataRowSection label={<Text.H6>Pricing</Text.H6>} />
              </Grid>

              {pricingMechanism === 'INDEX' ? (
                <Grid item xs={2}>
                  <CheckboxElement label='All-in' name='allIn' />
                </Grid>
              ) : (
                <Grid item xs={2} />
              )}

              <Grid item xs={2}>
                <SelectElement
                  fullWidth
                  label='Pricing Mechanism'
                  name='pricingMechanism'
                  options={[
                    { id: 'FIXED', label: 'FIXED' },
                    { id: 'INDEX', label: 'INDEX' },
                    { id: 'OPEN_BOOK', label: 'OPEN_BOOK' },
                  ]}
                  required
                  variant='standard'
                />
              </Grid>

              {pricingMechanism === 'OPEN_BOOK' && (
                <Grid item xs={2}>
                  <TextFieldElement
                    fullWidth
                    label='Carrier Upcharge (%)'
                    name='carrierUpcharge'
                    type='number'
                    variant='standard'
                  />
                </Grid>
              )}

              {pricingMechanism === 'FIXED' && (
                <>
                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='Fixed Fee Per Shipment'
                      name='fixedFeePerShipment'
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='LH RPM'
                      name='linehaulRpm'
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='Min LH Charge'
                      name='minLinehaulCharge'
                      type='number'
                      variant='standard'
                    />
                  </Grid>
                </>
              )}

              {pricingMechanism === 'INDEX' && (
                <>
                  <Grid item xs={2}>
                    <SelectElement
                      fullWidth
                      label='Pricing Index'
                      name='pricingIndex'
                      options={[{ id: 'DAT', label: 'DAT' }]}
                      required
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='PI Premium (%)'
                      name='pricingIndexPremium'
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='PI Override Sundays (%)'
                      name='pricingIndexPremiumOverrideSunday'
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='Min Tender Lead (hours)'
                      name='minTenderLeadTime'
                      type='number'
                      variant='standard'
                    />
                  </Grid>
                </>
              )}

              {type === 'CARRIER' && pricingMechanism !== 'INDEX' && (
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={2} />

                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='Rate per Asset'
                      name='assetPricing'
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <SelectElement
                      fullWidth
                      label='Asset Pricing Frequency'
                      name='assetPricingFrequency'
                      options={[
                        { id: 'DAILY', label: 'DAILY' },
                        { id: 'WEEKLY', label: 'WEEKLY' },
                      ]}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='Min Chargeable Miles'
                      name='minChargeableMiles'
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  {minChargeableMiles && (
                    <Grid item xs={3}>
                      <SelectElement
                        fullWidth
                        label='Min Chargeable Miles Frequency'
                        name='minChargeableMilesFrequency'
                        options={[
                          { id: 'DAILY', label: 'DAILY' },
                          { id: 'WEEKLY', label: 'WEEKLY' },
                        ]}
                        required
                        variant='standard'
                      />
                    </Grid>
                  )}
                </Grid>
              )}

              <Grid container item spacing={2} xs={12}>
                <Grid item xs={2} />

                {(pricingMechanism !== 'INDEX' || (pricingMechanism === 'INDEX' && !allIn)) && (
                  <Grid item xs={4}>
                    <AutocompleteElement
                      label='FSC'
                      name='fuelSurchargeSchedule'
                      options={fuelSurchargeSchedules}
                      required={!allIn}
                      textFieldProps={{
                        fullWidth: true,
                        helperText:
                          'Select a company and an equipment type to see FSCs or go all-in with INDEX pricing',
                        variant: 'standard',
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' onClick={onClose} variant='contained'>
            Cancel
          </Button>

          <Button color='primary' type='submit' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  )
}

export { ContractOverviewCreateDialog }
