import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetUserByIdDocument,
  type GetUserByIdQuery,
} from '@/features/user/user-details.api.generated'

type UserQueryReturnType = Types.NoUndefined<GetUserByIdQuery['usersByPk']>

const userDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const userResponse = await graphqlClient.request(GetUserByIdDocument, { id })
    const data = clean(userResponse) as GetUserByIdQuery
    const user = (data.usersByPk || {}) as UserQueryReturnType
    return user
  },
  queryKey: [`user-details-${id}`],
})

export { userDetailsQuery }
export type { UserQueryReturnType }
