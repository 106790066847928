import { Fragment, useCallback, useState } from 'react'

import { Flag, Square } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { Layer, type MapMouseEvent, Popup, Source } from 'react-map-gl'

import { Map, MapLegend, Progress } from '@leaf/components'
import { color } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { useGetShippersQuery } from '@/api/companies.api.generated'
import {
  useGetLeafNetworkQuery,
  useGetShipperNetworkQuery,
} from '@/features/shipper-network/shipper-network.api.generated'
import { useTitles } from '@/hooks'

type HoverInfo = {
  destinationGeometry: GeoJSON.Polygon
  destinationName: string
  id: string
  lat: number
  lng: number
  miles: string
  originGeometry: GeoJSON.Polygon
  originName: string
}

const ShipperNetwork = () => {
  const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null)
  const [shipperIds, setShipperIds] = useState<number[]>([60])
  const [showLeafNetwork, setShowLeafNetwork] = useState<boolean>(false)

  useTitles([{ value: 'Shipper Network' }])

  const { data: shippers } = useGetShippersQuery(
    graphqlClient,
    {},
    { select: (response) => response.companies },
  )

  const { data: shipperNetworks } = useGetShipperNetworkQuery(
    graphqlClient,
    { shipperIds },
    { select: (response) => response.shipperNetworks },
  )

  const networks = shipperNetworks || []

  const { data: leafNetwork } = useGetLeafNetworkQuery(
    graphqlClient,
    {},
    { select: (response) => response.leafNetwork[0].geometry },
  )

  const onHover = useCallback((event: MapMouseEvent) => {
    const properties = event.features && event.features[0] && event.features[0]?.properties

    if (properties) {
      setHoverInfo({
        destinationGeometry: JSON.parse(properties.destinationGeometry) as GeoJSON.Polygon,
        destinationName: properties.destinationName,
        id: properties.id,
        lat: event.lngLat.lat,
        lng: event.lngLat.lng,
        miles: properties.miles?.toFixed(0),
        originGeometry: JSON.parse(properties.originGeometry) as GeoJSON.Polygon,
        originName: properties.originName,
      })
    } else {
      setHoverInfo(null)
    }
  }, [])
  const options = shippers?.length ? shippers : [{ id: 60, name: 'Demo Shipper' }]
  const selection = options.filter((s) => shipperIds.includes(s.id))

  const onChange = (_: unknown, values: any) => {
    setShipperIds(values.map((v: any) => v.id))
  }

  return (
    <>
      <Progress />

      <Grid container>
        <Grid item xs={10}>
          {shippers?.length && (
            <Autocomplete
              disableClearable
              fullWidth
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              multiple
              onChange={onChange}
              options={options}
              renderInput={(params) => <TextField {...params} variant='standard' />}
              value={selection}
            />
          )}
        </Grid>

        <Grid item sx={{ display: 'flex', justifyContent: 'end' }} xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showLeafNetwork}
                onChange={() => setShowLeafNetwork(!showLeafNetwork)}
              />
            }
            label='Leaf Network'
          />
        </Grid>
      </Grid>

      <Map
        id='e2e-company-network'
        interactiveLayerIds={networks.map(
          (shipperNetwork) => `shipperNetworkLayer-${shipperNetwork.shipperId}`,
        )}
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
        onMouseMove={onHover}
      >
        <Source data={leafNetwork} id='leafNetwork' type='geojson'>
          <Layer
            id='leafNetworkLayer'
            paint={{
              'line-color': 'rgba(0, 200, 0, 0.2)',
              'line-opacity': ['case', ['boolean', showLeafNetwork, false], 1, 0],
              'line-width': 3,
            }}
            type='line'
          />
        </Source>

        {networks!.map(({ network, shipperId }, i) => (
          <Source
            data={network}
            id={`shipperNetwork-${shipperId}`}
            key={`shipperNetwork-${shipperId}`}
            type='geojson'
          >
            <Layer
              id={`shipperNetworkLayer-${shipperId}`}
              paint={{
                'line-color': ['match', ['get', 'id'], hoverInfo?.id ?? '', 'black', color.lane(i)],
                'line-opacity': ['match', ['get', 'id'], hoverInfo?.id ?? '', 1, 0.1],
                'line-width': 4,
              }}
              type='line'
            />

            {hoverInfo && (
              <Popup
                closeButton={false}
                closeOnClick={false}
                closeOnMove={false}
                latitude={hoverInfo.lat}
                longitude={hoverInfo.lng}
                offset={[0, -10] as [number, number]}
              >
                <Divider orientation='horizontal'>Shipper {shipperId}</Divider>

                <Box
                  sx={{
                    alignItems: 'end',
                    display: 'flex',
                  }}
                >
                  <Flag sx={{ color: 'blue' }} />

                  <Typography>{hoverInfo.originName}</Typography>
                </Box>

                <Divider orientation='horizontal'>{hoverInfo.miles} mi</Divider>

                <Box
                  sx={{
                    alignItems: 'end',
                    display: 'flex',
                  }}
                >
                  <Flag sx={{ color: 'green' }} />

                  <Typography>{hoverInfo.destinationName}</Typography>
                </Box>
              </Popup>
            )}

            {hoverInfo && (
              <>
                <Source
                  data={hoverInfo.originGeometry}
                  id={`shipperOriginNetwork-${shipperId}`}
                  type='geojson'
                >
                  <Layer
                    id={`shipperOriginNetworkLayer-${shipperId}`}
                    paint={{
                      'fill-color': 'green',
                      'fill-opacity': 1,
                    }}
                    type='fill'
                  />
                </Source>

                <Source
                  data={hoverInfo.destinationGeometry}
                  id={`shipperDestinationNetwork-${shipperId}`}
                  type='geojson'
                >
                  <Layer
                    id={`shipperDestinationNetworkLayer-${shipperId}`}
                    paint={{
                      'fill-color': 'blue',
                      'fill-opacity': 1,
                    }}
                    type='fill'
                  />
                </Source>
              </>
            )}
          </Source>
        ))}

        <MapLegend>
          {networks.map(({ shipperId }, i) => (
            <Fragment key={shipperId}>
              <Square sx={{ color: color.lane(i) }} />
              Shipper {shipperId}
            </Fragment>
          ))}

          {showLeafNetwork && (
            <>
              <Square sx={{ color: 'green' }} />
              Leaf&#39;s Network
            </>
          )}
        </MapLegend>
      </Map>
    </>
  )
}

export { ShipperNetwork }
