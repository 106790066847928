import { Fragment, useState } from 'react'

import { Add as CreateIcon } from '@mui/icons-material'
import { Box, Button, Link } from '@mui/material'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useShallow } from 'zustand/react/shallow'

import { CompanyTypes } from '@leaf/constants'
import { Columns, Table, renderCompanyAvatar } from '@leaf/table'

import { graphqlClient } from '@/api'
import type { IdmBusinessUnit } from '@/api/hasura.types.generated'
import {
  type GetCompaniesOverviewQuery,
  useGetCompaniesOverviewQuery,
} from '@/features/company/company-overview.api.generated'
import { CompanyCreateDialog } from '@/features/company/company-overview-create-dialog'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

type AccountTeamMembers = GetCompaniesOverviewQuery['companies'][0]['accountTeamMembers']

const CompanyOverview = () => {
  const [addSnackbar, changeTable, savedState] = useStore(
    useShallow((state) => [state.addSnackbar, state.changeTable, state.companyOverview]),
  )
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const toggleCreateDialog = () => setShowCreateDialog(!showCreateDialog)

  useTitles([{ value: 'Companies' }])
  useActions([
    <Button key='createCompany' onClick={toggleCreateDialog} startIcon={<CreateIcon />}>
      Create
    </Button>,
  ])

  const {
    data: companies,
    isLoading,
    refetch,
  } = useGetCompaniesOverviewQuery(graphqlClient, {}, { select: (response) => response?.companies })

  const columns = [
    Columns.Action(),
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 250,
      renderCell: renderCompanyAvatar({
        field: 'companyType',
        id: 'id',
        name: 'name',
      }),
    },
    {
      field: 'companyType',
      headerName: 'Type',
      minWidth: 200,
      type: 'singleSelect',
      valueOptions: [CompanyTypes.SHIPPER, CompanyTypes.CARRIER],
    },
    {
      customValueGetter: (value: AccountTeamMembers) => {
        const emails = value?.map((accountTeamMember) => {
          return accountTeamMember.user.email
        })
        if (!emails?.length) {
          return null
        }
        return emails
      },
      field: 'accountTeamMembers',
      headerName: 'Account team',
      renderCell: ({ row }: GridRenderCellParams) => {
        const accountTeamMembers = row.accountTeamMembers as AccountTeamMembers
        const elements = accountTeamMembers.map((accountTeamMember) => {
          return (
            <span key={accountTeamMember.user.id}>
              <Link href={`/users/${accountTeamMember.user.id}`} target='_self' underline='none'>
                {accountTeamMember.user.email}
              </Link>
              <br />
            </span>
          )
        })
        return <div>{elements}</div>
      },
      type: 'list',
    },
    {
      customValueGetter: (value: IdmBusinessUnit[]) => {
        const names = value.map((v) => v.name)
        if (!names?.length) {
          return null
        }
        return names
      },
      field: 'businessUnits',
      headerName: 'Business Units',
      renderCell: ({ row }: GridRenderCellParams) => {
        const names = row.businessUnits.map((v: IdmBusinessUnit) => {
          return (
            <Fragment key={v.name}>
              <span>{v.name}</span>
              <br />
            </Fragment>
          )
        })
        return <div>{names}</div>
      },
      type: 'list',
    },
    { field: 'phone', headerName: 'Phone' },
    { field: 'tenderPhoneNumber', headerName: 'Tender Phone' },
    { field: 'tenderEmailAddress', headerName: 'Tender Email' },
    { field: 'registeredName', headerName: 'Registered Name' },
    { field: 'registeredState', headerName: 'Registered State' },
    {
      field: 'transactionFee',
      headerName: 'Transaction Fee',
      type: 'percent',
    },
    { field: 'usMcNumber', headerName: 'MC' },
    { field: 'usDotNumber', headerName: 'DOT' },
    { field: 'scacCode', headerName: 'SCAC' },
    {
      field: 'useTrimbleIntegration',
      headerName: 'Trimble',
      type: 'boolean',
    },
    {
      field: 'useProject44Integration',
      headerName: 'Project 44',
      type: 'boolean',
    },
    {
      field: 'use4kitesIntegration',
      headerName: 'FourKites',
      type: 'boolean',
    },
    ...Columns.Meta(),
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: true,
        phone: false,
        registeredName: false,
        registeredState: false,
        scacCode: false,
        tenderEmailAddress: false,
        tenderPhoneNumber: false,
        usDotNumber: false,
        usMcNumber: false,
        use4kitesIntegration: false,
        useProject44Integration: false,
        useTrimbleIntegration: false,
      },
    },
  }

  const exportOptions = { fileName: 'companies' }

  const onSuccess = (_: unknown, payload: any) => {
    refetch()
    toggleCreateDialog()
    addSnackbar({ message: `${payload.name} created successfully` })
  }

  return (
    <Box id='e2e-companies-overview'>
      <Table
        // @ts-expect-error
        columns={columns}
        exportOptions={exportOptions}
        initialState={initialState}
        loading={isLoading}
        name='companyOverview'
        persist={{
          fn: changeTable,
          save: savedState,
        }}
        rows={companies}
      />

      {showCreateDialog && (
        <CompanyCreateDialog
          onClose={toggleCreateDialog}
          onSuccess={onSuccess}
          open={showCreateDialog}
        />
      )}
    </Box>
  )
}

export { CompanyOverview }
