import { useState } from 'react'

import { Add as CreateIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useShallow } from 'zustand/react/shallow'

import { Columns, Table, renderCompanyAvatar } from '@leaf/table'

import { graphqlClient } from '@/api'
import { useGetFuelSurchargeSchedulesOverviewQuery } from '@/features/fuel-surcharge-schedule/fuel-surcharge-schedule-overview.api.generated'
import { FuelSurchargeScheduleCreateDialog } from '@/features/fuel-surcharge-schedule/fuel-surcharge-schedule-overview-create-dialog'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const FuelSurchargeScheduleOverview = () => {
  const [addSnackbar, changeTable, savedState] = useStore(
    useShallow((state) => [
      state.addSnackbar,
      state.changeTable,
      state.fuelSurchargeScheduleOverview,
    ]),
  )

  const [showCreateDialog, setShowCreateDialog] = useState(false)

  const { data, isLoading, refetch } = useGetFuelSurchargeSchedulesOverviewQuery(
    graphqlClient,
    {},
    { select: ({ fuelSurchargeSchedules }) => fuelSurchargeSchedules },
  )

  const toggleCreateDialog = () => setShowCreateDialog(!showCreateDialog)

  const onSuccess = (_: unknown, payload: any) => {
    refetch()
    toggleCreateDialog()
    addSnackbar({ message: `FSC '${payload.name}' created successfully` })
  }

  useTitles([{ value: 'Fuel Surcharge Schedules' }])
  useActions([
    <Button key='createFsc' onClick={toggleCreateDialog} startIcon={<CreateIcon />}>
      Create
    </Button>,
  ])

  const columns = [
    Columns.Action(),
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 250,
    },
    {
      field: 'validFrom',
      headerName: 'Valid From',
      type: 'date',
    },
    {
      field: 'peg',
      headerName: 'PEG',
      type: 'money',
    },
    {
      field: 'bucketSize',
      headerName: 'Bucket Size',
      type: 'number',
    },
    {
      field: 'increment',
      headerName: 'Increment',
      type: 'number',
    },
    // ? Causes an error in console, should be fixed soon, see: https://github.com/mui/mui-x/issues/9335
    {
      field: 'isFirstStepZero',
      headerName: 'First Step Zero',
      type: 'boolean',
    },
    {
      field: 'company.id',
      headerName: 'Company ID',
    },
    {
      field: 'company.name',
      headerName: 'Company',
      renderCell: renderCompanyAvatar({
        id: 'company.id',
        name: 'company.name',
      }),
    },
    {
      field: 'equipmentType.id',
      headerName: 'Equipment ID',
    },
    {
      field: 'equipmentType.name',
      headerName: 'Equipment',
      link: {
        field: 'equipmentType.id',
        to: '/equipment-types?id=',
      },
      minWidth: 175,
      type: 'link',
    },
    {
      field: 'fuelPriceIndex.id',
      headerName: 'FPI ID',
    },
    {
      field: 'fuelPriceIndex.name',
      headerName: 'FPI',
    },
    ...Columns.Meta(),
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        'company.id': false,
        'equipmentType.id': false,
        'fuelPriceIndex.id': false,
      },
    },
  }

  const exportOptions = { fileName: 'fuel_surcharge_schedules' }

  return (
    <>
      <Table
        // @ts-expect-error
        columns={columns}
        exportOptions={exportOptions}
        initialState={initialState}
        loading={isLoading}
        name='fuelSurchargeScheduleOverview'
        persist={{
          fn: changeTable,
          save: savedState,
        }}
        rows={data}
      />

      <FuelSurchargeScheduleCreateDialog
        onClose={toggleCreateDialog}
        onSuccess={onSuccess}
        open={showCreateDialog}
      />
    </>
  )
}

export { FuelSurchargeScheduleOverview }
