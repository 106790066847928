// @ts-nocheck
/* eslint-disable */
import type * as Types from './hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetFuelPriceIndexesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFuelPriceIndexesQuery = { __typename?: 'query_root', fuelPriceIndexes: Array<{ __typename?: 'fuelPriceIndexes', id: any, name: string }> };



export const GetFuelPriceIndexesDocument = `
    query GetFuelPriceIndexes @cached(ttl: 300) {
  fuelPriceIndexes(orderBy: {name: ASC}) {
    id
    name
  }
}
    `;

export const useGetFuelPriceIndexesQuery = <
      TData = GetFuelPriceIndexesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetFuelPriceIndexesQueryVariables,
      options?: UseQueryOptions<GetFuelPriceIndexesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFuelPriceIndexesQuery, TError, TData>(
      variables === undefined ? ['GetFuelPriceIndexes'] : ['GetFuelPriceIndexes', variables],
      fetcher<GetFuelPriceIndexesQuery, GetFuelPriceIndexesQueryVariables>(client, GetFuelPriceIndexesDocument, variables, headers),
      options
    )};

useGetFuelPriceIndexesQuery.document = GetFuelPriceIndexesDocument;

useGetFuelPriceIndexesQuery.getKey = (variables?: GetFuelPriceIndexesQueryVariables) => variables === undefined ? ['GetFuelPriceIndexes'] : ['GetFuelPriceIndexes', variables];


useGetFuelPriceIndexesQuery.fetcher = (client: GraphQLClient, variables?: GetFuelPriceIndexesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFuelPriceIndexesQuery, GetFuelPriceIndexesQueryVariables>(client, GetFuelPriceIndexesDocument, variables, headers);
