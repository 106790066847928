import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { adaptNetworkMoveDetailsQuery } from '@/features/adapt-network-move/adapt-network-move-details.api'

const adaptNetworkMoveDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = adaptNetworkMoveDetailsQuery(params.id!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { adaptNetworkMoveDetailsLoader }
