import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { lanePlanDetailsQuery } from '@/features/lane-plan/lane-plan-details.api'

const lanePlanDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = lanePlanDetailsQuery(params.id!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { lanePlanDetailsLoader }
