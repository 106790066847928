import { useEffect } from 'react'

import { useStore } from '@/store'
import type { Title } from '@/types'

/**
 * @param titles Either an array which overrides the stored titles or a single element that gets appended
 */
const useTitles = (titles: Title | Title[]) => {
  const { titles: storedTitles, updateActions, updateTitles } = useStore.getState()

  useEffect(() => {
    if (Array.isArray(titles)) {
      updateTitles(titles)
      updateActions([])
    } else {
      updateTitles([...storedTitles, titles])
      updateActions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titles])
}

export { useTitles }
