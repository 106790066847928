import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetAdaptConstellationDocument,
  type GetAdaptConstellationQuery,
} from '@/features/adapt-constellation/adapt-constellation-details.api.generated'

type AdaptConstellationDetailsQueryReturnType = Types.NoUndefined<
  GetAdaptConstellationQuery['constellationsByPk']
>

const adaptConstellationDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const response = await graphqlClient.request(GetAdaptConstellationDocument, { id })
    const data = clean(response) as GetAdaptConstellationQuery
    const constellation = (data.constellationsByPk ||
      {}) as AdaptConstellationDetailsQueryReturnType
    return constellation
  },
  queryKey: [`constellation-details-${id}`],
})

export { adaptConstellationDetailsQuery }
export type { AdaptConstellationDetailsQueryReturnType }
