import { Box, styled } from '@mui/material'

const CompanyAvatarUploadStyled = styled(Box)`
  position: relative;
  .MuiAvatar-root {
    height: 100px;
    width: 100px;
  }
  .MuiSvgIcon-root {
    position: absolute;
    top: 6px;
    right: 2px;
    z-index: 1;
    height: 16px;
    color: gray;
    cursor: pointer;
  }
`

const CompanyAvatarCircleStyled = styled(Box)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 2px;
  right: -6px;
  z-index: 1;
  border: 1px solid gray;
`

export { CompanyAvatarCircleStyled, CompanyAvatarUploadStyled }
