import { fileClient } from '@leaf/utilities'

import { auth } from '@/api'

const getTemplate = async () => {
  const client = await fileClient(auth.token!)
  return client.download(`${import.meta.env.VITE_API}/contracting/contracts/import`)
}

const importContracts = async (file: File) => {
  const client = await fileClient(auth.token!)
  return client.upload(`${import.meta.env.VITE_API}/contracting/contracts/import`, file)
}

export { getTemplate, importContracts }
