// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetExecutionLaneOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetExecutionLaneOverviewQuery = { __typename?: 'query_root', executionLanes: Array<{ __typename?: 'executionLanes', id: number, leafDistance: number, shipperDistance?: any, referenceRpm?: any, co2ReductionPerShipment?: any, tolls?: any, drivingTimeMinutes?: any, createdAt?: any, lastUpdatedAt?: any, shipper: { __typename?: 'companies', id: any, name: string }, origin: { __typename?: 'ExecutionLocation', city?: string, state?: string, zip?: string, street?: string, businessName?: string }, destination: { __typename?: 'ExecutionLocation', city?: string, state?: string, zip?: string, street?: string, businessName?: string } }> };



export const GetExecutionLaneOverviewDocument = `
    query GetExecutionLaneOverview @cached(ttl: 300) {
  executionLanes(orderBy: {lastUpdatedAt: DESC}) {
    id
    shipper {
      id
      name
    }
    origin {
      city
      state
      zip
      street
      businessName
    }
    destination {
      city
      state
      zip
      street
      businessName
    }
    leafDistance
    shipperDistance
    referenceRpm
    co2ReductionPerShipment
    tolls
    drivingTimeMinutes
    createdAt
    lastUpdatedAt
  }
}
    `;

export const useGetExecutionLaneOverviewQuery = <
      TData = GetExecutionLaneOverviewQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetExecutionLaneOverviewQueryVariables,
      options?: UseQueryOptions<GetExecutionLaneOverviewQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetExecutionLaneOverviewQuery, TError, TData>(
      variables === undefined ? ['GetExecutionLaneOverview'] : ['GetExecutionLaneOverview', variables],
      fetcher<GetExecutionLaneOverviewQuery, GetExecutionLaneOverviewQueryVariables>(client, GetExecutionLaneOverviewDocument, variables, headers),
      options
    )};

useGetExecutionLaneOverviewQuery.document = GetExecutionLaneOverviewDocument;

useGetExecutionLaneOverviewQuery.getKey = (variables?: GetExecutionLaneOverviewQueryVariables) => variables === undefined ? ['GetExecutionLaneOverview'] : ['GetExecutionLaneOverview', variables];


useGetExecutionLaneOverviewQuery.fetcher = (client: GraphQLClient, variables?: GetExecutionLaneOverviewQueryVariables, headers?: RequestInit['headers']) => fetcher<GetExecutionLaneOverviewQuery, GetExecutionLaneOverviewQueryVariables>(client, GetExecutionLaneOverviewDocument, variables, headers);
