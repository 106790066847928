// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetCompaniesOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompaniesOverviewQuery = { __typename?: 'query_root', companies: Array<{ __typename?: 'companies', id: any, name: string, companyType?: string, phone?: string, tenderPhoneNumber?: string, tenderEmailAddress?: string, registeredName?: string, registeredState?: string, transactionFee?: any, usMcNumber?: string, usDotNumber?: string, scacCode?: string, useTrimbleIntegration?: boolean, useProject44Integration?: boolean, use4kitesIntegration?: boolean, createdAt: any, updatedAt: any, accountTeamMembers: Array<{ __typename?: 'accountTeamMembers', user: { __typename?: 'users', id: any, email: string } }>, businessUnits: Array<{ __typename?: 'IdmBusinessUnit', name: string }>, createdByUser?: { __typename?: 'users', id: any, email: string }, updatedByUser?: { __typename?: 'users', id: any, email: string } }> };

export type InsertCompanyMutationVariables = Types.Exact<{
  company: Types.CompaniesInsertInput;
}>;


export type InsertCompanyMutation = { __typename?: 'mutation_root', insertCompaniesOne?: { __typename?: 'companies', id: any } };



export const GetCompaniesOverviewDocument = `
    query GetCompaniesOverview {
  companies(orderBy: {createdAt: DESC}) {
    id
    name
    companyType
    phone
    tenderPhoneNumber
    tenderEmailAddress
    registeredName
    registeredState
    transactionFee
    usMcNumber
    usDotNumber
    scacCode
    accountTeamMembers {
      user {
        id
        email
      }
    }
    businessUnits {
      name
    }
    useTrimbleIntegration
    useProject44Integration
    use4kitesIntegration
    createdByUser {
      id
      email
    }
    createdAt
    updatedByUser {
      id
      email
    }
    updatedAt
  }
}
    `;

export const useGetCompaniesOverviewQuery = <
      TData = GetCompaniesOverviewQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCompaniesOverviewQueryVariables,
      options?: UseQueryOptions<GetCompaniesOverviewQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCompaniesOverviewQuery, TError, TData>(
      variables === undefined ? ['GetCompaniesOverview'] : ['GetCompaniesOverview', variables],
      fetcher<GetCompaniesOverviewQuery, GetCompaniesOverviewQueryVariables>(client, GetCompaniesOverviewDocument, variables, headers),
      options
    )};

useGetCompaniesOverviewQuery.document = GetCompaniesOverviewDocument;

useGetCompaniesOverviewQuery.getKey = (variables?: GetCompaniesOverviewQueryVariables) => variables === undefined ? ['GetCompaniesOverview'] : ['GetCompaniesOverview', variables];


useGetCompaniesOverviewQuery.fetcher = (client: GraphQLClient, variables?: GetCompaniesOverviewQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCompaniesOverviewQuery, GetCompaniesOverviewQueryVariables>(client, GetCompaniesOverviewDocument, variables, headers);

export const InsertCompanyDocument = `
    mutation InsertCompany($company: companiesInsertInput!) {
  insertCompaniesOne(object: $company) {
    id
  }
}
    `;

export const useInsertCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InsertCompanyMutation, TError, InsertCompanyMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<InsertCompanyMutation, TError, InsertCompanyMutationVariables, TContext>(
      ['InsertCompany'],
      (variables?: InsertCompanyMutationVariables) => fetcher<InsertCompanyMutation, InsertCompanyMutationVariables>(client, InsertCompanyDocument, variables, headers)(),
      options
    )};

useInsertCompanyMutation.getKey = () => ['InsertCompany'];


useInsertCompanyMutation.fetcher = (client: GraphQLClient, variables: InsertCompanyMutationVariables, headers?: RequestInit['headers']) => fetcher<InsertCompanyMutation, InsertCompanyMutationVariables>(client, InsertCompanyDocument, variables, headers);
