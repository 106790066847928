// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetActiveAdminsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetActiveAdminsQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, email: string }> };

export type GetCompanyByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type GetCompanyByIdQuery = { __typename?: 'query_root', companiesByPk?: { __typename?: 'companies', id: any, name: string, companyType?: string, phone?: string, tenderPhoneNumber?: string, tenderEmailAddress?: string, registeredName?: string, registeredState?: string, transactionFee?: any, usMcNumber?: string, usDotNumber?: string, scacCode?: string, useTrimbleIntegration?: boolean, useProject44Integration?: boolean, use4kitesIntegration?: boolean, createdAt: any, updatedAt: any, accountTeamMembers: Array<{ __typename?: 'accountTeamMembers', user: { __typename?: 'users', id: any, email: string } }>, businessUnits: Array<{ __typename?: 'IdmBusinessUnit', id: any, name: string }>, createdByUser?: { __typename?: 'users', id: any, email: string }, updatedByUser?: { __typename?: 'users', id: any, email: string } }, adaptCompanyDataByPk?: { __typename?: 'adaptCompanyData', hasAdapt?: boolean, runDate?: any, windowEndDate?: any, windowStartDate?: any } };

export type UpdateCompanyByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
  company: Types.CompaniesSetInput;
  adapt: Types.AdaptCompanyDataInsertInput;
  accountTeamMembers: Array<Types.AccountTeamMembersInsertInput> | Types.AccountTeamMembersInsertInput;
}>;


export type UpdateCompanyByIdMutation = { __typename?: 'mutation_root', updateCompaniesByPk?: { __typename?: 'companies', id: any }, insertAdaptCompanyDataOne?: { __typename?: 'adaptCompanyData', companyId: any }, deleteAccountTeamMembers?: { __typename?: 'accountTeamMembersMutationResponse', affectedRows: number }, insertAccountTeamMembers?: { __typename?: 'accountTeamMembersMutationResponse', affectedRows: number } };

export type DeleteCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type DeleteCompanyMutation = { __typename?: 'mutation_root', deleteCompaniesByPk?: { __typename?: 'companies', id: any } };



export const GetActiveAdminsDocument = `
    query GetActiveAdmins @cached(ttl: 300) {
  users(where: {role: {_eq: "LE_ADMIN"}, active: {_eq: true}}) {
    id
    email
  }
}
    `;

export const useGetActiveAdminsQuery = <
      TData = GetActiveAdminsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetActiveAdminsQueryVariables,
      options?: UseQueryOptions<GetActiveAdminsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetActiveAdminsQuery, TError, TData>(
      variables === undefined ? ['GetActiveAdmins'] : ['GetActiveAdmins', variables],
      fetcher<GetActiveAdminsQuery, GetActiveAdminsQueryVariables>(client, GetActiveAdminsDocument, variables, headers),
      options
    )};

useGetActiveAdminsQuery.document = GetActiveAdminsDocument;

useGetActiveAdminsQuery.getKey = (variables?: GetActiveAdminsQueryVariables) => variables === undefined ? ['GetActiveAdmins'] : ['GetActiveAdmins', variables];


useGetActiveAdminsQuery.fetcher = (client: GraphQLClient, variables?: GetActiveAdminsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetActiveAdminsQuery, GetActiveAdminsQueryVariables>(client, GetActiveAdminsDocument, variables, headers);

export const GetCompanyByIdDocument = `
    query GetCompanyById($id: bigint!) {
  companiesByPk(id: $id) {
    id
    name
    companyType
    phone
    tenderPhoneNumber
    tenderEmailAddress
    registeredName
    registeredState
    transactionFee
    usMcNumber
    usDotNumber
    scacCode
    accountTeamMembers {
      user {
        id
        email
      }
    }
    businessUnits {
      id
      name
    }
    useTrimbleIntegration
    useProject44Integration
    use4kitesIntegration
    createdByUser {
      id
      email
    }
    createdAt
    updatedByUser {
      id
      email
    }
    updatedAt
  }
  adaptCompanyDataByPk(companyId: $id) {
    hasAdapt
    runDate
    windowEndDate
    windowStartDate
  }
}
    `;

export const useGetCompanyByIdQuery = <
      TData = GetCompanyByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCompanyByIdQueryVariables,
      options?: UseQueryOptions<GetCompanyByIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCompanyByIdQuery, TError, TData>(
      ['GetCompanyById', variables],
      fetcher<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>(client, GetCompanyByIdDocument, variables, headers),
      options
    )};

useGetCompanyByIdQuery.document = GetCompanyByIdDocument;

useGetCompanyByIdQuery.getKey = (variables: GetCompanyByIdQueryVariables) => ['GetCompanyById', variables];


useGetCompanyByIdQuery.fetcher = (client: GraphQLClient, variables: GetCompanyByIdQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>(client, GetCompanyByIdDocument, variables, headers);

export const UpdateCompanyByIdDocument = `
    mutation UpdateCompanyById($id: bigint!, $company: companiesSetInput!, $adapt: adaptCompanyDataInsertInput!, $accountTeamMembers: [accountTeamMembersInsertInput!]!) {
  updateCompaniesByPk(pkColumns: {id: $id}, _set: $company) {
    id
  }
  insertAdaptCompanyDataOne(
    object: $adapt
    onConflict: {constraint: adapt_company_data_pkey, updateColumns: [hasAdapt, runDate, windowStartDate, windowEndDate]}
  ) {
    companyId
  }
  deleteAccountTeamMembers(where: {companyId: {_eq: $id}}) {
    affectedRows
  }
  insertAccountTeamMembers(objects: $accountTeamMembers) {
    affectedRows
  }
}
    `;

export const useUpdateCompanyByIdMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateCompanyByIdMutation, TError, UpdateCompanyByIdMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateCompanyByIdMutation, TError, UpdateCompanyByIdMutationVariables, TContext>(
      ['UpdateCompanyById'],
      (variables?: UpdateCompanyByIdMutationVariables) => fetcher<UpdateCompanyByIdMutation, UpdateCompanyByIdMutationVariables>(client, UpdateCompanyByIdDocument, variables, headers)(),
      options
    )};

useUpdateCompanyByIdMutation.getKey = () => ['UpdateCompanyById'];


useUpdateCompanyByIdMutation.fetcher = (client: GraphQLClient, variables: UpdateCompanyByIdMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateCompanyByIdMutation, UpdateCompanyByIdMutationVariables>(client, UpdateCompanyByIdDocument, variables, headers);

export const DeleteCompanyDocument = `
    mutation deleteCompany($id: bigint!) {
  deleteCompaniesByPk(id: $id) {
    id
  }
}
    `;

export const useDeleteCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteCompanyMutation, TError, DeleteCompanyMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteCompanyMutation, TError, DeleteCompanyMutationVariables, TContext>(
      ['deleteCompany'],
      (variables?: DeleteCompanyMutationVariables) => fetcher<DeleteCompanyMutation, DeleteCompanyMutationVariables>(client, DeleteCompanyDocument, variables, headers)(),
      options
    )};

useDeleteCompanyMutation.getKey = () => ['deleteCompany'];


useDeleteCompanyMutation.fetcher = (client: GraphQLClient, variables: DeleteCompanyMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteCompanyMutation, DeleteCompanyMutationVariables>(client, DeleteCompanyDocument, variables, headers);
