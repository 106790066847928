import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { adaptConstellationDetailsQuery } from '@/features/adapt-constellation/adapt-constellation-details.api'

const adaptConstellationDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = adaptConstellationDetailsQuery(params.id!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { adaptConstellationDetailsLoader }
