import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { fuelSurchargeScheduleDetailsQuery } from '@/features/fuel-surcharge-schedule/fuel-surcharge-schedule-details.api'

const fuelSurchargeDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = fuelSurchargeScheduleDetailsQuery(params.id!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { fuelSurchargeDetailsLoader }
