import { useCallback } from 'react'

import { Box } from '@mui/material'
import { type GridValidRowModel } from '@mui/x-data-grid-pro'
import { useShallow } from 'zustand/react/shallow'

import { Tip } from '@leaf/components'
import { EquipmentClasses } from '@leaf/constants'
import { Columns, Table, renderCompanyAvatar } from '@leaf/table'

import { graphqlClient } from '@/api'
import { useGetAdaptLaneSummariesQuery } from '@/features/adapt-lane/adapt-lane-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const AdaptLaneOverview = () => {
  const [changeTable, savedState] = useStore(
    useShallow((state) => [state.changeTable, state.adaptLaneOverview]),
  )

  const { data, isLoading } = useGetAdaptLaneSummariesQuery(
    graphqlClient,
    {},
    { select: ({ analyticsAdaptLaneSummaries }) => analyticsAdaptLaneSummaries },
  )

  // Link to the lane explorer which is used in the action column, so whenever the row.id is
  // referenced in this context, it will fall back to this method.
  const getRowId = useCallback((row: GridValidRowModel) => {
    return `${row.id}/explorer?equipment-class=${row.equipmentClass.toLowerCase()}&batch-date=${row.batchDate}`
  }, [])

  const columns = [
    Columns.Action(),
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 300,
    },
    {
      field: 'shipperId',
      headerName: 'Shipper ID',
    },
    {
      field: 'shipperName',
      headerName: 'Shipper',
      minWidth: 200,
      renderCell: renderCompanyAvatar({
        id: 'shipperId',
        name: 'shipperName',
      }),
    },
    {
      field: 'originName',
      headerName: 'Origin',
      minWidth: 250,
    },
    {
      field: 'destinationName',
      headerName: 'Destination',
      minWidth: 250,
    },
    {
      field: 'equipmentClass',
      headerName: 'Equipment Class',
      type: 'singleSelect',
      valueOptions: [EquipmentClasses.DRY, EquipmentClasses.REEFER],
    },
    {
      field: 'batchDate',
      headerName: 'Batch Date',
      type: 'date',
    },
    {
      field: 'leafMiles',
      headerName: 'Leaf Miles',
      type: 'number',
    },
    {
      field: 'shipperMiles',
      headerName: 'Shipper Miles',
      type: 'number',
    },
    {
      field: 'benefit',
      headerName: 'Benefit',
      type: 'bigMoney',
    },
    {
      field: 'totalShipments',
      headerName: 'Shipments',
      type: 'number',
    },
    {
      field: 'minDate',
      headerName: 'From',
      type: 'date',
    },
    {
      field: 'maxDate',
      headerName: 'To',
      type: 'date',
    },
    {
      field: 'linehaulZeroPeg',
      headerName: 'Linehaul Zero PEG',
      type: 'bigMoney',
    },
    {
      field: 'annualizedLinehaulZeroPeg',
      headerName: 'Linehaul Zero PEG (Annualized)',
      type: 'bigMoney',
    },
    {
      field: 'annualizedShipments',
      headerName: 'Shipments (Annualized)',
      type: 'number',
    },
    {
      field: 'annualizedSpend',
      headerName: 'Spend (Annualized)',
      type: 'bigMoney',
    },
    {
      field: 'observations',
      headerName: 'Observations',
      type: 'list',
    },
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        shipperId: false,
      },
    },
  }

  const exportOptions = { fileName: 'adapt_lanes' }

  useTitles([
    {
      value: (
        <Box>
          Adapt Lanes
          <Tip title='Analytics lanes with at least one lane explorer. A single lane can have multiple lane explorers based on equipment class and batch date' />
        </Box>
      ),
    },
  ])

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      exportOptions={exportOptions}
      getRowId={getRowId}
      initialState={initialState}
      loading={isLoading}
      name='adaptLaneOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={data}
    />
  )
}

export { AdaptLaneOverview }
