import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { type GraphQLErrorType } from '@leaf/api'
import { Spinner } from '@leaf/components'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import { useDeleteFuelSurchargeScheduleMutation } from '@/features/fuel-surcharge-schedule/fuel-surcharge-schedule-details.api.generated'

type FuelSurchargeScheduleDeleteDialogProps = {
  id: string
} & Types.FormDialog
const FuelSurchargeScheduleDeleteDialog = ({
  id,
  onClose,
  onSuccess,
  open,
}: FuelSurchargeScheduleDeleteDialogProps) => {
  const {
    error,
    isError,
    isLoading,
    mutate: deleteFuelSurchargeSchedule,
  } = useDeleteFuelSurchargeScheduleMutation(graphqlClient, {
    meta: { globalError: false },
    onSuccess,
  })
  const errors = (error as GraphQLErrorType)?.response?.errors

  const handleDelete = () => {
    deleteFuelSurchargeSchedule({ id })
  }

  return (
    <Dialog onClose={onClose} open={open}>
      {isLoading && <Spinner overlay />}

      <DialogTitle>Do you really want to delete this FSC?</DialogTitle>

      <DialogContent>
        We will try to delete the FSC, however in some cases this might not be possible since it can
        be referenced by other entities. Please contact us directly if you need help.
      </DialogContent>

      {isError && errors.map((e) => <Alert severity='error'>{e.message}</Alert>)}

      <DialogActions>
        <Button color='secondary' onClick={onClose} variant='contained'>
          Cancel
        </Button>

        <Button color='error' onClick={handleDelete} variant='contained'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { FuelSurchargeScheduleDeleteDialog }
