import { Header, HeaderActions } from '@leaf/components'

import { Breadcrumbs } from '@/navigation/pages/breadcrumbs'
import { useStore } from '@/store'

const LayoutHeader = () => {
  const actions = useStore((state) => state.actions)

  return (
    <Header>
      <Breadcrumbs />

      <HeaderActions>{actions}</HeaderActions>
    </Header>
  )
}

export { LayoutHeader }
