import { useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'

import { DataRow, DownloadButton, Spinner, Styled, Text, UploadButton } from '@leaf/components'
import type * as Types from '@leaf/types'
import { download, numbers, time } from '@leaf/utilities'

import {
  getLanePlanImport,
  postLanePlanImport,
} from '@/features/lane-plan/lane-plan-overview-import.api'
import { useStore } from '@/store'

type LanePlanImportDialogProps = {
  onClose: Types.Noop
  onSuccess: Types.Noop
  open: boolean
}
const LanePlanImportDialog = ({ onClose, onSuccess, open }: LanePlanImportDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [uploadFile, setUploadFile] = useState<File>()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const handleOnClose = () => {
    onClose()
    setUploadFile(undefined)
  }

  const handleUpload = (file: File) => {
    setUploadFile(file)
  }

  const handleDownload = async () => {
    const { content, name } = await getLanePlanImport()
    download.trigger(name || 'lane_plan_template.xlsx', content)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const { content, error, name } = await postLanePlanImport(uploadFile!)
    setIsLoading(false)

    if (error) {
      // TODO: LEAF-4950 - Create error message snackbar hook
      addSnackbar({
        message: error.key || 'An error occurred while importing lane plans.',
        severity: 'error',
      })
    } else {
      onSuccess()
      download.trigger(name || 'lane_plans_import_result.xlsx', content)
      addSnackbar({
        message: 'Import successful. Automatically downloaded the updated lane plans.',
      })
      handleOnClose()
    }
  }

  return (
    <Dialog onClose={handleOnClose} open={open}>
      {isLoading ? <Spinner overlay /> : null}

      <DialogTitle>Import Lane Plans</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text.Body1>Please provide an Excel file.</Text.Body1>

            <Text.Body1 paragraph>
              The required values and examples can be found in the template.
            </Text.Body1>
          </Grid>

          <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }} xs={12}>
            <DownloadButton handleDownload={handleDownload} label='Template' />

            <UploadButton handleUpload={handleUpload} />
          </Grid>

          {uploadFile && (
            <Grid item xs={12}>
              <Styled.Divider />

              <DataRow label='Filename' value={uploadFile.name} />

              <DataRow
                label='Last modification'
                value={time.filetimeToDatetime(uploadFile.lastModified)}
              />

              <DataRow label='Size' value={numbers.convertBytes(uploadFile.size)} />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleOnClose} variant='outlined'>
          Cancel
        </Button>

        <Button color='primary' disabled={!uploadFile} onClick={handleSubmit} variant='contained'>
          Import
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { LanePlanImportDialog }
