import { List } from '@mui/material'

import { DataRow, DataRowSection } from '@leaf/components'

import type { MatchedContractType, PricingType } from '@/features/lane-plan/lane-plan.types'

type ColorsType = keyof typeof contractTypes

type MarketRatesType = {
  [key in ColorsType]: number
}

const contractTypes = {
  blue: ['UNCOMMITTED'],
  green: ['DEDICATED', 'FLEET', 'DAY_OF', 'WEEK_OF'],
  yellow: ['SPOT'],
}

const calculateHistoricalShipperRpm = (
  avgHistoricalLinehaulZeroPegRpm?: number,
  pegAdjustmentRpm?: number,
) => {
  if (avgHistoricalLinehaulZeroPegRpm && pegAdjustmentRpm) {
    const historicalShipperRpm = avgHistoricalLinehaulZeroPegRpm + pegAdjustmentRpm
    return historicalShipperRpm.toFixed(2)
  }
}

const calculateMarketRates = (contracts?: MatchedContractType[]) => {
  const marketRates = {
    blue: 0,
    green: 0,
    yellow: 0,
  }
  contracts?.forEach((contract) => {
    Object.keys(contractTypes).forEach((color) => {
      const c = color as ColorsType
      if (contractTypes[c].includes(contract.contract_type)) {
        if (contract.fuel_adjusted_price_rpm && contract.fuel_adjusted_price_rpm > marketRates[c]) {
          marketRates[c] = contract.fuel_adjusted_price_rpm
        }
      }
    })
  })
  return marketRates
}

const calculateDisplayRates = (marketRates: MarketRatesType) => {
  const displayRates = {
    blue: '-',
    green: '-',
    yellow: '-',
  }
  Object.keys(marketRates).forEach((color) => {
    const c = color as ColorsType
    const value = marketRates[c]
    if (value) {
      displayRates[c] = value.toFixed(2)
    }
  })
  return displayRates
}

type LanePlanPricingProps = {
  data?: PricingType
  plannedShipperRpm?: string
}
const LanePlanPricing = ({ data, plannedShipperRpm }: LanePlanPricingProps) => {
  const marketRates = calculateMarketRates(data?.matchedContracts)
  const displayRates = calculateDisplayRates(marketRates)

  return (
    <List>
      <DataRow label='Planned RPM' value={plannedShipperRpm} />

      <DataRow label='Suggested RPM' value={data?.fuelAdjustedPriceRpm?.toFixed(2)} />

      <DataRow
        label='Historical Shipper RPM'
        value={calculateHistoricalShipperRpm(
          data?.avgHistoricalLinehaulZeroPegRpm,
          data?.pegAdjustmentRpm,
        )}
      />

      <DataRowSection label='Market Rates' />

      <DataRow label='Yellow' value={displayRates.yellow} />

      <DataRow label='Blue' value={displayRates.blue} />

      <DataRow label='Green' value={displayRates.green} />
    </List>
  )
}

export { LanePlanPricing }
