import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { contractDetailsQuery } from '@/features/contract/contract-details.api'

const contractDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = contractDetailsQuery(params.contractId!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { contractDetailsLoader }
