import { Card, CardContent } from '@mui/material'

import { CardHeader, DataRow, DataRowLink, DataRowList } from '@leaf/components'
import { time } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import type { AnalyticsLanes, AnalyticsRawLanes } from '@/api/hasura.types.generated'
import type { LanePlanType, PricingType } from '@/features/lane-plan/lane-plan.types'
import {
  useGetLanePlanOpportunitiesQuery,
  useGetLaneQuery,
  useGetRawLaneQuery,
} from '@/features/lane-plan/lane-plan-details-lane.api.generated'

type LaneType = AnalyticsLanes | AnalyticsRawLanes

const getLaneSource = (lane: LaneType) => {
  if (!lane) {
    return
  }
  let laneSource = ''
  if (lane.recordType) {
    laneSource += lane.recordType

    if ('datasetLabel' in lane) {
      laneSource += ` (${lane.datasetLabel})`
    }
  }
  return laneSource
}

const getLaneOriginName = (lane: LaneType) => {
  if (!lane) {
    return
  }
  if ('originLocation' in lane) {
    return lane.originLocation?.name
  }
  let origin = ''
  if ('oCity' in lane) {
    origin += lane.oCity

    if ('oState' in lane && 'oZip' in lane) {
      origin += `, ${lane.oState} ${lane.oZip}`
    }
  }
  return origin
}

const getLaneDestinationName = (lane: LaneType) => {
  if (!lane) {
    return
  }
  if ('destinationLocation' in lane) {
    return lane.destinationLocation?.name
  }
  let destination = ''
  if ('dCity' in lane) {
    destination += lane.dCity

    if ('dState' in lane && 'dZip' in lane) {
      destination += `, ${lane.dState} ${lane.dZip}`
    }
  }
  return destination
}

type LanePlanLaneProps = {
  plan: LanePlanType
  pricing?: PricingType
}
const LanePlanLane = ({ plan, pricing }: LanePlanLaneProps) => {
  const { data: rawLaneData } = useGetRawLaneQuery(graphqlClient, {
    powerLaneId: plan.adaptLaneId,
  })
  const { data: laneData } = useGetLaneQuery(graphqlClient, { laneId: plan.adaptLaneId })

  const { data: opportunitiesData } = useGetLanePlanOpportunitiesQuery(graphqlClient, {
    lanePlanId: plan.id,
  })

  const lane =
    (rawLaneData?.analyticsRawLanes[0] as AnalyticsRawLanes) ||
    (laneData?.analyticsLanesByPk as AnalyticsLanes)
  const laneSource = getLaneSource(lane)
  const laneOriginName = getLaneOriginName(lane)
  const laneDestinationName = getLaneDestinationName(lane)

  const opportunities =
    opportunitiesData?.lanePlanOpportunities.map((opportunity) => {
      return opportunity.opportunityType as string
    }) || []

  return (
    <>
      <Card>
        <CardHeader title='General' />

        <CardContent>
          <DataRowLink
            label='Shipper'
            name={plan.shipper.name}
            to={`/companies/${plan.shipper.id}`}
          />

          <DataRow label='Adapt Lane' value={plan.adaptLaneId} />

          <DataRow label='Lane Source' value={laneSource} />

          <DataRow label='Batch Date' value={time.toLocalDate(lane?.batchDate)} />

          <DataRow label='Equipment Class' value={lane?.equipmentClass} />

          <DataRow label='Annualized Loads' value={lane?.annualizedLoads} />

          <DataRowList label='Opportunity Types' value={opportunities} />

          <DataRow label='Shipper Distance' value={lane?.companyMiles} />

          <DataRow label='Shipper Transit Time' value={lane?.companyTransitTime} />

          <DataRow label='PCM Distance' value={lane?.pcmMiles?.toFixed(0)} />

          <DataRow label='PCM Transit Time' value={lane?.pcmHours?.toFixed(0)} />
        </CardContent>
      </Card>

      <Card sx={{ mt: '16px' }}>
        <CardHeader title='Location' />

        <CardContent>
          <DataRow label='Origin' value={laneOriginName} />

          <DataRow label='Origin Cluster' value={lane?.oClusterName} />

          <DataRow label='Destination' value={laneDestinationName} />

          <DataRow label='Destination Cluster' value={lane?.dClusterName} />
        </CardContent>
      </Card>

      <Card sx={{ mt: '16px' }}>
        <CardHeader title='Fuel' />

        <CardContent>
          <DataRowLink
            label='Fuel Surcharge Schedule'
            name={plan.fuelSurchargeSchedule?.name}
            to={`/fuel-surcharge-schedules?id=${plan.fuelSurchargeSchedule?.id}`}
          />

          <DataRow label='Current Fuel Price' value={pricing?.fuelPpg?.toFixed(2)} />

          <DataRow label='Current Fuel RPM' value={pricing?.shipperFuelPpm?.toFixed(2)} />
        </CardContent>
      </Card>
    </>
  )
}
export { LanePlanLane }
