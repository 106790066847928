import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { type GraphQLErrorType } from '@leaf/api'
import { Spinner } from '@leaf/components'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import { useDeleteEquipmentTypeMutation } from '@/features/equipment-type/equipment-type-details.api.generated'

type EquipmentTypeDeleteDialogProps = {
  id: string
} & Types.FormDialog
const EquipmentTypeDeleteDialog = ({
  id,
  onClose,
  onSuccess,
  open,
}: EquipmentTypeDeleteDialogProps) => {
  const {
    error,
    isError,
    isLoading,
    mutate: deleteEquipmentType,
  } = useDeleteEquipmentTypeMutation(graphqlClient, {
    meta: { globalError: false },
    onSuccess,
  })
  const errors = (error as GraphQLErrorType)?.response?.errors

  const handleDelete = () => {
    deleteEquipmentType({ id })
  }

  return (
    <Dialog onClose={onClose} open={open}>
      {isLoading && <Spinner overlay />}

      <DialogTitle>Do you really want to delete this equipment type?</DialogTitle>

      <DialogContent>
        We will try to delete the equipment type, however in some cases this might not be possible
        since it can be referenced by other entities. Please contact us directly if you need help.
      </DialogContent>

      {isError && errors.map((e) => <Alert severity='error'>{e.message}</Alert>)}

      <DialogActions>
        <Button color='secondary' onClick={onClose} variant='contained'>
          Cancel
        </Button>

        <Button color='error' onClick={handleDelete} variant='contained'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { EquipmentTypeDeleteDialog }
