import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { type GraphQLErrorType } from '@leaf/api'
import { Spinner } from '@leaf/components'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import { useDeleteCompanyMutation } from '@/features/company/company-details.api.generated'

type CompanyDeleteDialogProps = {
  id: string
} & Types.FormDialog
const CompanyDeleteDialog = ({ id, onClose, onSuccess, open }: CompanyDeleteDialogProps) => {
  const {
    error,
    isError,
    isLoading,
    mutate: deleteCompany,
  } = useDeleteCompanyMutation(graphqlClient, {
    meta: { globalError: false },
    onSuccess,
  })
  const errors = (error as GraphQLErrorType)?.response?.errors

  const handleDelete = () => {
    deleteCompany({ id })
  }

  return (
    <Dialog onClose={onClose} open={open}>
      {isLoading && <Spinner overlay />}

      <DialogTitle>Do you really want to delete this company?</DialogTitle>

      <DialogContent>
        We will try to delete the company, however in some cases this might not be possible since it
        can be referenced by other entities. Please contact us directly if you need help.
      </DialogContent>

      {isError && errors.map((e) => <Alert severity='error'>{e.message}</Alert>)}

      <DialogActions>
        <Button color='secondary' onClick={onClose} variant='contained'>
          Cancel
        </Button>

        <Button color='error' onClick={handleDelete} variant='contained'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { CompanyDeleteDialog }
