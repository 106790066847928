// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetLanePlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLanePlansQuery = { __typename?: 'query_root', lanePlans: Array<{ __typename?: 'lanePlans', id: any, status?: string, deprioritizationReason?: string, adaptLaneId?: any, estCarrierRate?: any, plannedShipperRpm?: any, plannedShipperRpmLastUpdatedAt?: any, plannedStartDate?: any, plannedWeeklyVolume?: number, targetPercBlue?: any, targetPercDedicated?: any, targetPercGreen?: any, targetPercYellow?: any, pickupType?: string, deliveryType?: string, createdAt: any, updatedAt?: any, equipmentClass?: string, shipper: { __typename?: 'companies', id: any, name: string }, route: { __typename?: 'routingRoutes', id: any, legs: Array<{ __typename?: 'routingLegs', sequence: any, lane: { __typename?: 'routingLanes', id: any, destinationName?: string, originName?: string } }> }, fuelSurchargeSchedule?: { __typename?: 'fuelSurchargeSchedules', id: any, name: string }, createdByUser?: { __typename?: 'users', id: any, email: string }, updatedByUser?: { __typename?: 'users', id: any, email: string } }> };



export const GetLanePlansDocument = `
    query GetLanePlans @cached(ttl: 300) {
  lanePlans {
    id
    shipper {
      id
      name
    }
    route {
      id
      legs {
        sequence
        lane {
          id
          destinationName
          originName
        }
      }
    }
    status
    deprioritizationReason
    fuelSurchargeSchedule {
      id
      name
    }
    adaptLaneId
    estCarrierRate
    plannedShipperRpm
    plannedShipperRpmLastUpdatedAt
    plannedStartDate
    plannedWeeklyVolume
    targetPercBlue
    targetPercDedicated
    targetPercGreen
    targetPercYellow
    pickupType
    deliveryType
    createdAt
    createdByUser {
      id
      email
    }
    updatedAt
    updatedByUser {
      id
      email
    }
    equipmentClass
  }
}
    `;

export const useGetLanePlansQuery = <
      TData = GetLanePlansQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetLanePlansQueryVariables,
      options?: UseQueryOptions<GetLanePlansQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLanePlansQuery, TError, TData>(
      variables === undefined ? ['GetLanePlans'] : ['GetLanePlans', variables],
      fetcher<GetLanePlansQuery, GetLanePlansQueryVariables>(client, GetLanePlansDocument, variables, headers),
      options
    )};

useGetLanePlansQuery.document = GetLanePlansDocument;

useGetLanePlansQuery.getKey = (variables?: GetLanePlansQueryVariables) => variables === undefined ? ['GetLanePlans'] : ['GetLanePlans', variables];


useGetLanePlansQuery.fetcher = (client: GraphQLClient, variables?: GetLanePlansQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLanePlansQuery, GetLanePlansQueryVariables>(client, GetLanePlansDocument, variables, headers);
