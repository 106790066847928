// @ts-nocheck
/* eslint-disable */
import type * as Types from './hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetAccessorialsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAccessorialsQuery = { __typename?: 'query_root', accessorials: Array<{ __typename?: 'accessorials', id: any, name: string }> };



export const GetAccessorialsDocument = `
    query GetAccessorials @cached(ttl: 300) {
  accessorials(orderBy: {name: ASC}) {
    id
    name
  }
}
    `;

export const useGetAccessorialsQuery = <
      TData = GetAccessorialsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetAccessorialsQueryVariables,
      options?: UseQueryOptions<GetAccessorialsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAccessorialsQuery, TError, TData>(
      variables === undefined ? ['GetAccessorials'] : ['GetAccessorials', variables],
      fetcher<GetAccessorialsQuery, GetAccessorialsQueryVariables>(client, GetAccessorialsDocument, variables, headers),
      options
    )};

useGetAccessorialsQuery.document = GetAccessorialsDocument;

useGetAccessorialsQuery.getKey = (variables?: GetAccessorialsQueryVariables) => variables === undefined ? ['GetAccessorials'] : ['GetAccessorials', variables];


useGetAccessorialsQuery.fetcher = (client: GraphQLClient, variables?: GetAccessorialsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAccessorialsQuery, GetAccessorialsQueryVariables>(client, GetAccessorialsDocument, variables, headers);
