import { useQuery } from '@tanstack/react-query'

import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient, restClient } from '@/api'
import type { PricingType } from '@/features/lane-plan/lane-plan.types'
import {
  GetLanePlanDocument,
  type GetLanePlanQuery,
} from '@/features/lane-plan/lane-plan-details.api.generated'

type LanePlanType = GetLanePlanQuery['lanePlansByPk']
type LanePlanQueryReturnType = Types.NoUndefined<
  {
    estCarrierRate: number
    plannedShipperRpm: number
    targetPercBlue: number
    targetPercDedicated: number
    targetPercGreen: number
    targetPercYellow: number
  } & LanePlanType
>

const useGetPricingQuery = (args: { [key: string]: string }) => {
  const params = new URLSearchParams(args)
  params.append('contract_type', 'FLEET')
  params.append('contract_type', 'SPOT')
  params.append('contract_type', 'UNCOMMITTED')
  params.append('contract_type', 'DEDICATED')
  params.append('contract_type', 'DAY_OF')
  params.append('contract_type', 'WEEK_OF')
  return useQuery({
    queryFn: () =>
      restClient
        .get('/pricing/lane', {
          params,
        })
        .then((res) => res.data as PricingType),
    queryKey: ['pricing'],
  })
}

const transform = (lanePlan: LanePlanType) => ({
  ...lanePlan,
  estCarrierRate: Number(lanePlan?.estCarrierRate?.toFixed(2) || 0),
  plannedShipperRpm: Number(lanePlan?.plannedShipperRpm?.toFixed(2) || 0),
  targetPercBlue: Number(lanePlan?.targetPercBlue?.toFixed(2) || 0),
  targetPercDedicated: Number(lanePlan?.targetPercDedicated?.toFixed(2) || 0),
  targetPercGreen: Number(lanePlan?.targetPercGreen?.toFixed(2) || 0),
  targetPercYellow: Number(lanePlan?.targetPercYellow?.toFixed(2) || 0),
})

const lanePlanDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const lanePlanDetailsResponse = await graphqlClient.request(GetLanePlanDocument, { id })
    const data = clean(lanePlanDetailsResponse) as GetLanePlanQuery
    const lanePlan = (data.lanePlansByPk || {}) as LanePlanType
    return transform(lanePlan) as LanePlanQueryReturnType
  },
  queryKey: [`lane-plan-details-${id}`],
})

export { lanePlanDetailsQuery, useGetPricingQuery }
export type { LanePlanQueryReturnType }
