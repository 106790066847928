import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Alert, Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import {
  AutocompleteElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
  useWatch,
} from 'react-hook-form-mui'
import * as z from 'zod'

import { type RestErrorType, errorToString } from '@leaf/api'
import { Spinner } from '@leaf/components'
import { Apps, CompanyTypes, Roles, RolesSelect } from '@leaf/constants'
import { clean, validation as v } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient, restClient } from '@/api'
import { useGetCompaniesQuery } from '@/api/companies.api.generated'

const schema1 = z
  .object({
    company: v.__select,
    role: v._string,
  })
  .superRefine((values, ctx) => {
    if (values.role === Roles.USER && !values.company) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required',
        path: ['company'],
      })
    }
  })
const schema2 = z.object({
  businessUnit: v.__select,
  defaultApp: v.__string,
  email: v._email,
  firstName: v._string,
  lastName: v._string,
  phoneNumber: v.__string,
})
const schema = z.intersection(schema1, schema2)
type Schema = z.infer<typeof schema>

const toPayload = (values: Schema) => ({
  company_id: values.company?.id,
  default_app: values.defaultApp,
  email: values.email,
  first_name: values.firstName,
  last_name: values.lastName,
  phone_number: values.phoneNumber,
  role: values.role,
})
type Payload = ReturnType<typeof toPayload>

const UserCreateDialog = ({ onClose, onSuccess, open }: Types.FormDialog) => {
  const { data: companies } = useGetCompaniesQuery(
    graphqlClient,
    {},
    {
      select: (data) =>
        data.companies.map((company) => ({
          id: company.id,
          label: company.name,
          type: company.companyType,
        })),
    },
  )
  const {
    error,
    isError,
    isLoading,
    mutate: insert,
  } = useMutation({
    meta: { globalError: false },
    mutationFn: (payload: Payload) => restClient.post('users', payload),
    onSuccess,
  })

  const context = useForm<Schema>({
    defaultValues: {
      role: Roles.USER,
    },
    mode: 'all',
    resolver: zr(schema),
  })
  const role = useWatch({ control: context.control, name: 'role' })
  const company = useWatch({ control: context.control, name: 'company' }) as { type: string }

  const handleSuccess = (values: Schema) => {
    const payload = toPayload(values)
    insert(clean(payload))
  }

  const onRoleChange = (newRole: string) => {
    if (newRole === Roles.LE_ADMIN) {
      context.setValue('defaultApp', null)
      context.setValue('company', null)
    }
    context.trigger('company')
  }

  const onCompanyChange = (_: unknown, value: any) => {
    context.trigger('company')
    if (!value) {
      context.setValue('defaultApp', null)
    }
    if (value.type === CompanyTypes.SHIPPER) {
      context.setValue('defaultApp', Apps.SHIPPER)
    }
    if (value.type === CompanyTypes.CARRIER) {
      context.setValue('defaultApp', Apps.CARRIER)
    }
  }

  const appsSelect = []
  if (company?.type === CompanyTypes.SHIPPER) {
    appsSelect.push({ id: Apps.SHIPPER, label: Apps.SHIPPER })
    appsSelect.push({ id: Apps.TENDER, label: Apps.TENDER })
  } else if (company?.type === CompanyTypes.CARRIER) {
    appsSelect.push({ id: Apps.CARRIER, label: Apps.CARRIER })
  }

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      {isLoading && <Spinner overlay />}

      {isError && <Alert severity='error'>{errorToString(error as RestErrorType)}</Alert>}

      <FormContainer formContext={context} onSuccess={handleSuccess}>
        <DialogContent>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                label='Firstname'
                name='firstName'
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                label='Lastname'
                name='lastName'
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement fullWidth label='Email' name='email' required variant='standard' />
            </Grid>

            <Grid item xs={6}>
              <TextFieldElement fullWidth label='Phone' name='phoneNumber' variant='standard' />
            </Grid>

            <Grid item xs={6}>
              <SelectElement
                fullWidth
                label='Role'
                name='role'
                onChange={onRoleChange}
                options={RolesSelect}
                required
                variant='standard'
              />
            </Grid>

            <Grid item xs={6}>
              <AutocompleteElement
                autocompleteProps={{
                  disabled: role === Roles.LE_ADMIN,
                  onChange: onCompanyChange,
                }}
                label='Company'
                name='company'
                options={companies || []}
                required={role === Roles.USER}
                textFieldProps={{
                  fullWidth: true,
                  variant: 'standard',
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <AutocompleteElement
                autocompleteProps={{
                  disabled: true,
                }}
                label='Business Unit'
                name='businessUnit'
                options={[]}
                textFieldProps={{
                  fullWidth: true,
                  variant: 'standard',
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <SelectElement
                disabled={!company}
                fullWidth
                label='Default App'
                name='defaultApp'
                options={appsSelect}
                required={role === Roles.USER}
                variant='standard'
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' onClick={onClose} variant='contained'>
            Cancel
          </Button>

          <Button color='primary' type='submit' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  )
}

export { UserCreateDialog }
