import { Box, Tooltip } from '@mui/material'
import { useShallow } from 'zustand/react/shallow'

import { Columns, Table } from '@leaf/table'

import { graphqlClient } from '@/api'
import { useGetAdaptConstellationsOverviewQuery } from '@/features/adapt-constellation/adapt-constellation-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const renderTooltipWrap = ({ value }: { value: string[] }) => {
  if (!value?.length) {
    return null
  }

  let s = value[0]
  if (value.length > 1) {
    s = `${value.join(', ').substring(0, 10)} …`
  }
  return (
    <Tooltip title={<Box sx={{ whiteSpace: 'pre-line' }}>{value.join('\n')}</Box>}>
      <span>{s}</span>
    </Tooltip>
  )
}

const AdaptConstellationOverview = () => {
  const [changeTable, savedState] = useStore(
    useShallow((state) => [state.changeTable, state.adaptConstellationOverview]),
  )

  useTitles([{ value: 'Adapt Constellations' }])

  const { data, isLoading } = useGetAdaptConstellationsOverviewQuery(
    graphqlClient,
    {},
    {
      select: (response) =>
        response.constellations.map((constellation) => {
          return {
            ...constellation,
            destinations: constellation.constellationLanes.map(
              (constellationLane) => constellationLane.lane.destinationLocation?.name,
            ),
            equipmentClasses: [
              ...new Set(
                constellation.constellationLanes.map(
                  (constellationLane) => constellationLane.equipmentClass,
                ),
              ),
            ],
            origins: constellation.constellationLanes.map(
              (constellationLane) => constellationLane.lane.originLocation?.name,
            ),
            shippers: [
              ...new Set(
                constellation.constellationLanes.map(
                  (constellationLane) => constellationLane.lane.company.name,
                ),
              ),
            ],
          }
        }),
    },
  )

  const columns = [
    Columns.Action(),
    {
      field: 'id',
      headerName: 'ID',
    },
    { field: 'shippers', headerName: 'Shippers', renderCell: renderTooltipWrap },
    {
      field: 'name',
      headerName: 'Name',
    },
    { field: 'constellationLanes.length', headerName: '# Lanes' },
    { field: 'shippers.length', headerName: '# Shippers' },
    {
      field: 'constellationNetworkMoves.length',
      headerName: '# Network Moves',
    },
    {
      field: 'origins',
      headerName: 'Origins',
      renderCell: renderTooltipWrap,
    },
    { field: 'destinations', headerName: 'Destinations', renderCell: renderTooltipWrap },
    { field: 'equipmentClasses', headerName: 'Equipment Types', type: 'list' },
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        'shippers.length': false,
      },
    },
  }

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      initialState={initialState}
      loading={isLoading}
      name='adaptConstellationOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={data || []}
    />
  )
}

export { AdaptConstellationOverview }
