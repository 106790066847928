import { type ReactNode, useEffect } from 'react'

import { useStore } from '@/store'

/**
 * @param actions If not provided, the actions are reset
 */
const useActions = (actions: ReactNode[]) => {
  const { updateActions } = useStore.getState()

  useEffect(() => {
    if (actions) {
      updateActions(actions)
    } else {
      updateActions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions])
}

export { useActions }
