// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetContractMatchesQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetContractMatchesQuery = { __typename?: 'query_root', routingGuides: Array<{ __typename?: 'routingGuides', buyContractId: any, sellContractId: any, isEnabled: boolean, isIncumbent: boolean, endDate?: any, startDate?: any, assignedWeeklyVolume?: number, sellContract: { __typename?: 'contracts', contractType?: string, isFleet: boolean, seller?: { __typename?: 'companies', name: string }, contractRoutes: Array<{ __typename?: 'contractRoutes', route: { __typename?: 'routingRoutes', legs: Array<{ __typename?: 'routingLegs', lane: { __typename?: 'routingLanes', id: any, destinationName?: string, originName?: string } }> } }> }, contractMatchVolumes?: { __typename?: 'contractMatchVolumes', shipperMaxUnallocatedVolumeUntilEnd?: any, weeklyShipperMaxUnallocatedVolumeUntilEnd?: any, carrierMaxUnallocatedVolumeUntilEnd?: any, weeklyCarrierMaxUnallocatedVolumeUntilEnd?: any, allocatedVolume?: number, weeklyAllocatedVolume?: number, areaOverlap?: boolean, startDate?: any, endDate?: any, remainingWeeks?: number } }> };



export const GetContractMatchesDocument = `
    query GetContractMatches($id: uuid!) {
  routingGuides(
    orderBy: {isEnabled: DESC}
    where: {_and: [{buyContractId: {_eq: $id}}, {sellContract: {sellerId: {_neq: "1520"}}}, {sellContract: {sellerId: {_neq: "49310"}}}]}
  ) {
    buyContractId
    sellContractId
    isEnabled
    isIncumbent
    endDate
    startDate
    assignedWeeklyVolume
    sellContract {
      contractType
      isFleet
      seller {
        name
      }
      contractRoutes(where: {isPrimary: {_eq: true}}) {
        route {
          legs(orderBy: {sequence: ASC}) {
            lane {
              id
              destinationName
              originName
            }
          }
        }
      }
    }
    contractMatchVolumes {
      shipperMaxUnallocatedVolumeUntilEnd
      weeklyShipperMaxUnallocatedVolumeUntilEnd
      carrierMaxUnallocatedVolumeUntilEnd
      weeklyCarrierMaxUnallocatedVolumeUntilEnd
      allocatedVolume
      weeklyAllocatedVolume
      areaOverlap
      startDate
      endDate
      remainingWeeks
    }
  }
}
    `;

export const useGetContractMatchesQuery = <
      TData = GetContractMatchesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetContractMatchesQueryVariables,
      options?: UseQueryOptions<GetContractMatchesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetContractMatchesQuery, TError, TData>(
      ['GetContractMatches', variables],
      fetcher<GetContractMatchesQuery, GetContractMatchesQueryVariables>(client, GetContractMatchesDocument, variables, headers),
      options
    )};

useGetContractMatchesQuery.document = GetContractMatchesDocument;

useGetContractMatchesQuery.getKey = (variables: GetContractMatchesQueryVariables) => ['GetContractMatches', variables];


useGetContractMatchesQuery.fetcher = (client: GraphQLClient, variables: GetContractMatchesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetContractMatchesQuery, GetContractMatchesQueryVariables>(client, GetContractMatchesDocument, variables, headers);
