import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { equipmentTypeDetailsQuery } from '@/features/equipment-type/equipment-type-details.api'

const equipmentTypeDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = equipmentTypeDetailsQuery(params.id!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { equipmentTypeDetailsLoader }
