import type * as Types from '@leaf/types'

import type { GetExecutionLaneDetailsQuery } from '@/features/execution-lane/execution-lane-details.api.generated'
import type { GetExecutionLaneOverviewQuery } from '@/features/execution-lane/execution-lane-overview.api.generated'

type ExecutionLaneType =
  | GetExecutionLaneDetailsQuery['executionLanesByPk']
  | GetExecutionLaneOverviewQuery['executionLanes'][0]

type ExecutionLaneMappedType = {
  geo?: GeoJSON.Feature | null
} & ReturnType<typeof mapExecutionLane>

const mapExecutionLane = (executionLane: Types.NoUndefined<ExecutionLaneType>) => {
  return {
    ...executionLane,
    co2Reduction: executionLane.co2ReductionPerShipment
      ? executionLane.co2ReductionPerShipment.toFixed()
      : null,
    destinationName: [
      `${executionLane.destination.city}, ${executionLane.destination.state} ${executionLane.destination.zip}`,
      `${executionLane.destination.street}`,
    ],
    drivingTime: executionLane.drivingTimeMinutes
      ? (executionLane.drivingTimeMinutes / 60).toFixed()
      : null,
    originName: [
      `${executionLane.origin.city}, ${executionLane.origin.state} ${executionLane.origin.zip}`,
      `${executionLane.origin.street}`,
    ],
  }
}

export { mapExecutionLane }
export type { ExecutionLaneMappedType }
