import { useParams } from 'react-router-dom'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import {
  Person as ActivateIcon,
  PersonOff as DeactivateIcon,
  Send as InviteIcon,
} from '@mui/icons-material'
import { Alert, Button, Card, CardContent, Grid } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  AutocompleteElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
  useWatch,
} from 'react-hook-form-mui'
import * as z from 'zod'

import { CardHeader, DataRow, Page, Progress, SubMenu } from '@leaf/components'
import { Apps, AppsSelect, Roles, RolesSelect } from '@leaf/constants'
import { clean, select, validation as v } from '@leaf/form'
import { useCopyToClipboard } from '@leaf/hooks'
import { time } from '@leaf/utilities'

import { graphqlClient, restClient } from '@/api'
import { useGetCompaniesQuery } from '@/api/companies.api.generated'
import { invite, toggleStatus } from '@/features/user/user.api'
import { type UserQueryReturnType, userDetailsQuery } from '@/features/user/user-details.api'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const schema = z
  .object({
    businessUnit: v.__select,
    company: v.__select,
    defaultApp: v.__string,
    email: v._email,
    firstName: v._string,
    lastName: v._string,
    phoneNumber: v.__string,
    role: v._string,
  })
  .superRefine((values, ctx) => {
    if (values.role === Roles.USER && !values.company) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required',
        path: ['company'],
      })
    }
  })
type Schema = z.infer<typeof schema>

const toPayload = (values: Schema, id: string) => ({
  company_id: values.company?.id,
  default_app: values.defaultApp,
  email: values.email,
  first_name: values.firstName,
  id,
  last_name: values.lastName,
  phone_number: values.phoneNumber,
  role: values.role,
})
type Payload = ReturnType<typeof toPayload>

const UserDetails = () => {
  const addSnackbar = useStore((state) => state.addSnackbar)

  const { id } = useParams()
  const { data, refetch } = useQuery(userDetailsQuery(id!))
  const user = data as UserQueryReturnType

  const { data: companies } = useGetCompaniesQuery(
    graphqlClient,
    {},
    {
      select: (response) =>
        response.companies.map((company) => ({ id: company.id, label: company.name })),
    },
  )
  const { mutate: update } = useMutation((payload: Payload) =>
    restClient
      .put(`/users/${payload.id}`, clean(payload))
      .then(() => addSnackbar({ message: 'Update successful' })),
  )

  const { toClipboard } = useCopyToClipboard()

  const context = useForm<Schema>({
    defaultValues: {
      ...user,
      businessUnit: select.fromResponse(user.businessUnit),
      company: select.fromResponse(user.company),
    },
    mode: 'all',
    resolver: zr(schema),
  })
  const role = useWatch({ control: context.control, name: 'role' })

  const onSubmit = (values: Schema) => {
    const payload = toPayload(values, user.id)
    update(payload)
  }
  const handleInvite = () =>
    invite(user.id).then(({ data: inviteData }) => {
      toClipboard(inviteData.url)
      addSnackbar({
        autoHideDuration: null,
        message: [
          `Invitation re-sent to ${user.email}`,
          `Copied activation link to your clipboard`,
          `${inviteData.url}`,
        ].join('\n'),
      })
    })
  const handleDeactivate = () =>
    toggleStatus(user.id).then(({ data: deactivationData }) => {
      refetch()
      addSnackbar({ message: `User ${deactivationData.email} deactivated` })
    })
  const handleActivate = () =>
    toggleStatus(user.id).then(({ data: activationData }) => {
      refetch()
      addSnackbar({ message: `User ${activationData.email} activated` })
    })

  useTitles([{ to: 'users', value: 'Users' }, { value: user.id }])
  useActions([
    <Button
      color='primary'
      form='user-details-form'
      key='saveUser'
      sx={{ marginRight: '1em' }}
      type='submit'
      variant='outlined'
    >
      Save
    </Button>,
    <SubMenu
      color='primary'
      items={[
        { icon: InviteIcon, onClick: handleInvite, value: 'Send invite' },
        {
          icon: user.active ? DeactivateIcon : ActivateIcon,
          onClick: user.active ? handleDeactivate : handleActivate,
          value: user.active ? 'Deactivate' : 'Activate',
        },
      ]}
      key='userActions'
    />,
  ])

  return (
    <FormContainer
      FormProps={{ id: 'user-details-form' }}
      formContext={context}
      onSuccess={onSubmit}
    >
      <Progress />

      <Page>
        {!user.active && (
          <Grid item xs={12}>
            <Alert severity='error'>User is inactive</Alert>
          </Grid>
        )}

        <Grid item xs={4}>
          <Card>
            <CardHeader title='User' />

            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldElement
                    fullWidth
                    label='Firstname'
                    name='firstName'
                    required
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    fullWidth
                    label='Lastname'
                    name='lastName'
                    required
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    fullWidth
                    label='Email'
                    name='email'
                    required
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement fullWidth label='Phone' name='phoneNumber' variant='standard' />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardHeader title='Permissions' />

            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SelectElement
                    fullWidth
                    label='Role'
                    name='role'
                    onChange={(newRole) => {
                      if (newRole === Roles.USER) {
                        context.setValue('defaultApp', Apps.SHIPPER)
                      }
                      if (newRole === Roles.LE_ADMIN) {
                        context.setValue('defaultApp', null)
                        context.setValue('company', null)
                      }
                      context.trigger('company')
                    }}
                    options={RolesSelect}
                    required
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteElement
                    autocompleteProps={{
                      disabled: role === Roles.LE_ADMIN,
                      onChange: () => {
                        context.trigger('company')
                      },
                    }}
                    label='Company'
                    name='company'
                    options={companies || []}
                    required={role === Roles.USER}
                    textFieldProps={{
                      fullWidth: true,
                      variant: 'standard',
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteElement
                    autocompleteProps={{
                      disabled: true,
                    }}
                    label='Business Unit'
                    name='businessUnit'
                    options={[]}
                    textFieldProps={{
                      fullWidth: true,
                      variant: 'standard',
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectElement
                    disabled={role === Roles.LE_ADMIN}
                    fullWidth
                    label='Default App'
                    name='defaultApp'
                    options={AppsSelect}
                    required={role === Roles.USER}
                    variant='standard'
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardHeader title='Meta' />

            <CardContent>
              <DataRow label='Active' value={user.active.toString()} />

              <DataRow label='Logins' value={user.loginCount} />

              <DataRow label='Last Login' value={time.toLocalDatetime(user.lastLoginAt)} />

              <DataRow label='Created At' value={time.toLocalDatetime(user.createdAt)} />

              <DataRow label='Updated At' value={time.toLocalDatetime(user.updatedAt)} />
            </CardContent>
          </Card>
        </Grid>
      </Page>
    </FormContainer>
  )
}

export { UserDetails }
