// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetLanePlanQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetLanePlanQuery = { __typename?: 'query_root', lanePlansByPk?: { __typename?: 'lanePlans', id: any, status?: string, deprioritizationReason?: string, volumeVolatility: boolean, carrierChurn: boolean, spendVolatility: boolean, marketPrice: boolean, equipmentClass?: string, adaptLaneId?: any, estCarrierRate?: any, plannedShipperRpm?: any, plannedShipperRpmLastUpdatedAt?: any, plannedStartDate?: any, plannedWeeklyVolume?: number, targetPercBlue?: any, targetPercDedicated?: any, targetPercGreen?: any, targetPercYellow?: any, pickupType?: string, deliveryType?: string, createdAt: any, createdBy: any, updatedAt?: any, updatedBy?: any, shipper: { __typename?: 'companies', id: any, name: string }, route: { __typename?: 'routingRoutes', id: any, legs: Array<{ __typename?: 'routingLegs', sequence: any, lane: { __typename?: 'routingLanes', id: any, destinationName?: string, originName?: string } }> }, comments: Array<{ __typename?: 'lanePlanComments', id: any, comment: string }>, fuelSurchargeSchedule?: { __typename?: 'fuelSurchargeSchedules', id: any, name: string }, opportunities: Array<{ __typename?: 'lanePlanOpportunities', opportunityType?: string, priority?: number }> } };

export type GetExplorersQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetExplorersQuery = { __typename?: 'query_root', analyticsAdaptLanes: Array<{ __typename?: 'analyticsAdaptLanes', id: any, batchDate: any, equipmentClass: string }> };



export const GetLanePlanDocument = `
    query GetLanePlan($id: uuid!) {
  lanePlansByPk(id: $id) {
    id
    shipper {
      id
      name
    }
    status
    deprioritizationReason
    volumeVolatility
    carrierChurn
    spendVolatility
    marketPrice
    route {
      id
      legs {
        sequence
        lane {
          id
          destinationName
          originName
        }
      }
    }
    comments {
      id
      comment
    }
    fuelSurchargeSchedule {
      id
      name
    }
    opportunities {
      opportunityType
      priority
    }
    equipmentClass
    adaptLaneId
    estCarrierRate
    plannedShipperRpm
    plannedShipperRpmLastUpdatedAt
    plannedStartDate
    plannedWeeklyVolume
    targetPercBlue
    targetPercDedicated
    targetPercGreen
    targetPercYellow
    pickupType
    deliveryType
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;

export const useGetLanePlanQuery = <
      TData = GetLanePlanQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetLanePlanQueryVariables,
      options?: UseQueryOptions<GetLanePlanQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLanePlanQuery, TError, TData>(
      ['GetLanePlan', variables],
      fetcher<GetLanePlanQuery, GetLanePlanQueryVariables>(client, GetLanePlanDocument, variables, headers),
      options
    )};

useGetLanePlanQuery.document = GetLanePlanDocument;

useGetLanePlanQuery.getKey = (variables: GetLanePlanQueryVariables) => ['GetLanePlan', variables];


useGetLanePlanQuery.fetcher = (client: GraphQLClient, variables: GetLanePlanQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLanePlanQuery, GetLanePlanQueryVariables>(client, GetLanePlanDocument, variables, headers);

export const GetExplorersDocument = `
    query GetExplorers($id: uuid!) @cached(ttl: 300) {
  analyticsAdaptLanes(where: {id: {_eq: $id}}, orderBy: {batchDate: DESC}) {
    id
    batchDate
    equipmentClass
  }
}
    `;

export const useGetExplorersQuery = <
      TData = GetExplorersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetExplorersQueryVariables,
      options?: UseQueryOptions<GetExplorersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetExplorersQuery, TError, TData>(
      ['GetExplorers', variables],
      fetcher<GetExplorersQuery, GetExplorersQueryVariables>(client, GetExplorersDocument, variables, headers),
      options
    )};

useGetExplorersQuery.document = GetExplorersDocument;

useGetExplorersQuery.getKey = (variables: GetExplorersQueryVariables) => ['GetExplorers', variables];


useGetExplorersQuery.fetcher = (client: GraphQLClient, variables: GetExplorersQueryVariables, headers?: RequestInit['headers']) => fetcher<GetExplorersQuery, GetExplorersQueryVariables>(client, GetExplorersDocument, variables, headers);
