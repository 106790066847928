import { NavLink } from 'react-router-dom'

import { Link, List, ListItem } from '@mui/material'

import { DataMissing } from '@leaf/components'
import { time } from '@leaf/utilities'

import type { LaneExplorerType } from '@/features/lane-plan/lane-plan.types'

type LanePlanExplorersProps = {
  adaptLaneId?: string
  explorers?: LaneExplorerType[]
}
const LanePlanExplorers = ({ adaptLaneId, explorers }: LanePlanExplorersProps) => {
  if (!explorers?.length) {
    return <DataMissing />
  }
  return (
    <List>
      {explorers.map((explorer) => {
        const explorerUrl = [
          `/adapt/lanes/${adaptLaneId}`,
          `/explorer?batch-date=${explorer.batchDate}`,
        ]
        if (explorer.equipmentClass) {
          explorerUrl.push(`&equipment-class=${explorer.equipmentClass.toLowerCase()}`)
        }
        return (
          <ListItem key={`${explorer.id}-${explorer.batchDate}-${explorer.equipmentClass}`}>
            <Link component={NavLink} to={explorerUrl.join('')} underline='none'>
              {`${time.toLocalDate(explorer.batchDate)} (${explorer.equipmentClass ?? 'DRY'})`}
            </Link>
          </ListItem>
        )
      })}
    </List>
  )
}

export { LanePlanExplorers }
