import { useParams } from 'react-router-dom'

import { Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { CardHeader, DataRow, Map, Page, Progress, Route, SubCard, Tabs } from '@leaf/components'
import { useTabs } from '@leaf/hooks'
import { Table } from '@leaf/table'
import { color, time, toMoney } from '@leaf/utilities'

import { CONTAINER_TABLE_HEIGHT, TABLE_HEIGHT } from '@/constants'
import { shipmentDetailsQuery } from '@/features/contract/contract-details-shipments-details.api'
import { useTitles } from '@/hooks'

const getStatus = (
  appointmentTime: Date,
  arrivalTime: Date,
  departureTime: Date,
  onTime?: boolean,
) => {
  const style = {
    color: 'white',
    fontWeight: 'bold',
    letterSpacing: '0.1em',
  }
  if (!appointmentTime || !arrivalTime || !departureTime || !onTime) {
    return <Chip label='UNKNOWN' sx={{ ...style, backgroundColor: 'gray' }} />
  }
  if (onTime) {
    return <Chip label='ON TIME' sx={{ ...style, backgroundColor: 'green' }} />
  }
  return <Chip label='NOT ON TIME' sx={{ ...style, backgroundColor: 'orange' }} />
}

const ContractDetailsShipmentsDetails = () => {
  const { contractId, shipmentId } = useParams()

  const { activeTab, setActiveTab } = useTabs()

  const { data } = useQuery(shipmentDetailsQuery(shipmentId!))
  const shipment = data!

  useTitles([
    { to: 'contracts', value: 'Contracts' },
    { to: `contracts/${contractId!}`, value: contractId! },
    { value: 'Shipments' },
    { value: shipmentId! },
  ])

  const originAddress = (
    <Box sx={{ textAlign: 'right' }}>
      <Typography sx={{ fontWeight: 'bold' }}>{shipment.originBusinessName}</Typography>
      <Typography>{shipment.originAddressName}</Typography>
      <Typography>
        {shipment.originCity}, {shipment.originState} {shipment.originZip}
      </Typography>
    </Box>
  )

  const OriginStatus = getStatus(
    shipment.originAppointmentTime,
    shipment.originArrivalTime,
    shipment.originDepartureTime,
    shipment.dashboardShipmentTender?.pickupOnTime,
  )

  const DestinationStatus = getStatus(
    shipment.destinationAppointmentTime,
    shipment.destinationArrivalTime,
    shipment.destinationDepartureTime,
    shipment.dashboardShipmentTender?.deliveryOnTime,
  )

  const geometry = {
    coordinates: shipment.lane?.drivingData?.path.coordinates[0],
    type: 'LineString' as const,
  }

  const destinationAddress = (
    <Box sx={{ textAlign: 'right' }}>
      <Typography sx={{ fontWeight: 'bold' }}>{shipment.destinationBusinessName}</Typography>
      <Typography>{shipment.destinationAddressName}</Typography>
      <Typography>
        {shipment.destinationCity}, {shipment.destinationState} {shipment.destinationZip}
      </Typography>
    </Box>
  )

  return (
    <>
      <Progress />

      <Tabs
        activeTab={activeTab}
        changeTab={setActiveTab}
        tabs={['Details', 'Invoices', 'Tender Allocation Guide']}
      />

      <Page sx={{ height: 'auto' }}>
        {activeTab === 0 && (
          <>
            <SubCard title='General' xs={6}>
              <DataRow label='Carrier' value={shipment.carrierTripTender?.carrier?.name} />

              <DataRow label='Equipment Type' value={shipment.equipmentType?.name} />

              <DataRow label='Status' value={shipment.shipmentExecutionStatus} />

              <DataRow label='Execution Partner' value={shipment.executionPartnerCompany.name} />

              <DataRow label='Leaf ID' value={shipment.executionPartnerShipmentId} />

              <DataRow
                label='LSP ID'
                value={shipment.carrierTripTender?.executionPartnerShipmentId}
              />

              <DataRow label='Shipment ID' value={shipment.shipperShipmentId} />

              <DataRow label='Leaf Miles' value={shipment.lane?.drivingData?.leafMiles} />
            </SubCard>

            <SubCard sx={{ height: '405px', padding: '0 !important' }} xs={6}>
              <Map fitBounds={[geometry]} mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}>
                <Route.MapLane color={color.lane(0)} geometry={geometry} id='0' position={0} />
              </Map>
            </SubCard>

            <SubCard actions={OriginStatus} title='Pickup' xs={6}>
              <DataRow jsx label='Address' value={originAddress} />

              <DataRow
                label='Appointment Time'
                value={time.toLocalDatetime(shipment.originAppointmentTime, true)}
              />

              <DataRow
                label='Arrival Time'
                value={time.toLocalDatetime(shipment.originArrivalTime, true)}
              />

              <DataRow
                label='Departure Time'
                value={time.toLocalDatetime(shipment.originDepartureTime, true)}
              />

              <DataRow label='Type' value={shipment.origin?.locationType} />
            </SubCard>

            <Grid item xs={6}>
              <Card>
                <CardHeader action={DestinationStatus} title='Delivery' />

                <CardContent>
                  <DataRow jsx label='Address' value={destinationAddress} />

                  <DataRow
                    label='Appointment Time'
                    value={time.toLocalDatetime(shipment.destinationAppointmentTime, true)}
                  />

                  <DataRow
                    label='Arrival Time'
                    value={time.toLocalDatetime(shipment.destinationArrivalTime, true)}
                  />

                  <DataRow
                    label='Departure Time'
                    value={time.toLocalDatetime(shipment.destinationDepartureTime, true)}
                  />

                  <DataRow label='Type' value={shipment.destination?.locationType} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card>
                <CardHeader title='Matched Contract' />

                <CardContent>
                  <DataRow label='Contract ID' value={shipment.buyContract?.id} />

                  <DataRow label='Type' value={shipment.buyContract?.contractType} />

                  <DataRow
                    label='Linehaul'
                    value={toMoney(
                      shipment.dashboardShipmentTender?.contractedLinehaulCostsShipper,
                    )}
                  />

                  <DataRow
                    label='RPM'
                    value={toMoney(shipment.dashboardShipmentTender?.contractedRateShipper)}
                  />

                  <DataRow
                    label='Fuel'
                    value={toMoney(shipment.dashboardShipmentTender?.contractedFuelCostShipper)}
                  />

                  <DataRow
                    label='Accessorials'
                    value={toMoney(
                      shipment.dashboardShipmentTender?.contractedAccessorialCostShipper,
                    )}
                  />

                  <DataRow
                    label='Total'
                    value={toMoney(shipment.dashboardShipmentTender?.contractedTotalCostShipper)}
                  />
                </CardContent>
              </Card>
            </Grid>
          </>
        )}

        {activeTab === 1 && (
          <SubCard sx={{ height: CONTAINER_TABLE_HEIGHT, padding: 0 }}>
            <Table
              columns={[
                {
                  field: 'date',
                  headerName: 'Date',
                  type: 'date',
                },
                {
                  field: 'fuel',
                  headerName: 'Fuel',
                  // @ts-expect-error
                  type: 'money',
                },
                {
                  field: 'linehaul',
                  headerName: 'Linehaul',
                  // @ts-expect-error
                  type: 'money',
                },
                {
                  field: 'accessorial',
                  headerName: 'Accessorial',
                  // @ts-expect-error
                  type: 'money',
                },
                {
                  field: 'total',
                  headerName: 'Total',
                  // @ts-expect-error
                  type: 'money',
                },
                {
                  field: 'status',
                  headerName: 'Status',
                },
              ]}
              getRowId={(row) => `${row.load}-${row.date}`}
              height={TABLE_HEIGHT}
              name='contractDetailsShipmentDetailsInvoices'
              rows={shipment.shipperInvoicesView}
              slots={{
                toolbar: null,
              }}
            />
          </SubCard>
        )}

        {activeTab === 2 && (
          <SubCard sx={{ height: CONTAINER_TABLE_HEIGHT, padding: 0 }}>
            <Table
              columns={[
                {
                  field: 'tagRank',
                  headerName: 'Rank',
                },
                {
                  field: 'contractType',
                  headerName: 'Type',
                },
                {
                  field: 'carrierContractWeeklyVolumeFulfilled',
                  headerName: 'Fulfilled (Week)',
                  // @ts-expect-error
                  type: 'percent',
                },
                {
                  field: 'carrierContractLifetimeVolumeFulfilled',
                  headerName: 'Fulfilled (Lifetime)',
                  // @ts-expect-error
                  type: 'percent',
                },
                {
                  field: 'carrier.name',
                  headerName: 'LSP',
                },
                {
                  field: 'carrierLinehaul',
                  headerName: 'Linehaul',
                  // @ts-expect-error
                  type: 'money',
                },
                {
                  field: 'carrierTotal',
                  headerName: 'Total',
                  // @ts-expect-error
                  type: 'money',
                },
                {
                  field: 'isNetworkMove',
                  headerName: 'Network Move',
                  type: 'boolean',
                },
              ]}
              getRowId={(row) => row.shipmentTenderId}
              height={TABLE_HEIGHT}
              name='contractDetailsShipmentDetailsTags'
              rows={shipment.shipmentTags}
              slots={{
                toolbar: null,
              }}
            />
          </SubCard>
        )}
      </Page>
    </>
  )
}

export { ContractDetailsShipmentsDetails }
