import { zodResolver as zr } from '@hookform/resolvers/zod'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
} from '@mui/material'
import { Chart } from 'react-chartjs-2'
import {
  CheckboxElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
  useWatch,
} from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'
import * as z from 'zod'

import { CardHeader } from '@leaf/components'
import { ContractColors } from '@leaf/constants'
import { clean, select, validation as v } from '@leaf/form'

import { graphqlClient } from '@/api'
import { useGetEquipmentClassesQuery } from '@/api/equipment-class.api.generated'
import type { LanePlansSetInput } from '@/api/hasura.types.generated'
import type { LanePlanType } from '@/features/lane-plan/lane-plan.types'
import {
  useGetDeliveryTypesQuery,
  useGetLanePlanDeprioritizationReasonsQuery,
  useGetPickupTypesQuery,
  useUpdateLanePlanMutation,
} from '@/features/lane-plan/lane-plan-details-summary.api.generated'
import { useGetLanePlanStatusesQuery } from '@/features/lane-plan/lane-plan-statuses.api.generated'
import { useStore } from '@/store'

const schema = z.object({
  carrierChurn: z.boolean(),
  deliveryType: v.__string,
  deprioritizationReason: v.__string,
  equipmentClass: v.__string,
  estCarrierRate: v.__number,
  marketPrice: z.boolean(),
  pickupType: v.__string,
  plannedShipperRpm: v.__number,
  plannedStartDate: v.__date,
  plannedWeeklyVolume: v.__number,
  spendVolatility: z.boolean(),
  status: v._string,
  targetPercBlue: v.__percentage,
  targetPercDedicated: v.__percentage,
  targetPercGreen: v.__percentage,
  targetPercYellow: v.__percentage,
  volumeVolatility: z.boolean(),
})
type Schema = z.infer<typeof schema>

type LanePlanSummaryProps = {
  plan: LanePlanType
}
const LanePlanSummary = ({ plan }: LanePlanSummaryProps) => {
  const addSnackbar = useStore((state) => state.addSnackbar)

  const { data: statuses } = useGetLanePlanStatusesQuery(
    graphqlClient,
    {},
    { select: ({ lanePlanStatus }) => select.fromEnum(plan.status, lanePlanStatus) },
  )
  const { data: deprioritizationReasons } = useGetLanePlanDeprioritizationReasonsQuery(
    graphqlClient,
    {},
    {
      select: (response) =>
        select.fromEnum(plan.deprioritizationReason, response.lanePlanDeprioritizationReasons),
    },
  )
  const { data: equipmentClasses } = useGetEquipmentClassesQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromEnum(plan.equipmentClass, response.equipmentClasses) },
  )
  const { data: pickupTypes } = useGetPickupTypesQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromEnum(plan.pickupType, response.pickupTypes) },
  )
  const { data: deliveryTypes } = useGetDeliveryTypesQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromEnum(plan.deliveryType, response.deliveryTypes) },
  )
  const { mutate: update } = useUpdateLanePlanMutation(graphqlClient, {
    onSuccess: () => {
      addSnackbar({ message: 'Lane Plan updated' })
    },
  })

  const context = useForm<Schema>({
    defaultValues: {
      carrierChurn: !!plan.carrierChurn,
      deliveryType: plan.deliveryType,
      deprioritizationReason: plan.deprioritizationReason,
      equipmentClass: plan.equipmentClass,
      estCarrierRate: plan.estCarrierRate,
      marketPrice: !!plan.marketPrice,
      pickupType: plan.pickupType,
      plannedShipperRpm: plan.plannedShipperRpm,
      plannedStartDate: plan.plannedStartDate,
      plannedWeeklyVolume: plan.plannedWeeklyVolume,
      spendVolatility: !!plan.spendVolatility,
      status: plan.status,
      targetPercBlue: plan.targetPercBlue,
      targetPercDedicated: plan.targetPercDedicated,
      targetPercGreen: plan.targetPercGreen,
      targetPercYellow: plan.targetPercYellow,
      volumeVolatility: !!plan.volumeVolatility,
    },
    mode: 'all',
    resolver: zr(schema),
  })

  const [targetPercYellow, targetPercBlue, targetPercGreen, targetPercDedicated] = useWatch({
    control: context.control,
    name: ['targetPercYellow', 'targetPercBlue', 'targetPercGreen', 'targetPercDedicated'],
  })

  const onSuccess = (values: Schema) => {
    update(
      clean({
        id: plan.id,
        lanePlan: values as LanePlansSetInput,
      }),
    )
  }

  return (
    <FormContainer formContext={context} onSuccess={onSuccess}>
      <Card>
        <CardHeader
          action={
            <CardActions sx={{ padding: 0 }}>
              <Button color='primary' type='submit' variant='outlined'>
                Save
              </Button>
            </CardActions>
          }
          title='Summary'
        />

        <CardContent>
          <Grid container item spacing={4} xs={12}>
            <Grid container item spacing={2} xs={9}>
              <Grid container item spacing={4} xs={12}>
                <Grid item xs={3}>
                  <SelectElement
                    fullWidth
                    label='Status'
                    name='status'
                    options={statuses}
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={3}>
                  <SelectElement
                    fullWidth
                    label='Deprioritization Reason'
                    name='deprioritizationReason'
                    options={deprioritizationReasons}
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={3}>
                  <DatePickerElement
                    disabled
                    inputProps={{
                      fullWidth: true,
                      value: plan.updatedAt,
                      variant: 'standard',
                    }}
                    label='Last Update'
                    name='updatedAt'
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={4} xs={12}>
                <Grid item xs={3}>
                  <DatePickerElement
                    inputProps={{
                      fullWidth: true,
                      variant: 'standard',
                    }}
                    label='Planned Start Date'
                    name='plannedStartDate'
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldElement
                    InputProps={{
                      inputProps: { min: 0, step: 0.01 },
                    }}
                    fullWidth
                    label='Planned Shipper RPM'
                    name='plannedShipperRpm'
                    type='number'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldElement
                    fullWidth
                    inputProps={{ min: 0 }}
                    label='Planned Weekly Volume'
                    name='plannedWeeklyVolume'
                    type='number'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldElement
                    InputProps={{
                      inputProps: { min: 0, step: 0.01 },
                    }}
                    fullWidth
                    label='Est Carrier Rate'
                    name='estCarrierRate'
                    type='number'
                    variant='standard'
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={4} xs={12}>
                <Grid item xs={3}>
                  <TextFieldElement
                    fullWidth
                    inputProps={{ max: 1, min: 0, step: 0.05 }}
                    label='Target Yellow [%]'
                    name='targetPercYellow'
                    type='number'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldElement
                    fullWidth
                    inputProps={{ max: 1, min: 0, step: 0.05 }}
                    label='Target Blue [%]'
                    name='targetPercBlue'
                    type='number'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldElement
                    fullWidth
                    inputProps={{ max: 1, min: 0, step: 0.05 }}
                    label='Target Green [%]'
                    name='targetPercGreen'
                    type='number'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldElement
                    fullWidth
                    inputProps={{ max: 1, min: 0, step: 0.05 }}
                    label='Target Dedicated [%]'
                    name='targetPercDedicated'
                    type='number'
                    variant='standard'
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={4} xs={12}>
                <Grid item xs={3}>
                  <SelectElement
                    fullWidth
                    label='Equipment Class'
                    name='equipmentClass'
                    options={equipmentClasses}
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={3}>
                  <SelectElement
                    fullWidth
                    label='Pickup Type'
                    name='pickupType'
                    options={pickupTypes}
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={3}>
                  <SelectElement
                    fullWidth
                    label='Delivery Type'
                    name='deliveryType'
                    options={deliveryTypes}
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>Risk Metrics</FormLabel>

                    <FormGroup aria-label='position' row>
                      <CheckboxElement label='Carrier Churn' name='carrierChurn' />

                      <CheckboxElement label='Market Price' name='marketPrice' />

                      <CheckboxElement label='Spend Volatility' name='spendVolatility' />

                      <CheckboxElement label='Volume Volatility' name='volumeVolatility' />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={3}>
              <Grid item xs={12}>
                <Chart
                  data={{
                    datasets: [
                      {
                        backgroundColor: [
                          ContractColors.YELLOW,
                          ContractColors.BLUE,
                          ContractColors.GREEN,
                          ContractColors.DEDICATED,
                        ],
                        data: [
                          targetPercYellow,
                          targetPercBlue,
                          targetPercGreen,
                          targetPercDedicated,
                        ],
                      },
                    ],
                    labels: ['Yellow', 'Blue', 'Green', 'Dedicated'],
                  }}
                  options={{ plugins: { legend: { display: false } } }}
                  type='pie'
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormContainer>
  )
}

export { LanePlanSummary }
