// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetLanePlanStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLanePlanStatusesQuery = { __typename?: 'query_root', lanePlanStatus: Array<{ __typename?: 'lanePlanStatus', name: string, position?: number }> };



export const GetLanePlanStatusesDocument = `
    query GetLanePlanStatuses @cached(ttl: 300) {
  lanePlanStatus(orderBy: {position: ASC}) {
    name
    position
  }
}
    `;

export const useGetLanePlanStatusesQuery = <
      TData = GetLanePlanStatusesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetLanePlanStatusesQueryVariables,
      options?: UseQueryOptions<GetLanePlanStatusesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLanePlanStatusesQuery, TError, TData>(
      variables === undefined ? ['GetLanePlanStatuses'] : ['GetLanePlanStatuses', variables],
      fetcher<GetLanePlanStatusesQuery, GetLanePlanStatusesQueryVariables>(client, GetLanePlanStatusesDocument, variables, headers),
      options
    )};

useGetLanePlanStatusesQuery.document = GetLanePlanStatusesDocument;

useGetLanePlanStatusesQuery.getKey = (variables?: GetLanePlanStatusesQueryVariables) => variables === undefined ? ['GetLanePlanStatuses'] : ['GetLanePlanStatuses', variables];


useGetLanePlanStatusesQuery.fetcher = (client: GraphQLClient, variables?: GetLanePlanStatusesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLanePlanStatusesQuery, GetLanePlanStatusesQueryVariables>(client, GetLanePlanStatusesDocument, variables, headers);
