import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Delete as DeleteIcon } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import { v4 as uuid } from 'uuid'
import * as z from 'zod'

import { Avatar, CardHeader, Styled } from '@leaf/components'
import { validation as v } from '@leaf/form'

import { graphqlClient } from '@/api'
import {
  optimisticDeleteComment,
  optimisticInsertComment,
} from '@/features/lane-plan/lane-plan-details-comments.api'
import {
  useDeleteLanePlanCommentMutation,
  useGetLanePlanCommentsQuery,
  useInsertLanePlanCommentMutation,
} from '@/features/lane-plan/lane-plan-details-comments.api.generated'

const schema = z.object({
  comment: v._string,
})
type Schema = z.infer<typeof schema>

const renderTooltip = (createdAt: string, name?: string) => {
  if (!name) {
    return null
  }
  const date = new Date(createdAt).toLocaleString()
  return (
    <div key={createdAt}>
      {name}
      <br />
      {date}
    </div>
  )
}

type LanePlanCommentsProps = {
  lanePlanId: string
}
const LanePlanComments = ({ lanePlanId }: LanePlanCommentsProps) => {
  const { data } = useGetLanePlanCommentsQuery(graphqlClient, { lanePlanId })
  const { mutate: insertComment } = useInsertLanePlanCommentMutation(
    graphqlClient,
    optimisticInsertComment(lanePlanId),
  )
  const { mutate: deleteComment } = useDeleteLanePlanCommentMutation(
    graphqlClient,
    optimisticDeleteComment(lanePlanId),
  )

  const context = useForm<Schema>({
    resolver: zr(schema),
  })

  const onSuccess = ({ comment }: { comment: string }) => {
    insertComment({
      comment,
      lanePlanId,
    })
    context.reset()
  }

  const getFullName = (user?: { firstName?: string; lastName?: string }) => {
    if (!user || !user.firstName || !user.lastName) {
      return undefined
    }
    return `${user.firstName} ${user.lastName}`
  }

  return (
    <FormContainer formContext={context} onSuccess={onSuccess}>
      <Card>
        <CardHeader
          action={
            <CardActions sx={{ padding: 0 }}>
              <Button color='primary' type='submit' variant='outlined'>
                Save
              </Button>
            </CardActions>
          }
          title='Comments'
        />

        <CardContent>
          <TextFieldElement
            autoComplete='off'
            fullWidth
            label='Add comment'
            margin='none'
            name='comment'
            size='small'
            variant='standard'
          />

          <List>
            {data?.lanePlanComments?.map((comment) => {
              const name = getFullName(comment.createdByUser)
              const tooltip = renderTooltip(comment.createdAt, name)

              return (
                <div key={comment.id || uuid()}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar name={name} tooltip={tooltip} />
                    </ListItemAvatar>

                    <ListItemText sx={{ overflowWrap: 'break-word' }}>
                      {comment.comment}
                    </ListItemText>

                    <ListItemIcon onClick={() => deleteComment({ id: comment.id })}>
                      <DeleteIcon
                        sx={{
                          color: 'rgba(0, 0, 0, 0.4)',
                          cursor: 'pointer',
                          height: '0.8em',
                          minWidth: '0',
                          width: '0.8em',
                        }}
                      />
                    </ListItemIcon>
                  </ListItem>

                  <Styled.Divider />
                </div>
              )
            })}
          </List>
        </CardContent>
      </Card>
    </FormContainer>
  )
}

export { LanePlanComments }
