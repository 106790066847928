// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetEquipmentTypesOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEquipmentTypesOverviewQuery = { __typename?: 'query_root', equipmentTypes: Array<{ __typename?: 'equipmentTypes', id: any, name: string, description: string, equipmentClass?: string, createdAt: any, updatedAt?: any, createdByUser?: { __typename?: 'users', id: any, email: string }, updatedByUser?: { __typename?: 'users', id: any, email: string } }> };

export type InsertEquipmentTypeMutationVariables = Types.Exact<{
  equipmentType: Types.EquipmentTypesInsertInput;
}>;


export type InsertEquipmentTypeMutation = { __typename?: 'mutation_root', insertEquipmentTypesOne?: { __typename?: 'equipmentTypes', id: any } };



export const GetEquipmentTypesOverviewDocument = `
    query GetEquipmentTypesOverview @cached(ttl: 300) {
  equipmentTypes(orderBy: {createdAt: DESC}) {
    id
    name
    description
    equipmentClass
    createdAt
    createdByUser {
      id
      email
    }
    updatedAt
    updatedByUser {
      id
      email
    }
  }
}
    `;

export const useGetEquipmentTypesOverviewQuery = <
      TData = GetEquipmentTypesOverviewQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetEquipmentTypesOverviewQueryVariables,
      options?: UseQueryOptions<GetEquipmentTypesOverviewQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetEquipmentTypesOverviewQuery, TError, TData>(
      variables === undefined ? ['GetEquipmentTypesOverview'] : ['GetEquipmentTypesOverview', variables],
      fetcher<GetEquipmentTypesOverviewQuery, GetEquipmentTypesOverviewQueryVariables>(client, GetEquipmentTypesOverviewDocument, variables, headers),
      options
    )};

useGetEquipmentTypesOverviewQuery.document = GetEquipmentTypesOverviewDocument;

useGetEquipmentTypesOverviewQuery.getKey = (variables?: GetEquipmentTypesOverviewQueryVariables) => variables === undefined ? ['GetEquipmentTypesOverview'] : ['GetEquipmentTypesOverview', variables];


useGetEquipmentTypesOverviewQuery.fetcher = (client: GraphQLClient, variables?: GetEquipmentTypesOverviewQueryVariables, headers?: RequestInit['headers']) => fetcher<GetEquipmentTypesOverviewQuery, GetEquipmentTypesOverviewQueryVariables>(client, GetEquipmentTypesOverviewDocument, variables, headers);

export const InsertEquipmentTypeDocument = `
    mutation insertEquipmentType($equipmentType: equipmentTypesInsertInput!) {
  insertEquipmentTypesOne(object: $equipmentType) {
    id
  }
}
    `;

export const useInsertEquipmentTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InsertEquipmentTypeMutation, TError, InsertEquipmentTypeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<InsertEquipmentTypeMutation, TError, InsertEquipmentTypeMutationVariables, TContext>(
      ['insertEquipmentType'],
      (variables?: InsertEquipmentTypeMutationVariables) => fetcher<InsertEquipmentTypeMutation, InsertEquipmentTypeMutationVariables>(client, InsertEquipmentTypeDocument, variables, headers)(),
      options
    )};

useInsertEquipmentTypeMutation.getKey = () => ['insertEquipmentType'];


useInsertEquipmentTypeMutation.fetcher = (client: GraphQLClient, variables: InsertEquipmentTypeMutationVariables, headers?: RequestInit['headers']) => fetcher<InsertEquipmentTypeMutation, InsertEquipmentTypeMutationVariables>(client, InsertEquipmentTypeDocument, variables, headers);
