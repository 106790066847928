import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Login as DetailsIcon, Square } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  type GridRowId,
  type GridRowParams,
} from '@mui/x-data-grid-pro'
import { useQuery } from '@tanstack/react-query'
import { compact } from 'lodash-es'

import { Map, MapHighlight, MapLegend, Page, Progress, Route, SubCard } from '@leaf/components'
import { MoveTypes } from '@leaf/constants'
import { Columns, Table } from '@leaf/table'
import type * as Types from '@leaf/types'
import { color, geometry } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { adaptConstellationDetailsQuery } from '@/features/adapt-constellation/adapt-constellation-details.api'
import { useGetAdaptConstellationNetworkMovesQuery } from '@/features/adapt-constellation/adapt-constellation-details.api.generated'
import { useTitles } from '@/hooks'

const CUSTOM_HEIGHT = 'calc(100vh - 18em)'

const laneColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    align: 'center',
    columnAlign: 'center',
    maxWidth: 65,
    minWidth: 65,
    renderHeader: () => <Typography sx={{ fontWeight: '500' }}>Moves</Typography>,
    width: 65,
  },
  Columns.LaneExplorer({ id: 'lane.id' }),
  {
    field: 'lane.id',
    headerName: 'ID',
  },
  {
    field: 'lane.company.name',
    headerName: 'Shipper',
  },
  {
    field: 'lane.originLocation.name',
    headerName: 'Origin',
    minWidth: 250,
  },
  {
    field: 'lane.destinationLocation.name',
    headerName: 'Destination',
    minWidth: 250,
  },
  {
    field: 'equipmentClass',
    headerName: 'Equipment Class',
  },
  {
    field: 'networkMoves.length',
    headerName: '# Network Moves',
    type: 'number',
  },
  {
    field: 'lane.leafMiles',
    headerName: 'Miles',
    type: 'number',
  },
  {
    field: 'annualizedLoads',
    headerName: 'Annualized Loads',
    type: 'number',
  },
]

const networkMoveColumns = [
  {
    field: 'actions',
    getActions: (params: GridRowParams) => {
      return [
        <Columns.ActionLink
          className='e2e-detail-action'
          icon={<DetailsIcon />}
          label='Details'
          to={`/adapt/network-moves/${params.id.toString()}`}
        />,
      ]
    },
    headerName: 'Move',
    maxWidth: 80,
    minWidth: 80,
    type: 'actions',
    width: 80,
  },
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'type',
    headerName: 'Type',
    maxWidth: 150,
    minWidth: 150,
  },
  { field: 'shippers', headerName: 'Shippers', sortable: false, type: 'list' },
  {
    field: 'origins',
    headerName: 'Origins',
    sortable: false,
    type: 'list',
  },
  {
    field: 'destinations',
    headerName: 'Destinations',
    sortable: false,
    type: 'list',
  },
  {
    field: 'loads',
    headerAlign: 'right',
    headerName: 'Loads',
    maxWidth: 150,
    minWidth: 150,
    renderCell: (params: GridRowParams) =>
      params.row.loads.map((load: any) => <Box sx={{ textAlign: 'right' }}>{load}</Box>),
    sortable: false,
  },
  {
    field: 'miles',
    headerAlign: 'right',
    headerName: 'Miles',
    maxWidth: 150,
    minWidth: 150,
    renderCell: (params: GridRowParams) =>
      params.row.miles.map((mile: any) => <Box sx={{ textAlign: 'right' }}>{mile}</Box>),
    sortable: false,
  },
]

const laneInitialState = {
  columns: {
    columnVisibilityModel: {
      'lane.id': false,
    },
  },
}

const networkMoveInitialState = {
  columns: {
    columnVisibilityModel: {
      networkMoveId: false,
    },
  },
  pagination: { paginationModel: { pageSize: 10 } },
}

const AdaptConstellationDetails = () => {
  const { id } = useParams()

  const [expandedRows, setExpandedRows] = useState<GridRowId[]>([])
  const [highlightedLane, setHighlightedLane] = useState<string | undefined>(undefined)
  const [highlightedMove, setHighlightedMove] = useState<string | undefined>(undefined)

  const { data, isLoading } = useQuery(adaptConstellationDetailsQuery(id!))

  const { data: constellationNetworkMoves, isLoading: isConstellationNetworkMovesLoading } =
    useGetAdaptConstellationNetworkMovesQuery(
      graphqlClient,
      { constellationId: id! },
      { select: (response) => response.sortedConstellationNetworkMoves, staleTime: 1800000 },
    )

  const constellationLanes = useMemo(
    () =>
      data?.constellationLanes.map((cl) => {
        return {
          ...cl,
          networkMoves: constellationNetworkMoves
            ?.filter((cnm) =>
              [cnm.l0_id, cnm.l1_id, cnm.l2_id, cnm.l3_id, cnm.l4_id].includes(cl.lane.id),
            )
            ?.map((cnm) => ({
              destinations: compact([
                cnm.l0_destination,
                cnm.l1_destination,
                cnm.l2_destination,
                cnm.l3_destination,
                cnm.l4_destination,
              ]),
              geojson: geometry.asFeatureCollection(
                compact([
                  cnm.l0_geometry,
                  cnm.l1_geometry,
                  cnm.l2_geometry,
                  cnm.l3_geometry,
                  cnm.l4_geometry,
                ]),
              ),
              id: cnm.network_move_id,
              loads: compact([
                cnm.l0_loads,
                cnm.l1_loads,
                cnm.l2_loads,
                cnm.l3_loads,
                cnm.l4_loads,
              ]),
              miles: compact([
                cnm.l0_miles,
                cnm.l1_miles,
                cnm.l2_miles,
                cnm.l3_miles,
                cnm.l4_miles,
              ]),
              origins: compact([
                cnm.l0_origin,
                cnm.l1_origin,
                cnm.l2_origin,
                cnm.l3_origin,
                cnm.l4_origin,
              ]),
              shippers: compact([
                cnm.l0_shipper_name,
                cnm.l1_shipper_name,
                cnm.l2_shipper_name,
                cnm.l3_shipper_name,
                cnm.l4_shipper_name,
              ]),
              type: MoveTypes[cnm.move_type ?? ''],
            })),
        }
      }) || [],
    [isConstellationNetworkMovesLoading],
  )

  const selectedLane = useMemo(
    () => constellationLanes.find((cl) => cl.lane.id === expandedRows[0]),
    [expandedRows],
  )
  const selectedMove = useMemo(
    () => selectedLane?.networkMoves?.find((nm) => nm.id === highlightedMove),
    [highlightedMove],
  )

  const getPanelContent = useCallback(() => {
    return (
      <Table
        className='datagrid-master-detail'
        columnHeaderHeight={selectedLane?.networkMoves?.length ? 56 : 0}
        // @ts-expect-error
        columns={networkMoveColumns}
        density='compact'
        getRowId={(row) => row.id}
        headerFilters={false}
        height='auto'
        hideFooter={!selectedLane?.networkMoves?.length}
        initialState={networkMoveInitialState}
        name='constellationDetailsNetworkMoves'
        pageSizeOptions={[10]}
        rows={selectedLane?.networkMoves || []}
        slotProps={{
          row: {
            onMouseEnter: (event) => setHighlightedMove(event.currentTarget.dataset.id),
          },
        }}
        slots={{
          noRowsOverlay: () => null,
          toolbar: null,
        }}
        sx={{ backgroundColor: color.lane(0) }}
      />
    )
  }, [expandedRows])

  const getPanelHeight = useCallback(() => 'auto', [])

  const handlePanelExpandedRow = useCallback((newIds: GridRowId[]) => {
    setExpandedRows(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  useTitles([{ to: 'adapt/constellations', value: 'Adapt Constellations' }, { value: data?.name }])

  const shippers = useMemo(() => {
    let c = 0
    const tmp = {} as { [key: string]: { color: string; name: string } }
    constellationLanes.forEach((constellationLane) => {
      if (!tmp[constellationLane.lane.companyId]) {
        tmp[constellationLane.lane.companyId] = {
          color: color.lane(c),
          name: constellationLane.lane.company.name,
        }
        c += 1
      }
    })
    return tmp
  }, [constellationLanes])

  const nAnnualizedLoads = useMemo(
    () =>
      constellationLanes.reduce(
        (acc, constellationLane) => acc + (constellationLane.annualizedLoads ?? 0),
        0,
      ),
    [constellationLanes],
  )

  const defaultColorMappings = useMemo(() => {
    return constellationLanes.reduce((acc, cl) => {
      const layerId = `lane-layer-${cl.lane.id}`
      return {
        ...acc,
        [layerId]: shippers[cl.lane.companyId].color,
      }
    }, {} as Types.KeyValue)
  }, [constellationLanes])

  const isHighlightLaneActive = Boolean(!selectedLane && highlightedLane)
  const isHighlightMoveActive = Boolean(selectedLane && highlightedMove)

  return (
    <>
      <Progress />

      <Page id='e2e-constellations-details'>
        <Grid item spacing={2} xs={4}>
          <Card>
            <CardHeader sx={{ textAlign: 'center' }} title='Lanes' />

            <CardContent sx={{ alignContent: 'center', display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>
                {constellationLanes.length.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item spacing={2} xs={4}>
          <Card>
            <CardHeader sx={{ textAlign: 'center' }} title='Shippers' />

            <CardContent sx={{ alignContent: 'center', display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>
                {Object.keys(shippers).length.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item spacing={2} xs={4}>
          <Card>
            <CardHeader sx={{ textAlign: 'center' }} title='Annualized Loads' />

            <CardContent sx={{ alignContent: 'center', display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>
                {nAnnualizedLoads.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <SubCard sx={{ height: CUSTOM_HEIGHT, padding: '0 !important' }} xs={8}>
          <Table
            // @ts-expect-error
            columns={laneColumns}
            detailPanelExpandedRowIds={expandedRows}
            getDetailPanelContent={getPanelContent}
            getDetailPanelHeight={getPanelHeight}
            getRowId={(row) => row.lane.id}
            height={CUSTOM_HEIGHT}
            initialState={laneInitialState}
            loading={isLoading}
            name='constellationDetailsLanes'
            onDetailPanelExpandedRowIdsChange={handlePanelExpandedRow}
            rows={constellationLanes || []}
            slotProps={{
              row: {
                onMouseEnter: (event) => setHighlightedLane(event.currentTarget.dataset.id),
              },
            }}
            slots={{
              toolbar: null,
            }}
          />
        </SubCard>

        <Grid container item spacing={2} xs={4}>
          <SubCard
            sx={{ height: '100%', padding: '0 !important' }}
            sxCard={{ height: '100%' }}
            xs={12}
          >
            <Map
              fitBounds={constellationLanes.map((cl) => cl.lane.geometry)}
              mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
            >
              {(expandedRows.length &&
                selectedLane?.networkMoves?.map((cnm) => (
                  <MapHighlight
                    active={isHighlightMoveActive}
                    defaultColor={color.lane(0)}
                    id={`lane-layer-${selectedMove?.id}`}
                    prefix='lane-layer-'
                  >
                    <Route.MapLane
                      color={color.lane(0)}
                      // @ts-expect-error
                      geometry={cnm.geojson}
                      id={cnm.id}
                      key={cnm.id}
                    />
                  </MapHighlight>
                ))) || (
                <>
                  <MapLegend>
                    {Object.values(shippers).map((shipper) => (
                      <>
                        <Square sx={{ color: shipper.color }} />
                        <Typography>{shipper.name}</Typography>
                      </>
                    ))}
                  </MapLegend>

                  <MapHighlight
                    active={isHighlightLaneActive}
                    defaultColorMappings={defaultColorMappings}
                    id={`lane-layer-${highlightedLane}`}
                    prefix='lane-layer-'
                  >
                    {constellationLanes?.map((cl) => (
                      <Route.MapLane
                        color={shippers[cl.lane.companyId].color}
                        geometry={cl.lane.geometry}
                        id={cl.lane.id}
                        key={cl.lane.id}
                      />
                    ))}
                  </MapHighlight>
                </>
              )}
            </Map>
          </SubCard>
        </Grid>
      </Page>
    </>
  )
}

export { AdaptConstellationDetails }
