// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetLanePlanCommentsQueryVariables = Types.Exact<{
  lanePlanId: Types.Scalars['uuid']['input'];
}>;


export type GetLanePlanCommentsQuery = { __typename?: 'query_root', lanePlanComments: Array<{ __typename?: 'lanePlanComments', id: any, comment: string, createdAt: any, createdByUser?: { __typename?: 'users', firstName?: string, lastName?: string } }> };

export type InsertLanePlanCommentMutationVariables = Types.Exact<{
  lanePlanId: Types.Scalars['uuid']['input'];
  comment: Types.Scalars['String']['input'];
}>;


export type InsertLanePlanCommentMutation = { __typename?: 'mutation_root', insertLanePlanCommentsOne?: { __typename?: 'lanePlanComments', id: any, comment: string, createdAt: any, createdByUser?: { __typename?: 'users', firstName?: string, lastName?: string } } };

export type DeleteLanePlanCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeleteLanePlanCommentMutation = { __typename?: 'mutation_root', deleteLanePlanCommentsByPk?: { __typename?: 'lanePlanComments', id: any } };



export const GetLanePlanCommentsDocument = `
    query GetLanePlanComments($lanePlanId: uuid!) {
  lanePlanComments(
    orderBy: {createdAt: DESC}
    where: {lanePlanId: {_eq: $lanePlanId}}
  ) {
    id
    comment
    createdByUser {
      firstName
      lastName
    }
    createdAt
  }
}
    `;

export const useGetLanePlanCommentsQuery = <
      TData = GetLanePlanCommentsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetLanePlanCommentsQueryVariables,
      options?: UseQueryOptions<GetLanePlanCommentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLanePlanCommentsQuery, TError, TData>(
      ['GetLanePlanComments', variables],
      fetcher<GetLanePlanCommentsQuery, GetLanePlanCommentsQueryVariables>(client, GetLanePlanCommentsDocument, variables, headers),
      options
    )};

useGetLanePlanCommentsQuery.document = GetLanePlanCommentsDocument;

useGetLanePlanCommentsQuery.getKey = (variables: GetLanePlanCommentsQueryVariables) => ['GetLanePlanComments', variables];


useGetLanePlanCommentsQuery.fetcher = (client: GraphQLClient, variables: GetLanePlanCommentsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLanePlanCommentsQuery, GetLanePlanCommentsQueryVariables>(client, GetLanePlanCommentsDocument, variables, headers);

export const InsertLanePlanCommentDocument = `
    mutation InsertLanePlanComment($lanePlanId: uuid!, $comment: String!) {
  insertLanePlanCommentsOne(object: {lanePlanId: $lanePlanId, comment: $comment}) {
    id
    comment
    createdByUser {
      firstName
      lastName
    }
    createdAt
  }
}
    `;

export const useInsertLanePlanCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InsertLanePlanCommentMutation, TError, InsertLanePlanCommentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<InsertLanePlanCommentMutation, TError, InsertLanePlanCommentMutationVariables, TContext>(
      ['InsertLanePlanComment'],
      (variables?: InsertLanePlanCommentMutationVariables) => fetcher<InsertLanePlanCommentMutation, InsertLanePlanCommentMutationVariables>(client, InsertLanePlanCommentDocument, variables, headers)(),
      options
    )};

useInsertLanePlanCommentMutation.getKey = () => ['InsertLanePlanComment'];


useInsertLanePlanCommentMutation.fetcher = (client: GraphQLClient, variables: InsertLanePlanCommentMutationVariables, headers?: RequestInit['headers']) => fetcher<InsertLanePlanCommentMutation, InsertLanePlanCommentMutationVariables>(client, InsertLanePlanCommentDocument, variables, headers);

export const DeleteLanePlanCommentDocument = `
    mutation DeleteLanePlanComment($id: uuid!) {
  deleteLanePlanCommentsByPk(id: $id) {
    id
  }
}
    `;

export const useDeleteLanePlanCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteLanePlanCommentMutation, TError, DeleteLanePlanCommentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteLanePlanCommentMutation, TError, DeleteLanePlanCommentMutationVariables, TContext>(
      ['DeleteLanePlanComment'],
      (variables?: DeleteLanePlanCommentMutationVariables) => fetcher<DeleteLanePlanCommentMutation, DeleteLanePlanCommentMutationVariables>(client, DeleteLanePlanCommentDocument, variables, headers)(),
      options
    )};

useDeleteLanePlanCommentMutation.getKey = () => ['DeleteLanePlanComment'];


useDeleteLanePlanCommentMutation.fetcher = (client: GraphQLClient, variables: DeleteLanePlanCommentMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteLanePlanCommentMutation, DeleteLanePlanCommentMutationVariables>(client, DeleteLanePlanCommentDocument, variables, headers);
