import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'

import { CompanyAvatar, Route } from '@leaf/components'

import type { RouteLaneType } from '@/features/adapt-network-move/adapt-network-move-details.types'

type LaneProps = {
  color: string
  data: RouteLaneType
  position: number
}
const AdaptNetworkMoveRouteScheduleLane = ({ color, data, position }: LaneProps) => (
  <Box
    sx={{
      display: 'flex',
      position: 'relative',
    }}
  >
    <Route.RouteStyled $color={color} $position={position} />

    <Route.LaneStyled>
      <Typography variant='body2'>{data.originLocation.name}</Typography>

      <Box>
        <Route.DistanceStyled>
          <Box gap={8} sx={{ display: 'flex' }}>
            {data.companyMiles ? (
              <Typography variant='caption'>{data.companyMiles} miles</Typography>
            ) : (
              <Box gap={4} sx={{ alignItems: 'center', display: 'flex' }}>
                <Typography variant='caption'>{data.leafMiles} miles</Typography>

                <Tooltip title='Showing Leaf distance based on PC*Miler Web API. Shipper mileage is not known.'>
                  <InfoIcon />
                </Tooltip>
              </Box>
            )}
          </Box>

          <Route.HorizontalLineStyled />

          <Box sx={{ marginLeft: '2', marginRight: '2' }}>
            <CompanyAvatar id={data.company.id} />
          </Box>
        </Route.DistanceStyled>
      </Box>

      <Typography variant='body2'>{data.destinationLocation.name}</Typography>
    </Route.LaneStyled>
  </Box>
)

export { AdaptNetworkMoveRouteScheduleLane }
