import { subMonths, subYears } from 'date-fns'
import { Line } from 'react-chartjs-2'

import { DataMissing, DataRow } from '@leaf/components'
import { Months } from '@leaf/constants'
import type { LaneExplorerTimeseries } from '@leaf/lane-explorer/lane-explorer.types'

import { graphqlClient } from '@/api'
import type { LaneExplorerType } from '@/features/lane-plan/lane-plan.types'
import {
  type GetActualsQuery,
  useGetActualsQuery,
  useGetExplorerMetaQuery,
} from '@/features/lane-plan/lane-plan-details-volume.api.generated'

type HistoricalsType = {
  average: null | number
  data: null | number[]
  year: null | number
}
type ActualsType = { average: null | number; data: null | number[] }

const calculateHistoricals = (timeseries?: LaneExplorerTimeseries) => {
  const historicals: HistoricalsType = {
    average: null,
    data: null,
    year: null,
  }
  if (!timeseries?.length) {
    return historicals
  }
  // TODO, ensure continuity
  const lastShipmentDate = new Date(timeseries[timeseries.length - 1].shipDate)
  const cutoffDate = subMonths(subYears(lastShipmentDate, 1), 1)
  historicals.year = lastShipmentDate.getFullYear()

  const historicalsByMonth = timeseries.reduce(
    (acc, data) => {
      const date = new Date(data.shipDate)
      if (date > cutoffDate) {
        const month = date.getMonth()
        acc[month] += data.shipments
      }
      return acc
    },
    Array(12).fill(0) as number[],
  )
  historicals.data = historicalsByMonth

  // Naively assume that there are always 52 weeks in a year.
  const averageHistoricalsPerWeek =
    historicalsByMonth.reduce((acc, historical) => {
      return acc + historical
    }, 0) / 52.0
  historicals.average = averageHistoricalsPerWeek

  return historicals
}

const transformActualsResponse = (response: GetActualsQuery) => {
  const actuals: ActualsType = {
    average: null,
    data: null,
  }
  if (!response.actuals.length) {
    return actuals
  }

  const actualsByMonth = response.actuals.reduce((acc, actualResponse) => {
    const date = new Date(actualResponse.date)
    const month = new Date(date).getMonth()
    acc[month] += 1
    return acc
  }, Array(12).fill(0))
  actuals.data = actualsByMonth

  const averageActualsPerWeek =
    response.actuals.reduce((acc, actualResponse) => {
      return acc + actualResponse.count
    }, 0) / 52.0
  actuals.average = averageActualsPerWeek

  return actuals
}

type LanePlanVolumeProps = {
  explorers?: LaneExplorerType[]
  plannedWeeklyVolume?: number
  routeId: string
  shipperId: string
}
const LanePlanVolume = ({
  explorers = [],
  plannedWeeklyVolume,
  routeId,
  shipperId,
}: LanePlanVolumeProps) => {
  const latestExplorer = explorers[0]
  const { batchDate, equipmentClass, id } = latestExplorer || {}
  const hasExplorerData = [id, batchDate, equipmentClass].every((value) => !!value)
  const { data: latestExplorerMetaResponse } = useGetExplorerMetaQuery(
    graphqlClient,
    {
      batchDate,
      equipmentClass: equipmentClass!,
      id,
    },
    {
      enabled: hasExplorerData,
    },
  )

  const { data: actuals } = useGetActualsQuery(
    graphqlClient,
    {
      routeId,
      shipperId,
    },
    { select: transformActualsResponse },
  )

  // Planned
  const plannedData = plannedWeeklyVolume
    ? Object.keys(Months).map(() => {
        // Naively multiply by 4 to get monthly volume
        return (plannedWeeklyVolume || 0) * 4
      })
    : null

  // Historicals
  const timeseries = (latestExplorerMetaResponse?.analyticsAdaptLaneDetails?.[0]?.meta
    ?.timeseries || []) as LaneExplorerTimeseries
  const historicals = calculateHistoricals(timeseries)

  if (!historicals.data?.length && !actuals?.data?.length && !plannedData?.length) {
    return <DataMissing />
  }

  const data = {
    datasets: [
      {
        backgroundColor: 'gray',
        borderColor: 'lightgray',
        data: historicals.data,
        label: 'Historical',
      },
      {
        backgroundColor: 'blue',
        borderColor: 'lightblue',
        data: actuals?.data,
        label: 'Actual',
      },
      {
        backgroundColor: 'green',
        borderColor: 'lightgreen',
        data: plannedData,
        label: 'Planned',
      },
    ],
    labels: Object.keys(Months),
  }

  if (historicals.data?.length) {
    data.datasets.push({
      backgroundColor: 'gray',
      borderColor: 'lightgray',
      data: historicals.data,
      label: 'Historical',
    })
  }
  if (actuals?.data?.length) {
    data.datasets.push({
      backgroundColor: 'blue',
      borderColor: 'lightblue',
      data: actuals?.data,
      label: 'Actual',
    })
  }
  if (plannedData?.length) {
    data.datasets.push({
      backgroundColor: 'green',
      borderColor: 'lightgreen',
      data: plannedData,
      label: 'Plan',
    })
  }

  return (
    <>
      <DataRow label='Plan/week' value={plannedWeeklyVolume} />

      <DataRow label='Historical/week' value={historicals.average?.toFixed(2)} />

      <DataRow label='Actual/week' value={actuals?.average?.toFixed(2)} />

      <Line
        data={data}
        options={{
          plugins: {
            legend: {
              position: 'bottom',
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  if (context.datasetIndex === 0) {
                    return `${context.formattedValue} shipments in ${historicals.year}`
                  }
                  return `${context.formattedValue} shipments`
                },
                title: (context) => {
                  const m = context[0].label as keyof typeof Months
                  return `${Months[m]}`
                },
              },
            },
          },
          responsive: true,
          scales: {
            y: {
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
      />
    </>
  )
}

export { LanePlanVolume }
