import { useShallow } from 'zustand/react/shallow'

import { Table } from '@leaf/table'

import { graphqlClient } from '@/api'
import { useGetFuelPriceIndexOverviewQuery } from '@/features/fuel-price-index/fuel-price-index-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const exportOptions = { fileName: 'fuel_price_indexes' }

const FuelPriceIndexOverview = () => {
  const [savedState, changeTable] = useStore(
    useShallow((state) => [state.fuelPriceIndexOverview, state.changeTable]),
  )

  useTitles([{ value: 'Fuel Price Indexes' }])

  const { data, isLoading } = useGetFuelPriceIndexOverviewQuery(
    graphqlClient,
    {},
    { select: (response) => response.fuelPriceIndexes },
  )

  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Name' },
    { field: 'providerName', headerName: 'Provider' },
    { field: 'seriesId', headerName: 'Series ID', minWidth: 300 },
    { field: 'states', headerName: 'States' },
    { field: 'hierarchyLevel', headerName: 'Hierarchy Level', type: 'number' },
    { field: 'createdAt', headerName: 'Created At', type: 'date' },
    { field: 'createdByUser.email', headerName: 'Created By' },
  ]

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      exportOptions={exportOptions}
      loading={isLoading}
      name='fuelPriceIndexOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={data}
    />
  )
}

export { FuelPriceIndexOverview }
