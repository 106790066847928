import { useParams } from 'react-router-dom'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import * as z from 'zod'

import { DataRow, Map, Page, Progress, Route } from '@leaf/components'
import { clean, validation as v } from '@leaf/form'
import { color, time } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import {
  type ExecutionLaneMappedType,
  mapExecutionLane,
} from '@/features/execution-lane/execution-lane.mapper'
import { executionLaneDetailsQuery } from '@/features/execution-lane/execution-lane-details.api'
import { useUpdateExecutionLaneMutation } from '@/features/execution-lane/execution-lane-details.api.generated'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const toAddress = (location: {
  businessName?: string
  city?: string
  state?: string
  street?: string
  zip?: string
}) => {
  if (
    !location.businessName &&
    !location.street &&
    !location.city &&
    !location.state &&
    !location.zip
  ) {
    return null
  }
  return (
    <Box sx={{ textAlign: 'right' }}>
      <Typography sx={{ fontWeight: 'bold' }}>{location.businessName}</Typography>
      <Typography>{location.street}</Typography>
      <Typography>
        {location.city}, {location.state} {location.zip}
      </Typography>
    </Box>
  )
}

const schema = z.object({
  co2ReductionPerShipment: v.__number,
  referenceRpm: v.__number,
  shipperDistance: v.__number,
})
type Schema = z.infer<typeof schema>

const ExecutionLaneDetails = () => {
  const { id } = useParams()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const { data } = useQuery(executionLaneDetailsQuery(id!))
  const executionLane = mapExecutionLane(data!) as ExecutionLaneMappedType

  const { mutate: update } = useUpdateExecutionLaneMutation(graphqlClient, {
    onSuccess: () => addSnackbar({ message: 'Execution lane updated successfully' }),
  })

  const context = useForm<Schema>({
    defaultValues: executionLane,
    mode: 'all',
    resolver: zr(schema),
  })

  const onSubmit = (values: Schema) => {
    update({ executionLane: clean(values), id: executionLane.id })
  }

  useTitles([{ to: 'execution/lanes', value: 'Execution Lanes' }, { value: executionLane.id }])
  useActions([
    <Button
      color='primary'
      form='execution-lane-details-form'
      key='saveExecutionLane'
      sx={{ marginRight: '1em' }}
      type='submit'
      variant='outlined'
    >
      Save
    </Button>,
  ])

  return (
    <FormContainer
      FormProps={{ id: 'execution-lane-details-form' }}
      formContext={context}
      onSuccess={onSubmit}
    >
      <Progress />

      <Page>
        <Grid item xs={5}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DataRow label='Shipper' value={executionLane.shipper.name} />
                </Grid>

                <Grid item xs={12}>
                  <DataRow label='Origin' value={toAddress(executionLane.origin)} />
                </Grid>

                <Grid item xs={12}>
                  <DataRow label='Destination' value={toAddress(executionLane.destination)} />
                </Grid>

                <Grid item xs={12}>
                  <DataRow label='Leaf Distance' value={executionLane.leafDistance} />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Shipper Distance'
                    name='shipperDistance'
                    type='number'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='CO2 Reduction Per Shipment'
                    name='co2ReductionPerShipment'
                    type='number'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Reference RPM'
                    name='referenceRpm'
                    type='number'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow label='Tolls' value={executionLane.tolls} />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    label='Driving Time'
                    value={executionLane.drivingTime ? `${executionLane.drivingTime} hrs` : null}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    label='Created At'
                    value={time.toLocalDatetime(executionLane.createdAt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    label='Updated At'
                    value={time.toLocalDatetime(executionLane.lastUpdatedAt)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={7}>
          <Map
            fitBounds={[executionLane.geo.geometry]}
            mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
          >
            <Route.MapLane
              color={color.lane(0)}
              geometry={executionLane.geo}
              id={executionLane.id.toString()}
              key={executionLane.id}
            />
          </Map>
        </Grid>
      </Page>
    </FormContainer>
  )
}

export { ExecutionLaneDetails }
