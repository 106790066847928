// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetLanePlanDeprioritizationReasonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLanePlanDeprioritizationReasonsQuery = { __typename?: 'query_root', lanePlanDeprioritizationReasons: Array<{ __typename?: 'lanePlanDeprioritizationReasons', name: string, description?: string }> };

export type GetPickupTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPickupTypesQuery = { __typename?: 'query_root', pickupTypes: Array<{ __typename?: 'pickupTypes', name: string, description?: string }> };

export type GetDeliveryTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDeliveryTypesQuery = { __typename?: 'query_root', deliveryTypes: Array<{ __typename?: 'deliveryTypes', name: string, description?: string }> };

export type UpdateLanePlanMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  lanePlan: Types.LanePlansSetInput;
}>;


export type UpdateLanePlanMutation = { __typename?: 'mutation_root', updateLanePlansByPk?: { __typename?: 'lanePlans', id: any } };



export const GetLanePlanDeprioritizationReasonsDocument = `
    query GetLanePlanDeprioritizationReasons @cached(ttl: 300) {
  lanePlanDeprioritizationReasons {
    name
    description
  }
}
    `;

export const useGetLanePlanDeprioritizationReasonsQuery = <
      TData = GetLanePlanDeprioritizationReasonsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetLanePlanDeprioritizationReasonsQueryVariables,
      options?: UseQueryOptions<GetLanePlanDeprioritizationReasonsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLanePlanDeprioritizationReasonsQuery, TError, TData>(
      variables === undefined ? ['GetLanePlanDeprioritizationReasons'] : ['GetLanePlanDeprioritizationReasons', variables],
      fetcher<GetLanePlanDeprioritizationReasonsQuery, GetLanePlanDeprioritizationReasonsQueryVariables>(client, GetLanePlanDeprioritizationReasonsDocument, variables, headers),
      options
    )};

useGetLanePlanDeprioritizationReasonsQuery.document = GetLanePlanDeprioritizationReasonsDocument;

useGetLanePlanDeprioritizationReasonsQuery.getKey = (variables?: GetLanePlanDeprioritizationReasonsQueryVariables) => variables === undefined ? ['GetLanePlanDeprioritizationReasons'] : ['GetLanePlanDeprioritizationReasons', variables];


useGetLanePlanDeprioritizationReasonsQuery.fetcher = (client: GraphQLClient, variables?: GetLanePlanDeprioritizationReasonsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLanePlanDeprioritizationReasonsQuery, GetLanePlanDeprioritizationReasonsQueryVariables>(client, GetLanePlanDeprioritizationReasonsDocument, variables, headers);

export const GetPickupTypesDocument = `
    query GetPickupTypes @cached(ttl: 300) {
  pickupTypes {
    name
    description
  }
}
    `;

export const useGetPickupTypesQuery = <
      TData = GetPickupTypesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetPickupTypesQueryVariables,
      options?: UseQueryOptions<GetPickupTypesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetPickupTypesQuery, TError, TData>(
      variables === undefined ? ['GetPickupTypes'] : ['GetPickupTypes', variables],
      fetcher<GetPickupTypesQuery, GetPickupTypesQueryVariables>(client, GetPickupTypesDocument, variables, headers),
      options
    )};

useGetPickupTypesQuery.document = GetPickupTypesDocument;

useGetPickupTypesQuery.getKey = (variables?: GetPickupTypesQueryVariables) => variables === undefined ? ['GetPickupTypes'] : ['GetPickupTypes', variables];


useGetPickupTypesQuery.fetcher = (client: GraphQLClient, variables?: GetPickupTypesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetPickupTypesQuery, GetPickupTypesQueryVariables>(client, GetPickupTypesDocument, variables, headers);

export const GetDeliveryTypesDocument = `
    query GetDeliveryTypes @cached(ttl: 300) {
  deliveryTypes {
    name
    description
  }
}
    `;

export const useGetDeliveryTypesQuery = <
      TData = GetDeliveryTypesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetDeliveryTypesQueryVariables,
      options?: UseQueryOptions<GetDeliveryTypesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetDeliveryTypesQuery, TError, TData>(
      variables === undefined ? ['GetDeliveryTypes'] : ['GetDeliveryTypes', variables],
      fetcher<GetDeliveryTypesQuery, GetDeliveryTypesQueryVariables>(client, GetDeliveryTypesDocument, variables, headers),
      options
    )};

useGetDeliveryTypesQuery.document = GetDeliveryTypesDocument;

useGetDeliveryTypesQuery.getKey = (variables?: GetDeliveryTypesQueryVariables) => variables === undefined ? ['GetDeliveryTypes'] : ['GetDeliveryTypes', variables];


useGetDeliveryTypesQuery.fetcher = (client: GraphQLClient, variables?: GetDeliveryTypesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetDeliveryTypesQuery, GetDeliveryTypesQueryVariables>(client, GetDeliveryTypesDocument, variables, headers);

export const UpdateLanePlanDocument = `
    mutation UpdateLanePlan($id: uuid!, $lanePlan: lanePlansSetInput!) {
  updateLanePlansByPk(pkColumns: {id: $id}, _set: $lanePlan) {
    id
  }
}
    `;

export const useUpdateLanePlanMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateLanePlanMutation, TError, UpdateLanePlanMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateLanePlanMutation, TError, UpdateLanePlanMutationVariables, TContext>(
      ['UpdateLanePlan'],
      (variables?: UpdateLanePlanMutationVariables) => fetcher<UpdateLanePlanMutation, UpdateLanePlanMutationVariables>(client, UpdateLanePlanDocument, variables, headers)(),
      options
    )};

useUpdateLanePlanMutation.getKey = () => ['UpdateLanePlan'];


useUpdateLanePlanMutation.fetcher = (client: GraphQLClient, variables: UpdateLanePlanMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateLanePlanMutation, UpdateLanePlanMutationVariables>(client, UpdateLanePlanDocument, variables, headers);
