import { useShallow } from 'zustand/react/shallow'

import { Columns, Table } from '@leaf/table'

import { graphqlClient } from '@/api'
import { mapExecutionLane } from '@/features/execution-lane/execution-lane.mapper'
import { useGetExecutionLaneOverviewQuery } from '@/features/execution-lane/execution-lane-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const exportOptions = { fileName: 'execution_lanes' }

const ExecutionLaneOverview = () => {
  const [savedState, changeTable] = useStore(
    useShallow((state) => [state.executionLaneOverview, state.changeTable]),
  )

  useTitles([{ value: 'Execution Lanes' }])

  const { data, isLoading } = useGetExecutionLaneOverviewQuery(
    graphqlClient,
    {},
    {
      select: ({ executionLanes }) => executionLanes.map(mapExecutionLane),
    },
  )

  const columns = [
    Columns.Action(),
    { field: 'id', headerName: 'ID' },
    { field: 'shipper.name', headerName: 'Shipper' },
    { field: 'originName', headerName: 'Origin', type: 'list' },
    { field: 'destinationName', headerName: 'Destination', type: 'list' },
    { field: 'leafDistance', headerName: 'Leaf Distance', type: 'miles' },
    { field: 'shipperDistance', headerName: 'Shipper Distance', type: 'miles' },
    { field: 'referenceRpm', headerName: 'Reference RPM', type: 'money' },
    { field: 'tolls', headerName: 'Estimated Tolls', type: 'money' },
    { field: 'drivingTime', headerName: 'Driving Time', type: 'hours' },
    { field: 'co2Reduction', headerName: 'CO2 Reduction (tons)' },
    { field: 'createdAt', headerName: 'Created At', type: 'date' },
    { field: 'lastUpdatedAt', headerName: 'Updated At', type: 'date' },
  ]

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      exportOptions={exportOptions}
      loading={isLoading}
      name='executionLaneOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={data}
    />
  )
}

export { ExecutionLaneOverview }
