import { useState } from 'react'

import { CameraAltRounded as UploadIcon } from '@mui/icons-material'
import axios from 'axios'

import { CompanyAvatar, Spinner } from '@leaf/components'
import type * as Types from '@leaf/types'

import { restClient } from '@/api'
import {
  CompanyAvatarCircleStyled,
  CompanyAvatarUploadStyled,
} from '@/features/company/company-details-avatar-upload.styled'
import { useStore } from '@/store'

/**
 * Upload company logo to AWS S3 with a presigned URL
 * @param {string} companyId
 * @param {string} files
 * @returns the filepath to uploaded image
 */
const uploadImage = async (companyId: number, files: FileList) => {
  // Get the AWS S3 presigned URL with the initialized axios client
  const response = await restClient.post(`/logos/${companyId}`)
  const presignedObject = response.data

  const formData = new FormData()
  Object.entries(presignedObject.fields).forEach(([k, v]) => {
    formData.append(k, v as string)
  })
  formData.append('file', files[0])

  // Create a new axios local client for interacting with S3
  const s3Client = axios.create({ headers: { 'Content-Type': 'multipart/form-data' } })
  // Upload to the presigned URL
  return s3Client
    .post(presignedObject.url, formData)
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

type CompanyAvatarUploadProps = {
  id: number
  name: string
}
const CompanyAvatarUpload = ({ id, name }: CompanyAvatarUploadProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const addSnackbar = useStore((state) => state.addSnackbar)

  const handleUpload = async (event: Types.FilesEvent) => {
    const { files } = event.currentTarget

    if (files && files.length) {
      setIsLoading(true)
      const result = await uploadImage(id, files)
      setIsLoading(false)
      if (result) {
        addSnackbar({
          message: 'Upload successful. The logo will be refreshed within the next 15 minutes.',
        })
      } else {
        addSnackbar({ message: 'Failed to upload the logo.', severity: 'error' })
      }
    }
  }

  return (
    <CompanyAvatarUploadStyled>
      <input
        accept='image/png'
        hidden
        id='companyLogo'
        name='file'
        onChange={handleUpload}
        type='file'
      />

      <label htmlFor='companyLogo'>
        {isLoading ? (
          <Spinner overlay />
        ) : (
          <CompanyAvatarCircleStyled>
            <UploadIcon />
          </CompanyAvatarCircleStyled>
        )}
      </label>

      <CompanyAvatar id={id} name={name} />
    </CompanyAvatarUploadStyled>
  )
}

export { CompanyAvatarUpload }
