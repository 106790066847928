import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  AutocompleteElement,
  CheckboxElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
  useWatch,
} from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'
import * as z from 'zod'

import { CardHeader, DataRow, DataRowSection, Page, Progress } from '@leaf/components'
import type { StatesKey } from '@leaf/constants'
import { CompanyTypes, CompanyTypesSelect, States, StatesSelect } from '@leaf/constants'
import { clean, validation as v } from '@leaf/form'
import { useUpdateEffect } from '@leaf/hooks'
import { time } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import {
  type CompanyQueryReturnType,
  companyDetailsQuery,
} from '@/features/company/company-details.api'
import {
  type UpdateCompanyByIdMutationVariables,
  useGetActiveAdminsQuery,
  useUpdateCompanyByIdMutation,
} from '@/features/company/company-details.api.generated'
import { CompanyAvatarUpload } from '@/features/company/company-details-avatar-upload'
import { CompanyDeleteDialog } from '@/features/company/company-details-delete-dialog'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const ShipperCard = () => {
  return (
    <Card>
      <CardHeader title='Shipper' />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CheckboxElement label='Trimble' name='useTrimbleIntegration' />
          </Grid>
          <Grid item xs={12}>
            <CheckboxElement label='FourKites' name='use4kitesIntegration' />
          </Grid>

          <Grid item xs={12}>
            <DataRowSection label='Adapt' />
          </Grid>

          <Grid item xs={12}>
            <CheckboxElement label='Adapt' name='hasAdapt' />
          </Grid>

          <Grid item xs={12}>
            <DatePickerElement
              inputProps={{
                fullWidth: true,
                variant: 'standard',
              }}
              label='Run Date Overwrite'
              name='runDate'
            />
          </Grid>

          <Grid item xs={12}>
            <DatePickerElement
              inputProps={{
                fullWidth: true,
                variant: 'standard',
              }}
              label='Window Start Date Overwrite'
              name='windowStartDate'
            />
          </Grid>

          <Grid item xs={12}>
            <DatePickerElement
              inputProps={{
                fullWidth: true,
                variant: 'standard',
              }}
              label='Window End Date Overwrite'
              name='windowEndDate'
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const CarrierCard = () => {
  return (
    <Card>
      <CardHeader title='Carrier' />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextFieldElement fullWidth label='MC' name='usMcNumber' variant='standard' />
          </Grid>

          <Grid item xs={12}>
            <TextFieldElement fullWidth label='DOT' name='usDotNumber' variant='standard' />
          </Grid>

          <Grid item xs={12}>
            <TextFieldElement fullWidth label='SCAC' name='scacCode' variant='standard' />
          </Grid>

          <Grid item xs={12}>
            <CheckboxElement label='Project44' name='useProject44Integration' />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const schema = z.object({
  // Common
  accountTeamMembers: v.__selects,
  businessUnits: v.__selects,
  companyType: v._string,
  // Adapt
  hasAdapt: v.__boolean,
  name: v._string,
  phone: v.__string,
  registeredName: v.__string,
  registeredState: v.__select,
  runDate: v.__date,
  // Carrier
  scacCode: v.__string,
  tenderEmailAddress: v.__string,
  tenderPhoneNumber: v.__string,
  transactionFee: v.__percentage,
  usDotNumber: v.__string,
  usMcNumber: v.__string,
  // Shipper
  use4kitesIntegration: v.__boolean,
  useProject44Integration: v.__boolean,
  useTrimbleIntegration: v.__boolean,
  windowEndDate: v.__date,
  windowStartDate: v.__date,
})
type Schema = z.infer<typeof schema>

const CompanyDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const { data } = useQuery(companyDetailsQuery(id!))
  const company = data as CompanyQueryReturnType

  const { data: admins } = useGetActiveAdminsQuery(
    graphqlClient,
    {},
    {
      select: ({ users }) =>
        users.map((user) => ({
          id: user.id,
          label: user.email,
        })),
    },
  )
  const { mutate: update } = useUpdateCompanyByIdMutation(graphqlClient, {
    onSuccess: () => addSnackbar({ message: 'Company updated successfully' }),
  })

  let registeredState
  // Format might be old or new, let's figure it out
  const currentRegisteredState = company.registeredState
  if (currentRegisteredState) {
    const state = States[currentRegisteredState as StatesKey]
    registeredState = {
      id: currentRegisteredState,
      // First try to retrieve it from the states, this works for the new format else just take the old one
      label: state ? state.text : currentRegisteredState,
    }
  }

  const defaultValues = {
    accountTeamMembers: company.accountTeamMembers.map((accountTeamMember) => ({
      id: accountTeamMember.user.id,
      label: accountTeamMember.user.email,
    })),
    businessUnits: company.businessUnits.map((businessUnit) => ({
      id: businessUnit.id,
      label: businessUnit.name,
    })),
    companyType: company.companyType,
    hasAdapt: company.hasAdapt,
    name: company.name,
    phone: company.phone,
    registeredName: company.registeredName,
    registeredState,
    runDate: company.runDate,
    scacCode: company.scacCode,
    tenderEmailAddress: company.tenderEmailAddress,
    tenderPhoneNumber: company.tenderPhoneNumber,
    transactionFee: company.transactionFee,
    usDotNumber: company.usDotNumber,
    usMcNumber: company.usMcNumber,
    use4kitesIntegration: company.use4kitesIntegration,
    useProject44Integration: company.useProject44Integration,
    useTrimbleIntegration: company.useTrimbleIntegration,
    windowEndDate: company.windowEndDate,
    windowStartDate: company.windowStartDate,
  }
  const context = useForm<Schema>({
    defaultValues,
    mode: 'all',
    resolver: zr(schema),
  })

  const toPayload = (values: Schema, companyId: string): UpdateCompanyByIdMutationVariables => {
    const [_company, _adapt, _accountTeamMembers] = (({
      accountTeamMembers,
      businessUnits,
      hasAdapt,
      runDate,
      windowEndDate,
      windowStartDate,
      ...rest
    }) => {
      const companyPayload = {
        ...rest,
        registeredState: rest.registeredState?.id,
      }
      const accountTeamMembersPayload =
        values.accountTeamMembers?.map((accountTeamMember) => ({
          companyId,
          userId: accountTeamMember.id,
        })) || []
      const adaptPayload = {
        companyId,
        hasAdapt: values.hasAdapt,
        runDate: values.runDate,
        windowEndDate: values.windowEndDate,
        windowStartDate: values.windowStartDate,
      }
      return [companyPayload, adaptPayload, accountTeamMembersPayload]
    })(values)

    const payload = {
      accountTeamMembers: _accountTeamMembers,
      adapt: _adapt,
      company: _company,
      id: companyId,
    }
    return payload
  }
  const onSubmit = (values: Schema) => {
    const payload = toPayload(values, company.id)
    update(clean(payload))
  }

  const companyType = useWatch({ control: context.control, name: 'companyType' })
  const isShipper = companyType === CompanyTypes.SHIPPER
  const SpecificCard = isShipper ? ShipperCard : CarrierCard

  useUpdateEffect(() => {
    if (isShipper) {
      context.setValue('scacCode', undefined)
      context.setValue('usDotNumber', undefined)
      context.setValue('usMcNumber', undefined)
      context.setValue('useProject44Integration', undefined)
    } else {
      context.setValue('hasAdapt', undefined)
      context.setValue('runDate', undefined)
      context.setValue('windowEndDate', undefined)
      context.setValue('windowStartDate', undefined)
      context.setValue('use4kitesIntegration', undefined)
      context.setValue('useTrimbleIntegration', undefined)
    }
  }, [isShipper])

  const toggleDeleteDialog = () => setShowDeleteDialog(!showDeleteDialog)

  const onDeleteSuccess = () => {
    toggleDeleteDialog()
    navigate('/companies')
    addSnackbar({ message: `Company '${data?.name}' deleted successfully` })
  }

  useTitles([{ to: 'companies', value: 'Companies' }, { value: company.id }])

  useActions([
    <Button
      color='primary'
      form='company-details-form'
      key='saveCompany'
      sx={{ marginRight: '1em' }}
      type='submit'
      variant='outlined'
    >
      Save
    </Button>,

    <Button
      color='error'
      form='company-details-form'
      key='deleteCompany'
      onClick={toggleDeleteDialog}
      variant='contained'
    >
      Delete
    </Button>,
  ])

  return (
    <>
      <FormContainer
        FormProps={{ id: 'company-details-form' }}
        formContext={context}
        onSuccess={onSubmit}
      >
        <Progress />

        <Page>
          <Grid item xs={4}>
            <Card>
              <CardHeader title='Company' />

              <CardContent>
                <Grid container item spacing={2} xs={12}>
                  <Grid container item sx={{ justifyContent: 'center' }} xs={12}>
                    <CompanyAvatarUpload id={company.id} name={company.name} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldElement
                      fullWidth
                      label='Name'
                      name='name'
                      required
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SelectElement
                      fullWidth
                      label='Type'
                      name='companyType'
                      options={CompanyTypesSelect}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldElement
                      fullWidth
                      label='Registered Name'
                      name='registeredName'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteElement
                      label='Registered state'
                      name='registeredState'
                      options={StatesSelect}
                      textFieldProps={{ fullWidth: true, variant: 'standard' }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldElement
                      InputProps={{
                        inputProps: { max: 1, min: 0, step: 0.05 },
                      }}
                      fullWidth
                      label='Transaction Fee [%]'
                      name='transactionFee'
                      type='number'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteElement
                      label='Account Team Members'
                      multiple
                      name='accountTeamMembers'
                      options={admins || []}
                      textFieldProps={{ fullWidth: true, variant: 'standard' }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteElement
                      autocompleteProps={{ disabled: true, freeSolo: true }}
                      label='Business Units'
                      multiple
                      name='businessUnits'
                      options={[]}
                      textFieldProps={{ fullWidth: true, variant: 'standard' }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardHeader title='Contact' />

              <CardContent>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <TextFieldElement fullWidth label='Phone' name='phone' variant='standard' />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldElement
                      fullWidth
                      label='Tender Phone'
                      name='tenderPhoneNumber'
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldElement
                      fullWidth
                      label='Tender Email'
                      name='tenderEmailAddress'
                      variant='standard'
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{ marginTop: '1rem' }}>
              <CardHeader title='Meta' />

              <CardContent>
                <DataRow label='Created' value={time.toLocalDate(company.createdAt)} />

                <DataRow label='Created By' value={company.createdByUser?.email} />

                <DataRow label='Updated' value={time.toLocalDate(company.updatedAt)} />

                <DataRow label='Updated By' value={company.updatedByUser?.email} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <SpecificCard />
          </Grid>
        </Page>
      </FormContainer>

      <CompanyDeleteDialog
        id={id!}
        onClose={toggleDeleteDialog}
        onSuccess={onDeleteSuccess}
        open={showDeleteDialog}
      />
    </>
  )
}

export { CompanyDetails }
