/* eslint-disable perfectionist/sort-objects */

import { clean, select } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import { parseVolumePattern } from '@/features/contract/contract.mapper'
import {
  GetContractDocument,
  type GetContractQuery,
} from '@/features/contract/contract-details.api.generated'

type ContractRouteType = {
  isPrimary?: boolean
  route: {
    id: any
    legs: {
      lane: {
        destinationName: string
        id: any
        leafMiles?: number
        originName: string
        path: any
        shipper: { id: any; name: string }
      }
    }[]
    tripType: string
  }
  routeId: any
}

const contractDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const contractResponse = await graphqlClient.request(GetContractDocument, { id })
    const data = clean(contractResponse) as Types.NoUndefineds<GetContractQuery>
    const contract = (data.contractsByPk || {}) as Types.NoUndefined<
      GetContractQuery['contractsByPk']
    >

    const dailies = parseVolumePattern(contract.volumePattern)

    const sortedContractRoutes = contract.contractRoutes.sort(
      (a, b) => Number(b.isPrimary) - Number(a.isPrimary),
    )

    const domicile = contract.domiciles[0]
    let domicileName
    let domicileAddress

    if (domicile) {
      domicileName = domicile.name

      domicileAddress = {
        address: domicile.street,
        city: domicile.city,
        state: domicile.state,
        zipCode: domicile.zip,
        latitude: domicile.geo.coordinates[0],
        longitude: domicile.geo.coordinates[1],
      }
    }

    const formValues = {
      id: contract.id,
      accessorials: select.fromResponses(contract.contractAccessorials.map((ca) => ca.accessorial)),
      backupAward: !!contract.isBackupAward,
      contractRoutes: sortedContractRoutes as unknown as ContractRouteType[],
      company: select.fromResponse(contract.buyer ?? contract.seller),
      domicileAddress,
      domicileName,
      endDate: contract.endDate,
      equipmentType: select.fromResponse(contract.equipmentType),
      fleet: !!contract.isFleet,
      includeWeekends: !!contract.includeWeekends,
      maxVolume: contract.maxVolume,
      minVolume: contract.minVolume,
      notes: contract.notes,
      planId: contract.shipperRoutePlans[0]?.shipperRoutePlanId,
      startDate: contract.startDate,
      status: contract.contractStatus,
      type: contract.buyer ? 'SHIPPER' : 'CARRIER',
      volumeFrequency: contract.volumeFrequency,
      volumeType: contract.volumeType,
      su: dailies.SUNDAY,
      mo: dailies.MONDAY,
      tu: dailies.TUESDAY,
      we: dailies.WEDNESDAY,
      th: dailies.THURSDAY,
      fr: dailies.FRIDAY,
      sa: dailies.SATURDAY,
      pricingMechanism: contract.pricingMechanism,
      carrierUpcharge: contract.carrierUpcharge,
      fixedFeePerShipment: contract.fixedFeePerShipment,
      linehaulRpm: contract.linehaulRpm,
      minLinehaulCharge: contract.minimumLinehaulCharge,
      pricingIndex: contract.pricingIndex,
      pricingIndexPremium: contract.pricingIndexPremium,
      pricingIndexPremiumOverrideSunday: contract.pricingIndexPremiumOverrideSun,
      minTenderLeadTime: contract.minTenderLeadTimeHrs,
      assetPricing: contract.assetPricing,
      assetPricingFrequency: contract.assetPricingFrequency,
      minChargeableMiles: contract.minChargeableMiles,
      minChargeableMilesFrequency: contract.minChargeableMilesFrequency,
      fuelSurchargeSchedule: select.fromResponse(contract.fuelSurchargeSchedule),
      allIn: !contract.fuelSurchargeSchedule,
      useBtf: !!contract.useBtf,
    }
    return formValues
  },
  queryKey: [`contract-details-${id}`],
})

export { contractDetailsQuery }
export type { ContractRouteType }
