// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetUserByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type GetUserByIdQuery = { __typename?: 'query_root', usersByPk?: { __typename?: 'users', id: any, firstName?: string, lastName?: string, email: string, phoneNumber?: string, role: string, defaultApp?: string, active: boolean, loginCount?: number, lastLoginAt?: any, createdAt: any, updatedAt: any, company?: { __typename?: 'companies', id: any, name: string }, businessUnit?: { __typename?: 'IdmBusinessUnit', id: any, name: string } } };



export const GetUserByIdDocument = `
    query GetUserById($id: bigint!) {
  usersByPk(id: $id) {
    id
    firstName
    lastName
    email
    phoneNumber
    company {
      id
      name
    }
    businessUnit {
      id
      name
    }
    role
    defaultApp
    active
    loginCount
    lastLoginAt
    createdAt
    updatedAt
  }
}
    `;

export const useGetUserByIdQuery = <
      TData = GetUserByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserByIdQueryVariables,
      options?: UseQueryOptions<GetUserByIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserByIdQuery, TError, TData>(
      ['GetUserById', variables],
      fetcher<GetUserByIdQuery, GetUserByIdQueryVariables>(client, GetUserByIdDocument, variables, headers),
      options
    )};

useGetUserByIdQuery.document = GetUserByIdDocument;

useGetUserByIdQuery.getKey = (variables: GetUserByIdQueryVariables) => ['GetUserById', variables];


useGetUserByIdQuery.fetcher = (client: GraphQLClient, variables: GetUserByIdQueryVariables, headers?: RequestInit['headers']) => fetcher<GetUserByIdQuery, GetUserByIdQueryVariables>(client, GetUserByIdDocument, variables, headers);
