// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetFuelPriceIndexOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFuelPriceIndexOverviewQuery = { __typename?: 'query_root', fuelPriceIndexes: Array<{ __typename?: 'fuelPriceIndexes', id: any, name: string, providerName: string, seriesId: string, states?: string, hierarchyLevel?: number, createdAt: any, createdByUser?: { __typename?: 'users', id: any, email: string } }> };



export const GetFuelPriceIndexOverviewDocument = `
    query GetFuelPriceIndexOverview @cached(ttl: 300) {
  fuelPriceIndexes(orderBy: {createdAt: DESC}) {
    id
    name
    providerName
    seriesId
    states
    hierarchyLevel
    createdAt
    createdByUser {
      id
      email
    }
  }
}
    `;

export const useGetFuelPriceIndexOverviewQuery = <
      TData = GetFuelPriceIndexOverviewQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetFuelPriceIndexOverviewQueryVariables,
      options?: UseQueryOptions<GetFuelPriceIndexOverviewQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFuelPriceIndexOverviewQuery, TError, TData>(
      variables === undefined ? ['GetFuelPriceIndexOverview'] : ['GetFuelPriceIndexOverview', variables],
      fetcher<GetFuelPriceIndexOverviewQuery, GetFuelPriceIndexOverviewQueryVariables>(client, GetFuelPriceIndexOverviewDocument, variables, headers),
      options
    )};

useGetFuelPriceIndexOverviewQuery.document = GetFuelPriceIndexOverviewDocument;

useGetFuelPriceIndexOverviewQuery.getKey = (variables?: GetFuelPriceIndexOverviewQueryVariables) => variables === undefined ? ['GetFuelPriceIndexOverview'] : ['GetFuelPriceIndexOverview', variables];


useGetFuelPriceIndexOverviewQuery.fetcher = (client: GraphQLClient, variables?: GetFuelPriceIndexOverviewQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFuelPriceIndexOverviewQuery, GetFuelPriceIndexOverviewQueryVariables>(client, GetFuelPriceIndexOverviewDocument, variables, headers);
