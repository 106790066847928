import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { userDetailsQuery } from '@/features/user/user-details.api'

const userDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = userDetailsQuery(params.id!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { userDetailsLoader }
