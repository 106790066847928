// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetShipperNetworkQueryVariables = Types.Exact<{
  shipperIds?: Types.InputMaybe<Array<Types.Scalars['bigint']['input']> | Types.Scalars['bigint']['input']>;
}>;


export type GetShipperNetworkQuery = { __typename?: 'query_root', shipperNetworks: Array<{ __typename?: 'shipperNetworks', shipperId?: any, network?: any }> };

export type GetLeafNetworkQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLeafNetworkQuery = { __typename?: 'query_root', leafNetwork: Array<{ __typename?: 'leafNetwork', geometry: any }> };



export const GetShipperNetworkDocument = `
    query GetShipperNetwork($shipperIds: [bigint!]) {
  shipperNetworks(where: {shipperId: {_in: $shipperIds}}) {
    shipperId
    network
  }
}
    `;

export const useGetShipperNetworkQuery = <
      TData = GetShipperNetworkQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetShipperNetworkQueryVariables,
      options?: UseQueryOptions<GetShipperNetworkQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetShipperNetworkQuery, TError, TData>(
      variables === undefined ? ['GetShipperNetwork'] : ['GetShipperNetwork', variables],
      fetcher<GetShipperNetworkQuery, GetShipperNetworkQueryVariables>(client, GetShipperNetworkDocument, variables, headers),
      options
    )};

useGetShipperNetworkQuery.document = GetShipperNetworkDocument;

useGetShipperNetworkQuery.getKey = (variables?: GetShipperNetworkQueryVariables) => variables === undefined ? ['GetShipperNetwork'] : ['GetShipperNetwork', variables];


useGetShipperNetworkQuery.fetcher = (client: GraphQLClient, variables?: GetShipperNetworkQueryVariables, headers?: RequestInit['headers']) => fetcher<GetShipperNetworkQuery, GetShipperNetworkQueryVariables>(client, GetShipperNetworkDocument, variables, headers);

export const GetLeafNetworkDocument = `
    query GetLeafNetwork {
  leafNetwork(orderBy: {id: DESC}, limit: 1) {
    geometry
  }
}
    `;

export const useGetLeafNetworkQuery = <
      TData = GetLeafNetworkQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetLeafNetworkQueryVariables,
      options?: UseQueryOptions<GetLeafNetworkQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLeafNetworkQuery, TError, TData>(
      variables === undefined ? ['GetLeafNetwork'] : ['GetLeafNetwork', variables],
      fetcher<GetLeafNetworkQuery, GetLeafNetworkQueryVariables>(client, GetLeafNetworkDocument, variables, headers),
      options
    )};

useGetLeafNetworkQuery.document = GetLeafNetworkDocument;

useGetLeafNetworkQuery.getKey = (variables?: GetLeafNetworkQueryVariables) => variables === undefined ? ['GetLeafNetwork'] : ['GetLeafNetwork', variables];


useGetLeafNetworkQuery.fetcher = (client: GraphQLClient, variables?: GetLeafNetworkQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLeafNetworkQuery, GetLeafNetworkQueryVariables>(client, GetLeafNetworkDocument, variables, headers);
