import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetFuelSurchargeScheduleDocument,
  type GetFuelSurchargeScheduleQuery,
} from '@/features/fuel-surcharge-schedule/fuel-surcharge-schedule-details.api.generated'

type FuelSurchargeScheduleQueryReturnType = Types.NoUndefined<
  GetFuelSurchargeScheduleQuery['fuelSurchargeSchedulesByPk']
>

const fuelSurchargeScheduleDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const fuelSurchargeScheduleResponse = await graphqlClient.request(
      GetFuelSurchargeScheduleDocument,
      { id },
    )
    const data = clean(fuelSurchargeScheduleResponse) as GetFuelSurchargeScheduleQuery
    const fuelSurchargeSchedule = (data.fuelSurchargeSchedulesByPk ||
      {}) as FuelSurchargeScheduleQueryReturnType
    return fuelSurchargeSchedule
  },
  queryKey: [`fuel-surcharge-schedule-details-${id}`],
})

export { fuelSurchargeScheduleDetailsQuery }
export type { FuelSurchargeScheduleQueryReturnType }
