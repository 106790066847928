import { type ReactNode } from 'react'

import { Box } from '@mui/material'
import type { GridRowModel } from '@mui/x-data-grid-pro'
import { get } from 'lodash-es'
import { useShallow } from 'zustand/react/shallow'

import { Wrap } from '@leaf/components'
import { EquipmentClasses } from '@leaf/constants'
import { AsyncTable, Columns } from '@leaf/table'

import { graphqlClient } from '@/api'
import {
  type GetAdaptNetworkMovesQuery,
  useGetAdaptNetworkMovesQuery,
} from '@/features/adapt-network-move/adapt-network-move-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const renderMultiLine =
  (field: string | string[], isNumber: boolean = false) =>
  ({ row }: GridRowModel) => {
    const data: (ReactNode | string)[] = []
    const hasLane = [!!row.lane0, !!row.lane1, !!row.lane2, !!row.lane3, !!row.lane4]
    if (Array.isArray(field)) {
      field.forEach((f, i) => {
        if (!hasLane[i]) {
          return null
        }
        if (i > 0) {
          data.push(<br />)
        }
        data.push(row[f]?.toString() ?? '-')
      })
    } else {
      const { lane0, lane1, lane2, lane3, lane4 } = row
      data.push(get(lane0, field) ?? '-')
      data.push(<br />)
      data.push(get(lane1, field) ?? '-')

      if (lane2) {
        data.push(<br />)
        data.push(get(lane2, field) ?? '-')

        if (lane3) {
          data.push(<br />)
          data.push(get(lane3, field) ?? '-')
        }

        if (lane4) {
          data.push(<br />)
          data.push(get(lane4, field) ?? '-')
        }
      }
    }
    if (isNumber) {
      return <Box sx={{ textAlign: 'right' }}>{data}</Box>
    }
    return <Wrap>{data}</Wrap>
  }

// TODO: LEAF-5120 Implement export for server-side tables
const AdaptNetworkMoveOverview = () => {
  const [changeTable, savedState] = useStore(
    useShallow((state) => [state.changeTable, state.adaptNetworkMoveOverview]),
  )

  useTitles([{ value: 'Adapt Network Moves' }])

  const columns = [
    Columns.Action(),
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 300,
    },
    {
      field: 'networkType',
      headerName: 'Network Type',
    },
    {
      field: 'moveType',
      headerName: 'Move Type',
    },
    {
      field: '_companies',
      headerName: 'Companies',
      minWidth: 200,
      renderCell: renderMultiLine('company.name'),
      sortable: false,
    },
    {
      field: '_origins',
      headerName: 'Origins',
      minWidth: 250,
      renderCell: renderMultiLine('originLocation.name'),
      sortable: false,
    },
    {
      field: '_destinations',
      headerName: 'Destinations',
      minWidth: 250,
      renderCell: renderMultiLine('destinationLocation.name'),
      sortable: false,
    },
    {
      field: '_leafMiles',
      filterable: false,
      headerName: 'Leaf Miles',
      renderCell: renderMultiLine('leafMiles', true),
      sortable: false,
      type: 'number',
    },
    {
      field: '_companyMiles',
      filterable: false,
      headerName: 'Company Miles',
      minWidth: 125,
      renderCell: renderMultiLine('companyMiles', true),
      sortable: false,
      type: 'number',
    },
    {
      field: '_deadheads',
      filterable: false,
      headerName: 'Deadhead Miles',
      minWidth: 125,
      renderCell: renderMultiLine(
        ['deadheadL0L1', 'deadheadL1L2', 'deadheadL2L3', 'deadheadL3L4', 'deadheadFinal'],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: '_annualLoads',
      filterable: false,
      headerName: 'Loads',
      renderCell: renderMultiLine(
        ['l0AnnualLoads', 'l1AnnualLoads', 'l2AnnualLoads', 'l3AnnualLoads', 'l4AnnualLoads'],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: '_dryLoads',
      filterable: false,
      headerName: 'Dry Loads',
      renderCell: renderMultiLine(
        [
          'l0DryLoadsAnnual',
          'l1DryLoadsAnnual',
          'l2DryLoadsAnnual',
          'l3DryLoadsAnnual',
          'l4DryLoadsAnnual',
        ],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: '_reeferLoads',
      filterable: false,
      headerName: 'Reefer Loads',
      renderCell: renderMultiLine(
        [
          'l0ReeferLoadsAnnual',
          'l1ReeferLoadsAnnual',
          'l2ReeferLoadsAnnual',
          'l3ReeferLoadsAnnual',
          'l4ReeferLoadsAnnual',
        ],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: '_weeklyBase',
      filterable: false,
      headerName: 'Weekly Base',
      minWidth: 200,
      renderCell: renderMultiLine([
        'l0WeeklyBase',
        'l1WeeklyBase',
        'l2WeeklyBase',
        'l3WeeklyBase',
        'l4WeeklyBase',
      ]),
      sortable: false,
    },
    {
      field: '_meanRpms',
      filterable: false,
      headerName: 'Mean RPMs',
      renderCell: renderMultiLine(
        ['l0MeanRpm', 'l1MeanRpm', 'l2MeanRpm', 'l3MeanRpm', 'l4MeanRpm'],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: '_outlierRpms',
      filterable: false,
      headerName: 'Outlier RPM',
      renderCell: renderMultiLine([
        'l0OutlierRpm',
        'l1OutlierRpm',
        'l2OutlierRpm',
        'l3OutlierRpm',
        'l4OutlierRpm',
      ]),
      sortable: false,
      type: 'boolean',
    },
    {
      field: '_lowLegs',
      filterable: false,
      headerName: 'Low',
      renderCell: renderMultiLine(['l0Low', 'l1Low', 'l2Low', 'l3Low', 'l4Low']),
      sortable: false,
      type: 'boolean',
    },
    {
      field: '_balanceAdjs',
      filterable: false,
      headerName: 'Balance Adjustments',
      renderCell: renderMultiLine(
        ['l0BalanceAdj', 'l1BalanceAdj', 'l2BalanceAdj', 'l3BalanceAdj', 'l4BalanceAdj'],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: '_oMarkets',
      filterable: false,
      headerName: 'Origin Markets',
      renderCell: renderMultiLine([
        'l0OMarket',
        'l1OMarket',
        'l2OMarket',
        'l3OMarket',
        'l4OMarket',
      ]),
      sortable: false,
    },
    {
      field: '_dMarkets',
      filterable: false,
      headerName: 'Destination Markets',
      renderCell: renderMultiLine([
        'l0DMarket',
        'l1DMarket',
        'l2DMarket',
        'l3DMarket',
        'l4DMarket',
      ]),
      sortable: false,
    },
    {
      field: '_equipmentClasses',
      filterable: false,
      headerName: 'Equipment Classes',
      minWidth: 125,
      renderCell: renderMultiLine([
        'l0EquipmentClass',
        'l1EquipmentClass',
        'l2EquipmentClass',
        'l3EquipmentClass',
        'l4EquipmentClass',
      ]),
      sortable: false,
    },
    {
      field: '_networkTypes',
      filterable: false,
      headerName: 'Network Types',
      renderCell: renderMultiLine([
        'l0NetworkType',
        'l1NetworkType',
        'l2NetworkType',
        'l3NetworkType',
        'l4NetworkType',
      ]),
      sortable: false,
    },
    {
      field: '_datouts',
      filterable: false,
      headerName: 'DAT 90D LH/Outs',
      renderCell: renderMultiLine(
        [
          'l0Dat90DayLhOut',
          'l1Dat90DayLhOut',
          'l2Dat90DayLhOut',
          'l3Dat90DayLhOut',
          'l4Dat90DayLhOut',
        ],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: '_datreturns',
      filterable: false,
      headerName: 'DAT 90D LH/Returns',
      renderCell: renderMultiLine(
        [
          'l0Dat90DayLhReturn',
          'l1Dat90DayLhReturn',
          'l2Dat90DayLhReturn',
          'l3Dat90DayLhReturn',
          'l4Dat90DayLhReturn',
        ],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: 'batchDate',
      headerName: 'Batch Date',
      type: 'date',
    },
    {
      field: 'margin',
      headerName: 'Margin',
      type: 'number',
    },
    {
      field: 'benefit',
      headerName: 'Benefit',
      type: 'number',
    },
    {
      field: 'totalMiles',
      headerName: 'Total Miles',
      type: 'number',
    },
    {
      field: 'equipmentClass',
      headerName: 'Equipment Class',
      minWidth: 125,
      type: 'singleSelect',
      valueOptions: [EquipmentClasses.DRY, EquipmentClasses.REEFER],
    },
    {
      field: 'oMarket',
      headerName: 'Origin Market',
    },
    {
      field: 'dMarket',
      headerName: 'Destination Market',
    },
    {
      field: 'days',
      headerName: 'Days',
      type: 'number',
    },
    {
      field: 'lowLegs',
      headerName: 'Low Legs',
      type: 'number',
    },
    {
      field: 'leafDailyLinehaulLsp',
      headerName: 'Leaf Daily Linehaul LSP',
      minWidth: 200,
      type: 'number',
    },
    {
      field: 'meanHistLinehaulLsp',
      headerName: 'Mean Historical Linehaul LSP',
      minWidth: 200,
      type: 'number',
    },
    {
      field: 'shipperTriggerLinehaulLsp',
      headerName: 'Shipper Trigger Linehaul LSP',
      minWidth: 200,
      type: 'number',
    },
    {
      field: 'supplyTargetDayRateLsp',
      headerName: 'Supply Target Day Rate LSP',
      minWidth: 200,
      type: 'number',
    },
    {
      field: 'supplyTargetLinehaulLsp',
      headerName: 'Supply Target Linehaul LSP',
      minWidth: 200,
      type: 'number',
    },
    {
      field: 'dat90DayLhOut',
      headerName: 'DAT 90D LH/Out',
      type: 'number',
    },
    {
      field: 'dat90DayLhReturn',
      headerName: 'DAT 90D LH/Return',
      type: 'number',
    },
    {
      field: 'balanceType',
      headerName: 'Balance Type',
      minWidth: 200,
    },
    {
      field: 'balanceAdj',
      headerName: 'Balance Adjustment',
      type: 'number',
    },
    {
      field: 'complexity',
      headerName: 'Complexity',
      type: 'number',
    },
    {
      field: 'recordType',
      headerName: 'Record Type',
    },
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        company: false,
        id: true,
      },
    },
  }

  return (
    <AsyncTable
      // @ts-expect-error
      columns={columns}
      filters={{
        computed: {
          _companies: [
            'lane0.company.name',
            'lane1.company.name',
            'lane2.company.name',
            'lane3.company.name',
            'lane4.company.name',
          ],
          _destinations: [
            'lane0.destinationLocation.name',
            'lane1.destinationLocation.name',
            'lane2.destinationLocation.name',
            'lane3.destinationLocation.name',
            'lane4.destinationLocation.name',
          ],
          _origins: [
            'lane0.originLocation.name',
            'lane1.originLocation.name',
            'lane2.originLocation.name',
            'lane3.originLocation.name',
            'lane4.originLocation.name',
          ],
        },
      }}
      initialState={initialState}
      name='adaptNetworkMoveOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      query={{
        client: graphqlClient,
        fn: useGetAdaptNetworkMovesQuery,
        select: (response: GetAdaptNetworkMovesQuery) => ({
          count: response?.analyticsNetworkMovesAggregate.aggregate?.count,
          rows: response?.analyticsNetworkMoves,
        }),
      }}
    />
  )
}

export { AdaptNetworkMoveOverview }
