// @ts-nocheck
/* eslint-disable */
import type * as Types from './hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetEquipmentTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEquipmentTypesQuery = { __typename?: 'query_root', equipmentTypes: Array<{ __typename?: 'equipmentTypes', id: any, name: string }> };



export const GetEquipmentTypesDocument = `
    query GetEquipmentTypes @cached(ttl: 300) {
  equipmentTypes(orderBy: {name: ASC}) {
    id
    name
  }
}
    `;

export const useGetEquipmentTypesQuery = <
      TData = GetEquipmentTypesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetEquipmentTypesQueryVariables,
      options?: UseQueryOptions<GetEquipmentTypesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetEquipmentTypesQuery, TError, TData>(
      variables === undefined ? ['GetEquipmentTypes'] : ['GetEquipmentTypes', variables],
      fetcher<GetEquipmentTypesQuery, GetEquipmentTypesQueryVariables>(client, GetEquipmentTypesDocument, variables, headers),
      options
    )};

useGetEquipmentTypesQuery.document = GetEquipmentTypesDocument;

useGetEquipmentTypesQuery.getKey = (variables?: GetEquipmentTypesQueryVariables) => variables === undefined ? ['GetEquipmentTypes'] : ['GetEquipmentTypes', variables];


useGetEquipmentTypesQuery.fetcher = (client: GraphQLClient, variables?: GetEquipmentTypesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetEquipmentTypesQuery, GetEquipmentTypesQueryVariables>(client, GetEquipmentTypesDocument, variables, headers);
