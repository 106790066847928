// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetAdaptLaneSummariesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAdaptLaneSummariesQuery = { __typename?: 'query_root', analyticsAdaptLaneSummaries: Array<{ __typename?: 'analyticsAdaptLaneSummaries', id?: any, shipperId?: any, shipperName?: string, equipmentClass?: string, batchDate?: any, originName?: string, destinationName?: string, annualizedLinehaulZeroPeg?: any, annualizedShipments?: any, annualizedSpend?: any, benefit?: any, leafMiles?: any, linehaulZeroPeg?: any, maxDate?: any, minDate?: any, observations?: Array<string>, shipperMiles?: any, totalShipments?: any, createdAt?: any, updatedAt?: any }> };



export const GetAdaptLaneSummariesDocument = `
    query GetAdaptLaneSummaries @cached(ttl: 300) {
  analyticsAdaptLaneSummaries(orderBy: {createdAt: DESC}) {
    id
    shipperId
    shipperName
    equipmentClass
    batchDate
    originName
    destinationName
    annualizedLinehaulZeroPeg
    annualizedShipments
    annualizedSpend
    benefit
    leafMiles
    linehaulZeroPeg
    maxDate
    minDate
    observations
    shipperMiles
    totalShipments
    createdAt
    updatedAt
  }
}
    `;

export const useGetAdaptLaneSummariesQuery = <
      TData = GetAdaptLaneSummariesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetAdaptLaneSummariesQueryVariables,
      options?: UseQueryOptions<GetAdaptLaneSummariesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAdaptLaneSummariesQuery, TError, TData>(
      variables === undefined ? ['GetAdaptLaneSummaries'] : ['GetAdaptLaneSummaries', variables],
      fetcher<GetAdaptLaneSummariesQuery, GetAdaptLaneSummariesQueryVariables>(client, GetAdaptLaneSummariesDocument, variables, headers),
      options
    )};

useGetAdaptLaneSummariesQuery.document = GetAdaptLaneSummariesDocument;

useGetAdaptLaneSummariesQuery.getKey = (variables?: GetAdaptLaneSummariesQueryVariables) => variables === undefined ? ['GetAdaptLaneSummaries'] : ['GetAdaptLaneSummaries', variables];


useGetAdaptLaneSummariesQuery.fetcher = (client: GraphQLClient, variables?: GetAdaptLaneSummariesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAdaptLaneSummariesQuery, GetAdaptLaneSummariesQueryVariables>(client, GetAdaptLaneSummariesDocument, variables, headers);
