// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetFuelPricesOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFuelPricesOverviewQuery = { __typename?: 'query_root', fuelPrices: Array<{ __typename?: 'fuelPrices', id: any, price: any, validFrom: any, createdAt: any, fuelPriceIndex: { __typename?: 'fuelPriceIndexes', id: any, name: string }, createdByUser?: { __typename?: 'users', id: any, email: string } }> };



export const GetFuelPricesOverviewDocument = `
    query GetFuelPricesOverview @cached(ttl: 300) {
  fuelPrices(orderBy: {validFrom: DESC}) {
    id
    price
    validFrom
    fuelPriceIndex {
      id
      name
    }
    createdAt
    createdByUser {
      id
      email
    }
  }
}
    `;

export const useGetFuelPricesOverviewQuery = <
      TData = GetFuelPricesOverviewQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetFuelPricesOverviewQueryVariables,
      options?: UseQueryOptions<GetFuelPricesOverviewQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFuelPricesOverviewQuery, TError, TData>(
      variables === undefined ? ['GetFuelPricesOverview'] : ['GetFuelPricesOverview', variables],
      fetcher<GetFuelPricesOverviewQuery, GetFuelPricesOverviewQueryVariables>(client, GetFuelPricesOverviewDocument, variables, headers),
      options
    )};

useGetFuelPricesOverviewQuery.document = GetFuelPricesOverviewDocument;

useGetFuelPricesOverviewQuery.getKey = (variables?: GetFuelPricesOverviewQueryVariables) => variables === undefined ? ['GetFuelPricesOverview'] : ['GetFuelPricesOverview', variables];


useGetFuelPricesOverviewQuery.fetcher = (client: GraphQLClient, variables?: GetFuelPricesOverviewQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFuelPricesOverviewQuery, GetFuelPricesOverviewQueryVariables>(client, GetFuelPricesOverviewDocument, variables, headers);
