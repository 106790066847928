import { type ChangeEvent, useCallback, useEffect, useState } from 'react'

import { HorizontalRule } from '@mui/icons-material'
import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import mapboxgl from 'mapbox-gl'

import { auth } from '@/api'
import { useTitles } from '@/hooks'

import 'mapbox-gl/dist/mapbox-gl.css'

const ContractNetwork = () => {
  const [loading, setLoading] = useState(true)
  const [map, setMap] = useState<mapboxgl.Map>()
  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const [layers, setLayers] = useState<string[]>([
    '/shipper/contracted-lanes-0',
    '/shipper/contracted-lanes-1',
    '/shipper/unmatched-loads',
  ])

  useTitles([{ value: 'Contract Network' }])

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const init = useCallback(async (map: mapboxgl.Map) => {
    const options = {
      headers: {
        'Authorization': `Bearer ${auth.token!}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }

    async function addSource(source: string) {
      return fetch(`https://api.leaflogistics.com/tendering/geojson${source}`, options)
        .then((resp) => resp.json())
        .then((data) => map?.addSource(source, { data, type: 'geojson' }))
    }

    await addSource('/shipper/contracted-lanes')
    await addSource('/shipper/unmatched-loads')

    map
      .addLayer({
        filter: ['>', ['get', 'loads'], 0],
        id: '/shipper/contracted-lanes-0',
        paint: {
          'line-color': '#00aa00',
          'line-width': 3,
        },
        source: '/shipper/contracted-lanes',
        type: 'line',
      })
      .addLayer({
        filter: ['==', ['get', 'loads'], 0],
        id: '/shipper/contracted-lanes-1',
        paint: {
          'line-color': '#cccc00',
          'line-width': 3,
        },
        source: '/shipper/contracted-lanes',
        type: 'line',
      })
      .addLayer({
        id: '/shipper/unmatched-loads',
        paint: {
          'line-color': '#ff0000',
          'line-dasharray': [2, 4],
          'line-opacity': 0.5,
          'line-width': 3,
        },
        source: '/shipper/unmatched-loads',
        type: 'line',
      })

    setLoading(false)
  }, [])

  useEffect(() => {
    if (!map) {
      mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_API_KEY

      const mapboxMap = new mapboxgl.Map({
        center: [-95.7, 37.1],
        container: 'mapbox',
        style: 'mapbox://styles/mapbox/streets-v12',
        zoom: 4,
      })

      const mapboxPopup = new mapboxgl.Popup({
        maxWidth: 'unset',
      })

      mapboxMap.on('mousemove', (e) => {
        if (!e) {
          return
        }
        const features = mapboxMap
          .queryRenderedFeatures(e.point)
          .filter((f) => f.properties?.loads >= 0)
          .map((f) => f.properties)
          // @ts-expect-error: Argument checking performed in filter
          .sort((a, b) => b.loads - a.loads)

        if (features.length === 0) {
          return
        }
        const featuresHtml = `
           <div>
             <div>
               <h3>Contracted Lanes</h3>
               <table>
                 <tr>
                   <th>Contract</th>
                   <th>Shipper</th>
                   <th>Origin</th>
                   <th>Destination</th>
                   <th>Loads</th>
                 </tr>
                 ${features
                   .filter((f) => f?.contract_id)
                   .map(
                     (f) => `
                     <tr>
                       <td><a href="https://admin.leaflogistics.com/contracts/${f?.contract_id}" target="_blank" rel="noopener noreferrer">${f?.contract_id}</a></td>
                       <td>${f?.shipper}</td>
                       <td>${f?.origin}</td>
                       <td>${f?.destination}</td>
                       <td>${f?.loads}</td>
                     </tr>
                   `,
                   )
                   .reduce((a, b) => a + b, '')}
               </table>
             </div>
             <div>
               <h3>Unmatched Loads</h3>
               <table>
                 <tr>
                   <th>Shipper</th>
                   <th>Origin</th>
                   <th>Destination</th>
                   <th>Loads</th>
                 </tr>
                 ${features
                   .filter((f) => !f?.contract_id)
                   .map(
                     (f) => `
                     <tr>
                       <td>${f?.shipper}</td>
                       <td>${f?.origin}</td>
                       <td>${f?.destination}</td>
                       <td>${f?.loads}</td>
                     </tr>
                   `,
                   )
                   .reduce((a, b) => a + b, '')}
               </table>
             </div>
           </div>
         `
        mapboxPopup.setLngLat(e.lngLat).setHTML(featuresHtml).addTo(mapboxMap)
      })
      init(mapboxMap)
      setMap(mapboxMap)
      setPopup(mapboxPopup)
    }
  }, [map, init])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    popup?.remove()
    map?.setFilter('/shipper/contracted-lanes-0', [
      'all',
      ['>', ['get', 'loads'], 0],
      [
        'in',
        ['downcase', ['literal', e.target.value ?? '']],
        ['downcase', ['to-string', ['properties']]],
      ],
    ])
    map?.setFilter('/shipper/contracted-lanes-1', [
      'all',
      ['==', ['get', 'loads'], 0],
      [
        'in',
        ['downcase', ['literal', e.target.value ?? '']],
        ['downcase', ['to-string', ['properties']]],
      ],
    ])
    map?.setFilter('/shipper/unmatched-loads', [
      'in',
      ['downcase', ['literal', e.target.value ?? '']],
      ['downcase', ['to-string', ['properties']]],
    ])
  }

  const handleLayerToggle = (_event: React.MouseEvent<HTMLElement>, newLayers: string[]) => {
    popup?.remove()
    ;[
      '/shipper/contracted-lanes-0',
      '/shipper/contracted-lanes-1',
      '/shipper/unmatched-loads',
    ].forEach((layer: string) =>
      map?.setLayoutProperty(layer, 'visibility', newLayers.includes(layer) ? 'visible' : 'none'),
    )
    setLayers(newLayers)
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Backdrop
        open={loading}
        sx={{ color: '#fff', position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      <Box
        id='mapbox'
        sx={{
          border: '0',
          height: '100vh',
          width: '100%',
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          zIndex: '1',
        }}
      >
        <Stack alignItems='flex-end'>
          <TextField
            id='mapbox-search'
            name='search'
            onChange={handleSearch}
            placeholder='Search'
            size='small'
            sx={{
              backgroundColor: 'white',
            }}
          />
          <ToggleButtonGroup id='mapbox-layer-toggle' onChange={handleLayerToggle} value={layers}>
            <ToggleButton value='/shipper/contracted-lanes-0'>
              <HorizontalRule style={{ color: '#00aa00' }} />
            </ToggleButton>
            <ToggleButton value='/shipper/contracted-lanes-1'>
              <HorizontalRule style={{ color: '#cccc00' }} />
            </ToggleButton>
            <ToggleButton value='/shipper/unmatched-loads'>
              <HorizontalRule style={{ color: '#ff0000' }} />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>
    </Box>
  )
}

export { ContractNetwork }
