// @ts-nocheck
/* eslint-disable */
import type * as Types from './hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetEquipmentClassesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEquipmentClassesQuery = { __typename?: 'query_root', equipmentClasses: Array<{ __typename?: 'equipmentClasses', name: string, description?: string }> };



export const GetEquipmentClassesDocument = `
    query GetEquipmentClasses @cached(ttl: 300) {
  equipmentClasses(orderBy: {name: ASC}) {
    name
    description
  }
}
    `;

export const useGetEquipmentClassesQuery = <
      TData = GetEquipmentClassesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetEquipmentClassesQueryVariables,
      options?: UseQueryOptions<GetEquipmentClassesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetEquipmentClassesQuery, TError, TData>(
      variables === undefined ? ['GetEquipmentClasses'] : ['GetEquipmentClasses', variables],
      fetcher<GetEquipmentClassesQuery, GetEquipmentClassesQueryVariables>(client, GetEquipmentClassesDocument, variables, headers),
      options
    )};

useGetEquipmentClassesQuery.document = GetEquipmentClassesDocument;

useGetEquipmentClassesQuery.getKey = (variables?: GetEquipmentClassesQueryVariables) => variables === undefined ? ['GetEquipmentClasses'] : ['GetEquipmentClasses', variables];


useGetEquipmentClassesQuery.fetcher = (client: GraphQLClient, variables?: GetEquipmentClassesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetEquipmentClassesQuery, GetEquipmentClassesQueryVariables>(client, GetEquipmentClassesDocument, variables, headers);
